import React, { useContext, useEffect, useRef, useState } from 'react'
import Countdown from '../../components/invitation/CountDown'
import { darker, extratImageID, lighter } from '../../helpers/functions'
import { appContext } from '../../context/AuthContext'
import { message } from 'antd'
import { storage } from '../../firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import '../../styles/modules/cover.css'
import ConfettiComponent from '../../components/build/Conffeti'


export const CoverApp = ({ content, coverRef, inv, dev, height, land }) => {

    const { MainColor, theme, font, colorPalette } = useContext(appContext)

    const [timerColor, setTimerColor] = useState(null)
    const [isToday, setIsToday] = useState(false)

    useEffect(() => {
        if (content) {
            setTimerColor(content.timerColor)
        }
    }, [content])


    const types = [
        {
            name: 'Redondeado',
            content: {
                borderRadius: '12px',
                background: timerColor,
                width: '90%',
                padding: '10px 0'
            },
        },
        {
            name: 'Completo',
            content: {
                borderRadius: '0px',
                background: timerColor,
                width: '100%',
                padding: '10px 0'
            },
        },
        {
            name: 'Transparente',
            content: {
                borderRadius: '0px',
                background: 'transparent',
                width: '100%',
                padding: '10px 0'
            }
        }
    ]

    const cleanDate = (dateString) => {
        if (dateString) {
            if (dateString.endsWith("000Z")) {
                return dateString.slice(0, -5);
            }
            return dateString;
        }

    };

    const checkIfToday = (targetDate) => {
        const today = new Date();
        const target = new Date(targetDate);

        // Comparar solo el año, mes y día
        return (
            today.getFullYear() === target.getFullYear() &&
            today.getMonth() === target.getMonth() &&
            today.getDate() === target.getDate()
        );
    };


    return (


        content && colorPalette ?
            <div ref={coverRef} className='module-cover-container' style={{ position: 'relative', zIndex: 3 }}>
                {
                    checkIfToday(cleanDate(content.date)) && <ConfettiComponent palette={colorPalette} />
                }

                <div
                    className={!dev ? 'cover-container' : 'cover-container-dev'}
                    style={{
                        height: height, padding: '0', minHeight: '630px', maxHeight: !dev ? '' : '730px',
                        background: colorPalette.primary,
                    }}>

                    {
                        content.featured_dev || content.featured_prod ?
                            <div className='cover-image-container--'
                                style={{
                                    top: `${content.mapPosition.y}px`,
                                    left: `${content.mapPosition.x}px`,
                                    transform: `scale(${content.zoomLevel})`,
                                }}>
                                <img src={dev && !land ? content.featured_dev : content.featured_prod} />
                                <div style={{
                                    position: 'absolute', width: '100%', height: '100%', top: '0px', left: '0px',
                                    background: `linear-gradient(to top, ${theme ? darker(colorPalette.primary, 0.2) : darker(colorPalette.primary, 0.2)}, rgba(0,0,0,0))`
                                }}></div>
                            </div>
                            : <></>
                    }



                    <div className='background-cover'
                        style={{
                            flexDirection: content.flexDirection,
                        }}>



                        <div className='cover--title-container' style={{
                            alignItems: content.align, height: content.date ? '75%' : '100%',
                            padding: content.date ? 0 : '10px',

                        }}>
                            <span style={{
                                color: !content.color ? theme ? lighter(colorPalette.primary, 0.6) : lighter(colorPalette.accent, 0.6) : content.color, width: '100%',
                                textAlign: content.justify, fontSize: `${content.fontSize}em`, wordBreak: 'break-word',
                                opacity: content.opacity, fontFamily: content.image, fontWeight: content.fontWeight,
                                lineHeight: '1'
                            }}>{content.title}</span>
                        </div>

                        {
                            content.date && (
                                <div style={{
                                    width: '100%',
                                    backgroundColor: `transparent`,

                                    height: '25%', marginTop: '10px',
                                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}>

                                    <Countdown mainColor={content.color} color={content.timerColor} colorPalette={colorPalette} dev={dev} targetDate={content.date} MainColor={MainColor} theme={theme} font={font} fontWeight={content.fontWeight} />

                                </div>
                            )
                        }



                    </div>

                </div>
            </div>
            : <></>

    )
}



