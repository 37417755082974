import { Button, Col, ColorPicker, Drawer, Empty, Input, Row, Switch, Upload, message } from 'antd'
import { storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import {
    BiSolidColorFill, FaMinus, HiOutlinePlus, IoMdAdd, LuImagePlus, LuSeparatorHorizontal,
    FaLink, IoClose, MdEdit, colorFactoryToHex, LuBadgeHelp, generateImagesName, TbEyeClosed,
    IoDocumentTextOutline, BsChatSquareText, load, dresscodeAI, BsStars, RiDeleteBack2Line, IoIosArrowDown
} from '../../helpers';

import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { HelpDrawer } from '../../components/build/HelpDrawer';
import { appContext } from '../../context';
import { CustomButton } from '../../components/CustomButton';


export const BuildDressCode = ({ invitation, setInvitation, invitationID, setSaved, onDesign, onTry }) => {

    const [onLinkMode, setOnLinkMode] = useState(false)
    const [onImageMode, setOnImageMode] = useState(false)
    const [visible, setVisible] = useState(false)
    const [type, setType] = useState(null)
    const [location, setLocation] = useState('info')
    const [currentCard, setcurrentCard] = useState(null)
    // const { onDesign } = useContext(appContext)

    const [onButton, setOnButton] = useState(false)
    const [onGeneration, setOnGeneration] = useState(false)
    const [descriptionValue, setDescriptionValue] = useState(null)
    const [textAppear, setTextAppear] = useState(false)

    const handleGenerating = () => {

        let local_description = dresscodeAI[Math.floor(Math.random() * 9)]

        setDescriptionValue('Generando ...');

        setTimeout(() => {
            setDescriptionValue(local_description)
        }, 4500);

        setOnGeneration(true);

        setTimeout(() => {
            setInvitation(prevInvitation => ({
                ...prevInvitation,
                dresscode: {
                    ...prevInvitation.dresscode,
                    description: local_description,
                },
            }));
            setSaved(false);
            setOnGeneration(false);
        }, 5000);
    };

    const handleActive = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                active: e,
            },
        }));
        setSaved(false)
    }

    const handleOnImages = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                onImages: e,
            },
        }));
        setSaved(false)
    }

    const handleOnLinks = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                onLinks: e,
            },
        }));
        setSaved(false)
    }

    const onChangeTitle = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                title: e ? e.target.value : prevInvitation.dresscode.title,
            },
        }));
        setSaved(false)
    }

    const onChangeDescription = (e) => {
        setDescriptionValue(e.target.value)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                description: e ? e.target.value : prevInvitation.dresscode.description,
            },
        }));
        setSaved(false)
    }

    const changeNameLinkbyIndex = (index, newName) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                links: prevInvitation.dresscode.links.map((card, i) => {
                    if (i === index) {
                        return {
                            ...card,
                            name: newName
                        };
                    }
                    return card;
                })
            }
        }));
        setSaved(false)
    }

    const changeLinkbyIndex = (index, newName) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                links: prevInvitation.dresscode.links.map((card, i) => {
                    if (i === index) {
                        return {
                            ...card,
                            URL: newName
                        };
                    }
                    return card;
                })
            }
        }));
        setSaved(false)
    }

    const deleteLinkByIndex = (index) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                links: prevInvitation.dresscode.links.filter((card, i) => i !== index)
            }
        }));
        setSaved(false)
    }

    const addNewLink = () => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                links: [
                    ...prevInvitation.dresscode.links,
                    {
                        name: null,
                        URL: null,
                    }
                ]
            }
        }));
        setSaved(false)
    }

    const onChangeBackground = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                background: e,
            },
        }));
        setSaved(false)
    }

    const onChangeSeparator = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                separator: e,
            },
        }));
        setSaved(false)
    }

    const switchSettings = (type, value) => {
        if (type === 'image') {
            if (value) {
                setOnImageMode(true);
                setOnLinkMode(false);
            } else if (!value && !onLinkMode) {
                setOnImageMode(false);
            }
        } else if (type === 'link') {
            if (value) {
                setOnLinkMode(true);
                setOnImageMode(false);
            } else if (!value && !onImageMode) {
                setOnLinkMode(false);
            }
        }
    };


    const handleURL = (url) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,  // Asegúrate de copiar correctamente dresscode
                images_dev: [...prevInvitation.dresscode.images_dev, url],  // Agregar la nueva URL al array de imágenes
            },
        }));
        setSaved(false)
    };

    const handleCustomRequest = ({ file }) => {
        if (!file) return;

        const isLt3M = file.size / 1024 / 1024 < 5;
        if (!isLt3M) {
            message.error('El archivo debe ser menor a 5MB.');
            return; // Salir de la función si el archivo es mayor a 3MB
        }

        if (invitation.dresscode.images_dev.length < 5) {
            const storageRef = ref(storage, `invitations/${invitationID}/dresscode/${generateImagesName()}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                'state_changed',
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    // setProgress(progress);
                },
                error => {
                    console.error('Upload error: ', error);
                },

                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                        handleURL(downloadURL)
                    });
                }
            );
        } else {
            message.error('No puedes tener más de 5 imágenes')
        }

    };

    const removeImageDB = (name) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                images_dev: prevInvitation.dresscode.images_dev.filter(imageName => imageName !== name),
            },
        }));
        setSaved(false)
    };

    const deleteImageFB = async (url) => {
        const filePath = extractFilePathFromURL(url)

        const imageRef = ref(storage, filePath);

        try {
            await deleteObject(imageRef);
            removeImageDB(url)
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const extractFilePathFromURL = (url) => {
        const regex = /https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/([^/]+)\/o\/(.+)\?alt=media&token=.+/;
        const match = url.match(regex);
        if (!match) {
            throw new Error('URL does not match Firebase Storage base URL.');
        }
        const filePath = decodeURIComponent(match[2]);
        return filePath;
    };


    const addNewColor = () => {
        if (invitation.dresscode.colors.length < 5) {
            setInvitation(prevInvitation => ({
                ...prevInvitation,
                dresscode: {
                    ...prevInvitation.dresscode,
                    colors: [
                        ...prevInvitation.dresscode.colors,
                        invitation.generals.palette.secondary
                    ]
                }
            }))
            setSaved(false)
        } else {
            message.warning('No puedes agregar más de 5 colores')
        }

    }

    const cancelColors = () => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                colors: []
            }
        }));
        setSaved(false)
    }

    const onChangeColorByIndex = (e, index) => {
        const color = colorFactoryToHex(e);

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                colors: prevInvitation.dresscode.colors.map((card, i) => {
                    if (i === index) {
                        return color; // Reemplazar el color existente en el índice dado
                    }
                    return card;
                })
            },
        }));
        setSaved(false)
    }

    const onDeleteColorByIndex = (index) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                colors: prevInvitation.dresscode.colors.filter((_, i) => i !== index)
            }
        }));
        setSaved(false)
    }

    const handleDrawer = (type) => {
        setType(type)
        setVisible(true)
    }

    const handleTitle = (location) => {
        switch (location) {
            case 'info': return 'Información'
            case 'links': return 'Links'
            case 'images': return 'Imágenes'
            default:
                break;
        }
    }

    useEffect(() => {
        setDescriptionValue(invitation.dresscode.description)
    }, [])

    useEffect(() => {

        if (onButton) {
            setTimeout(() => {
                setTextAppear(onButton)
            }, 350);

        } else {
            setTextAppear(onButton)
        }

    }, [onButton])



    return (
        <>
            {
                invitation ?


                    <div className='scroll-item generals-main-container'>
                        <div className='build-component-elements'>
                            <div className='general-cards-single-row' style={{ width: '100%', justifyContent: 'space-between' }}>
                                <div className='general-cards-single-row'>
                                    <span className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                                        style={{
                                            width: 'auto',
                                        }}
                                    >Dresscode</span>
                                    <Switch
                                        size='small'
                                        value={invitation.dresscode.active}
                                        onChange={handleActive} />
                                </div>


                                <div className='general-cards-single-row' style={{ gap: '5px' }}>
                                    {
                                        invitation.dresscode.active && (

                                            <>
                                                <Button
                                                    onMouseEnter={() => setOnButton(true)}
                                                    onMouseLeave={() => setOnButton(false)}
                                                    type='ghost'
                                                    onClick={handleGenerating}
                                                    id={`gc-cta-magic-btn`}
                                                    style={{
                                                        width: '35px',
                                                        transition: 'all 0.4s ease',
                                                        boxShadow: onButton ? '0 0 15px #FFB32D' : '',
                                                        borderRadius: '8px',
                                                        height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                        background: onButton ? 'linear-gradient(to bottom, #FFB32D, #fdc564)' : 'transparent',
                                                        color: onButton ? '#FFF' : '#000',


                                                    }}
                                                    icon={<BsStars size={20} />}>

                                                </Button>

                                                <Button
                                                    type='ghost'
                                                    onClick={() => onChangeSeparator(!invitation.dresscode.separator)}
                                                    id={`build-cover-date-buttons${invitation.dresscode.separator ? '--active' : ''}`}
                                                    icon={<LuSeparatorHorizontal size={18} />} />

                                                <Button
                                                    type='ghost'
                                                    onClick={() => onChangeBackground(!invitation.dresscode.background)}
                                                    id={`build-cover-date-buttons${invitation.dresscode.background ? '--active' : ''}`}
                                                    icon={<BiSolidColorFill size={18} />} />
                                            </>
                                        )
                                    }
                                </div>

                            </div>

                            {
                                invitation.dresscode.active &&
                                <>
                                    <span className='gc-content-label'>Título</span>

                                    <Input
                                        placeholder='Título'
                                        className='gc-input-text'
                                        onChange={onChangeTitle}
                                        value={invitation.dresscode.title} />

                                    <span className='gc-content-label'>Descripción</span>

                                    <Input.TextArea
                                        placeholder={'Descripción'}
                                        value={descriptionValue}
                                        onChange={onChangeDescription}
                                        autoSize={{ minRows: 8, maxRows: 15 }}
                                        className={`gc-input-text ${onGeneration ? 'magic-effect' : ''}`}
                                        style={{
                                            borderRadius: '8px'
                                        }} />
                                </>
                            }

                        </div>

                        {
                            invitation.dresscode.active ?

                                <>
                                    <div className='build-component-elements'>
                                        <div className='general-cards-single-row' style={{
                                            width: '100%', justifyContent: 'space-between'
                                        }}>
                                            <span className={'module--title'}
                                            >Paleta de colores</span>

                                            <Button
                                                id='build-cover-date-buttons'
                                                onClick={addNewColor}
                                                icon={<IoMdAdd size={20} />} />
                                        </div>

                                        {
                                            invitation.dresscode.colors ?
                                                invitation.dresscode.colors.map((item, index) => (

                                                    <div className='generl-card-color-item'>
                                                        <div className='general-cards-single-row'>
                                                            <ColorPicker
                                                                disabledAlpha={false}
                                                                value={item}
                                                                style={{ width: '184px' }}
                                                                onChange={(e) => onChangeColorByIndex(e, index)}>
                                                            </ColorPicker>
                                                            <Button
                                                                id='build-cover-date-buttons'
                                                                onClick={() => onDeleteColorByIndex(index)}
                                                                icon={<RiDeleteBack2Line size={20} />}
                                                            >Eliminar</Button>

                                                        </div>

                                                    </div>

                                                ))
                                                : <></>

                                        }

                                    </div>

                                    <div className='build-component-elements'>
                                        <div className='general-cards-single-row' style={{
                                            width: '100%', justifyContent: 'space-between'
                                        }}>
                                            <div className='general-cards-single-row'>
                                                {/* <LuBadgeHelp style={{ cursor: 'pointer' }} size={16} onClick={() => handleDrawer('links-dresscode')} /> */}


                                                <span className={'module--title'} style={{ width: 'auto' }}
                                                >Enlaces / Ideas</span>
                                                <Switch
                                                    size='small'
                                                    value={invitation.dresscode.onLinks}
                                                    onChange={handleOnLinks} />

                                            </div>

                                            {
                                                invitation.dresscode.onLinks &&
                                                <Button
                                                    id='build-cover-date-buttons'
                                                    onClick={addNewLink}
                                                    icon={<IoMdAdd size={20} />} />
                                            }



                                        </div>

                                        {
                                            invitation.dresscode.onLinks &&
                                            invitation.dresscode.links.map((item, index) => (

                                                <div
                                                    className='regular-card'
                                                    style={{
                                                        width: !onDesign && '100%', padding: currentCard !== index ? '8px' : '12px',
                                                        backgroundColor: currentCard !== index ? '#FFF' : '#F5F3F280'
                                                    }}
                                                    key={index}
                                                >
                                                    <div className='general-cards-single-row' style={{
                                                        width: '100%', justifyContent: 'space-between',
                                                        marginBottom: currentCard === index && '8px'
                                                    }}>
                                                        {
                                                            currentCard !== index ? <span className='gc-content-label'>{item.name}</span>
                                                                : <Button
                                                                    type='ghost'
                                                                    id="build-cover-date-buttons"
                                                                    onClick={() => deleteLinkByIndex(index)}
                                                                    icon={<RiDeleteBack2Line size={16} />}
                                                                >Eliminar</Button>
                                                        }

                                                        <Button
                                                            type='ghost'
                                                            onClick={() => setcurrentCard(currentCard === index ? null : index)}
                                                            id={`build-cover-date-buttons`}
                                                            style={{ transform: currentCard === index && 'rotate(180deg)' }}
                                                            icon={<IoIosArrowDown size={18} />} />

                                                    </div>

                                                    {
                                                        currentCard === index &&
                                                        <>
                                                            <span className='gc-content-label'>Nombre</span>
                                                            <Input
                                                                placeholder='Título'
                                                                className='gc-input-text'
                                                                onChange={(e) => changeNameLinkbyIndex(index, e.target.value)}
                                                                value={item.name} />
                                                            <span className='gc-content-label'>Enlace</span>
                                                            <Input
                                                                placeholder='Link'
                                                                className='gc-input-text'
                                                                onChange={(e) => changeLinkbyIndex(index, e.target.value)}
                                                                value={item.URL} />

                                                        </>
                                                    }

                                                </div>


                                            ))

                                        }

                                    </div>

                                    <div className='build-component-elements'>
                                        <div className='general-cards-single-row' style={{
                                            width: '100%', justifyContent: 'space-between'
                                        }}>
                                            <div className='general-cards-single-row'>
                                                {/* <LuBadgeHelp style={{ cursor: 'pointer' }} size={16} onClick={() => handleDrawer('links-dresscode')} /> */}

                                                <span className={'module--title'} style={{ width: 'auto', }}
                                                >Imagenes</span>
                                                <Switch
                                                    size='small'
                                                    value={invitation.dresscode.onImages}
                                                    onChange={handleOnImages} />

                                            </div>

                                            {
                                                invitation.dresscode.onImages &&
                                                <Upload
                                                    onChange={handleCustomRequest}
                                                    showUploadList={false} // Oculta la lista de archivos subidos
                                                    beforeUpload={() => false} // Evita la carga automática de archivos
                                                >

                                                    <Button
                                                        id='build-cover-date-buttons'
                                                        // onClick={addNewLink}
                                                        icon={<IoMdAdd size={20} />} />


                                                </Upload>
                                            }





                                        </div>

                                        {
                                            invitation.dresscode.onImages &&
                                                invitation.dresscode.images_dev.length > 0 ?
                                                invitation.dresscode.images_dev.map((item, index) => (
                                                    <div style={{
                                                        width: '100%', borderRadius: '8px', border: '1px solid #D9D9D9',
                                                        backgroundColor: '#F5F5F7', display: 'flex', alignItems: 'center', justifyContent: 'flex-end',
                                                        position: 'relative', height: '70px', overflow: 'hidden', padding: '8px'
                                                    }}>


                                                        <img src={item} style={{
                                                            width: '400px', height: '40vh', objectFit: 'cover', position: 'absolute',
                                                            right: 0
                                                        }} />

                                                        <Button
                                                            type='ghost'
                                                            style={{ opacity: 0.8 }}
                                                            id="build-cover-date-buttons"
                                                            onClick={() => deleteImageFB(item)}
                                                            icon={<RiDeleteBack2Line size={16} />}
                                                        >Eliminar</Button>

                                                    </div>
                                                ))
                                                : <Empty description={false} style={{
                                                    marginTop: '30px'
                                                }} />

                                        }

                                    </div>
                                </>
                                : <div style={{
                                    width: '100%', height: '300px',
                                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}><TbEyeClosed size={32} style={{ color: '#717171' }} /></div>


                        }













                    </div >

                    : <></>
            }

            <HelpDrawer visible={visible} setVisible={setVisible} type={type} setType={setType} />
        </>
    )
}


{/* <Row style={{
                            width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row'
                        }}>

                            <Col style={{
                                width: '40%', display: 'flex', alignItems: 'flex-start',
                                justifyContent: 'center', flexDirection: 'column',
                                margin: '7px 0'
                            }}>
                                <span
                                    className='roboto-regular'
                                    style={{
                                        width: 'auto', fontSize: '1em', fontFamily: '',
                                        marginLeft: '5px'
                                    }}>Descripción</span>
                                <Input.TextArea
                                    autoSize={{ minRows: 5, maxRows: 10 }}
                                    style={{
                                        width: '100%', borderRadius: '15px'
                                    }}
                                    value={invitation.dresscode.description}
                                    onChange={onChangeDescription} />

                                <span
                                    className='roboto-regular'
                                    style={{
                                        width: 'auto', fontSize: '1em', fontFamily: '',
                                        marginLeft: '5px', marginTop: '10px'
                                    }}>Username</span>
                                <Input
                                    style={{
                                        width: '100%', borderRadius: '30px'
                                    }}
                                    onChange={onChnageUsername}
                                    value={invitation.dresscode.username} />

                                <Button
                                    onClick={addItemToBoards}
                                    icon={<IoMdAdd style={{
                                        color: '#FFF'
                                    }} />}
                                    type='primary' style={{
                                        borderRadius: '30px', color: '#FFF',
                                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                                        marginTop: '20px'
                                    }}>
                                    Nuevo Tablero
                                </Button>

                                {
                                    invitation.dresscode.boards ?
                                        invitation.dresscode.boards.map((board, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    width: '100%', borderRadius: '30px',
                                                    border: '1px solid #D9D9D9', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                                                    flexDirection: 'row', position: 'relative',
                                                    marginTop: '15px'
                                                }}>
                                                <Input
                                                    value={board}
                                                    onChange={(e) => editBoardItemByIndex(index, e.target.value)}
                                                    style={{
                                                        width: '90%', borderRadius: '30px',
                                                        border: 'none'
                                                    }} />

                                                <Button
                                                    onClick={() => deleteItemFromBoardsByIndex(index)}
                                                    style={{
                                                        height: '28px', width: '28px', borderRadius: '50%',
                                                        backgroundColor: '#F5F5F7', border: 'none',
                                                        // color: '#878787', 
                                                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                        position: 'absolute', right: '2px'
                                                    }}
                                                    icon={<IoClose size={12} />}
                                                />
                                            </div>
                                        ))
                                        : <></>
                                }

                            </Col>

                            <Col style={{
                                width: '57%', display: 'flex', alignItems: 'flex-start',
                                justifyContent: 'center', flexDirection: 'column',
                                marginTop: '-5px'
                                // margin: '7px 0'
                            }}>
                                <span
                                    className='roboto-regular'
                                    style={{
                                        width: 'auto', fontSize: '1em', fontFamily: '',
                                        marginLeft: '5px', marginTop: '10px'
                                    }}>¿Cómo funcionan los tableros de pinterest?</span>

                                <div style={{
                                    width: '100%', border: '1px solid #e3e3e3',
                                    height: '380px', borderRadius: '15px'
                                }}>

                                </div>

                            </Col>



                        </Row> */}