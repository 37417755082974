import { Layout, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { FooterMobile, HeaderBuild } from '../layouts/Build'
import { Login } from '../components/land-page/Login'
import justWeb from '../assets/just_web.svg'
import { PricingContent } from '../components/pricing/PricingContent'
import { useLocation } from 'react-router-dom';

export const PricingPage = () => {

    const [openLogin, setOpenLogin] = useState(false)
    const [isVisible, setIsVisible] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const { pathname } = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const margin = 0; // Ajusta este valor según tu preferencia


            if (currentScrollPos < margin) {
                setIsVisible(false);
            } else {
                setIsVisible(prevScrollPos > currentScrollPos);

                setPrevScrollPos(currentScrollPos);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        setIsVisible(true)

    }, [])



    return (
        <>

            <Layout
                // className='responsive-web'
                style={{
                    position: 'relative',
                    alignItems: 'center', justifyContent: 'center',
                    backgroundColor: '#FFF'
                }}>
                <HeaderBuild position={'pricing'} setOpenLogin={setOpenLogin} isVisible={isVisible} />
                <PricingContent />
                {/* <FooterMobile position={'pricing'} setOpenLogin={setOpenLogin} isVisible={isVisible} /> */}

                <Modal
                    open={openLogin}
                    onClose={() => setOpenLogin(false)}
                    onCancel={() => setOpenLogin(false)}
                    footer={null} // Opcional: Elimina el footer del modal si no es necesario
                    style={{
                        top: 40,
                        display: 'flex',
                        alignItems: 'center', justifyContent: 'center'
                    }}>
                    <Login position={'land-page'} setOpenLogin={setOpenLogin} />

                </Modal>


            </Layout >


            {/* <Layout
                className='responsive-mobile'
            >

                <img src={justWeb} style={{
                    width: '100%', objectFit: 'cover', height: '100%'
                }} />

            </Layout > */}
        </>
    )
}
