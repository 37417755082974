import { Button, Col, Empty, Row, Switch, Upload, message } from 'antd'
import axios from 'axios';
import { storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { BiSolidColorFill, IoMdAdd, LuSeparatorHorizontal, MdEdit, FaRegTrashAlt, generateImagesName, IoClose, TbEyeClosed, RiDeleteBack2Line } from '../../helpers';
import { CustomButton } from '../../components/CustomButton';
import { useContext, useEffect } from 'react';
import { appContext } from '../../context';


export const BuildGallery = ({ invitation, setInvitation, invitationID, setSaved, onDesign, onTry }) => {

    // const { onDesign } = useContext(appContext)


    const handleActive = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gallery: {
                ...prevInvitation.gallery,
                active: e,
            },
        }));
        setSaved(false)
    }


    const onChangeBackground = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gallery: {
                ...prevInvitation.gallery,
                background: e,
            },
        }));
        setSaved(false)
    }

    const onChangeSeparator = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gallery: {
                ...prevInvitation.gallery,
                separator: e,
            },
        }));
        setSaved(false)
    }



    const handleURL = (url) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gallery: {
                ...prevInvitation.gallery,  // Asegúrate de copiar correctamente dresscode
                gallery_dev: [...prevInvitation.gallery.gallery_dev, url],  // Agregar la nueva URL al array de imágenes
            },
        }));
        setSaved(false)
    };

    const handleCustomRequest = ({ file }) => {
        if (!file) return;

        // Verificar si el archivo es menor a 3MB
        const isLt3M = file.size / 1024 / 1024 < 5;
        if (!isLt3M) {
            message.error('El archivo debe ser menor a 5MB.');
            return; // Salir de la función si el archivo es mayor a 3MB
        }

        if (invitation.gallery.gallery_dev.length < 10) {
            const storageRef = ref(storage, `invitations/${invitationID}/gallery/${generateImagesName()}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                'state_changed',
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    // setProgress(progress);
                },
                error => {
                    console.error('Upload error: ', error);
                },

                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                        handleURL(downloadURL)
                    });
                }
            );
        } else {
            message.error('No puedes tener más de 5 imágenes')
        }

    };

    const removeImageDB = (name) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gallery: {
                ...prevInvitation.gallery,
                gallery_dev: prevInvitation.gallery.gallery_dev.filter(imageName => imageName !== name),
            },
        }));
        setSaved(false)
    };

    const deleteImageFB = async (url) => {
        const filePath = extractFilePathFromURL(url)

        const imageRef = ref(storage, filePath);

        try {
            await deleteObject(imageRef);
            removeImageDB(url)
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const extractFilePathFromURL = (url) => {
        const regex = /https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/([^/]+)\/o\/(.+)\?alt=media&token=.+/;
        const match = url.match(regex);
        if (!match) {
            throw new Error('URL does not match Firebase Storage base URL.');
        }
        const filePath = decodeURIComponent(match[2]);
        return filePath;
    };

    const beforeUpload = (file) => {
        const isLt3M = file.size / 1024 / 1024 < 3;
        if (!isLt3M) {
            message.error('El archivo debe ser menor a 3MB.');
        }
        return isLt3M; // Retorna true si el archivo es menor a 3MB, lo que permitirá la carga
    };


    return (
        <>
            {
                invitation ?
                    <div className='scroll-item generals-main-container'>
                        <div className='build-component-elements'>
                            <div className='general-cards-single-row' style={{ width: '100%', justifyContent: 'space-between' }}>
                                <div className='general-cards-single-row'>
                                    <span className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                                        style={{
                                            width: 'auto',
                                        }}
                                    >Galería</span>
                                    <Switch
                                        size='small'
                                        value={invitation.gallery.active}
                                        onChange={handleActive} />
                                </div>


                                <div className='general-cards-single-row' style={{ gap: '5px' }}>
                                    {
                                        invitation.gallery.active && (

                                            <>
                                                <Button
                                                    type='ghost'
                                                    onClick={() => onChangeSeparator(!invitation.gallery.separator)}
                                                    id={`build-cover-date-buttons${invitation.gallery.separator ? '--active' : ''}`}
                                                    icon={<LuSeparatorHorizontal size={18} />} />

                                                <Button
                                                    type='ghost'
                                                    onClick={() => onChangeBackground(!invitation.gallery.background)}
                                                    id={`build-cover-date-buttons${invitation.gallery.background ? '--active' : ''}`}
                                                    icon={<BiSolidColorFill size={18} />} />
                                            </>
                                        )
                                    }
                                </div>

                            </div>

                            {
                                invitation.gallery.active &&
                                <>
                                    {
                                        onTry ?
                                            <CustomButton
                                                onClick={() => message.warning('No puedes subir imágenes en simulador')}
                                                icon={<IoMdAdd />} text={'Nueva Fotografía'} style={{
                                                    margin: '10px 0px'
                                                }} />

                                            : <Upload
                                                onChange={onTry ? () => message.warning('No puedes subir imágenes en simulador') : handleCustomRequest}
                                                showUploadList={false} // Oculta la lista de archivos subidos
                                                beforeUpload={() => false} // Evita la carga automática de archivos
                                            >

                                                <CustomButton

                                                    icon={<IoMdAdd />} text={'Nueva Fotografía'} style={{
                                                        margin: '10px 0px'
                                                    }} />

                                            </Upload>
                                    }



                                    {
                                        invitation.gallery.active ?
                                            <Col style={{
                                                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                                                flexDirection: 'row', flexWrap: 'wrap',
                                            }}>
                                                {
                                                    invitation.gallery.gallery_dev && (
                                                        invitation.gallery.gallery_dev.length > 0 ?
                                                            invitation.gallery.gallery_dev.map((photo, index) => (

                                                                <div style={{
                                                                    width: '100%', borderRadius: '8px', border: '1px solid #D9D9D9',
                                                                    backgroundColor: '#F5F5F7', display: 'flex', alignItems: 'center', justifyContent: 'flex-end',
                                                                    position: 'relative', height: '70px', overflow: 'hidden', padding: '8px',
                                                                    marginBottom: '5px'
                                                                }}>


                                                                    <img src={photo} style={{
                                                                        width: '400px', height: '40vh', objectFit: 'cover', position: 'absolute',
                                                                        right: 0
                                                                    }} />

                                                                    <Button
                                                                        type='ghost'
                                                                        style={{ opacity: 0.8 }}
                                                                        id="build-cover-date-buttons"
                                                                        onClick={() => deleteImageFB(photo)}
                                                                        icon={<RiDeleteBack2Line size={16} />}
                                                                    >Eliminar</Button>

                                                                </div>

                                                            ))
                                                            : <Empty description={false} style={{
                                                                marginTop: '30px'
                                                            }} />
                                                    )

                                                }


                                            </Col>
                                            : <div style={{
                                                width: '100%', height: '300px',
                                                display: 'flex', alignItems: 'center', justifyContent: 'center'
                                            }}><TbEyeClosed size={32} style={{ color: '#717171' }} /></div>
                                    }

                                </>
                            }
                        </div>

                    </div >
                    // <div style={{
                    //     width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                    //     flexDirection: 'column'
                    // }}>
                    //     <Switch
                    //         value={invitation.gallery.gallery.length > 0 ? true : false}
                    //         onChange={toggleClearGallery} />

                    //     <Button onClick={addNewGalleryImage}>Add photo</Button>

                    //     {
                    //         invitation.gallery.gallery ?
                    //             invitation.gallery.gallery.map((photo, index) => (

                    //                 <>
                    //                     <Button key={index} onClick={() => deleteGalleryByIndex(index)}>Remove</Button>
                    //                     <Input
                    //                         onChange={(e) => editGalleryByIndex(index, e.target.value)}
                    //                         placeholder={photo} />
                    //                 </>
                    //             ))
                    //             : <></>
                    //     }

                    // </div>
                    : <></>
            }
        </>
    )
}


// return (
//     <>
//         {
//             invitation ?

//                 <div style={{
//                     width: '92%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
//                     flexDirection: 'column',
//                 }}>
//                     <span style={{
//                         width: '100%', textAlign: 'left',
//                         fontWeight: 600, marginBottom: '20px', letterSpacing: '0',
//                         fontFamily: ''
//                     }}>Avisos</span>

//                     <p
//                         style={{
//                             width: '100%', textAlign: 'left', lineHeight: '1.3',
//                             marginBottom: '30px', fontSize: '1.1em', color: '#00000080',
//                             fontFamily: ''
//                         }}
//                     >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus turpis, aliquet id dapibus sed, convallis sit amet tortor. Integer lacinia maximus ex in placerat.</p>

//                     <Row style={{
//                         width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
//                         marginTop: '20px'
//                     }}>

//                         <Button
//                             onClick={addNewNotice}
//                             icon={<IoMdAdd style={{
//                                 color: '#FFF'
//                             }} />}
//                             type='primary' style={{
//                                 borderRadius: '30px', color: '#FFF',
//                                 display: 'flex', alignItems: 'center', justifyContent: 'center',
//                                 // marginTop: '20px'
//                             }}>
//                             Nuevo Aviso
//                         </Button>

//                         < Switch
//                             value={invitation.notices.active}
//                             onChange={handleActive} />
//                     </Row>

//                     <div style={{
//                         width: '100%', height: '50vh', overflow: 'auto', display: 'flex', alignItems: 'flex-start',
//                         marginTop: '20px'
//                     }}>
//                         <Col style={{
//                             width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
//                             justifyContent: 'flex-start', flexDirection: 'column'
//                         }}>
//                             {
//                                 invitation.notices.notices ?
//                                     invitation.notices.notices.map((notice, index) => (

//                                         <div
//                                             key={index}
//                                             style={{
//                                                 width: '100%', position: 'relative', margin: '10px',
//                                                 // borderRadius: '15px'
//                                             }}>

//                                             <Input.TextArea
//                                                 style={{
//                                                     width: '100%', borderRadius: '15px'
//                                                 }}
//                                                 autoSize={{ minRows: 5, maxRows: 6 }}
//                                                 onChange={(e) => editNoticeByIndex(index, e.target.value)}
//                                                 placeholder={notice} />

//                                             <Button
//                                                 icon={<MdDeleteOutline />}
//                                                 style={{
//                                                     position: 'absolute', right: '2px', top: '2px',
//                                                     borderRadius: '50%', border: 'none'
//                                                 }}
//                                                 onClick={() => deleteNoticeByIndex(index)} />
//                                         </div>
//                                     ))
//                                     : <></>
//                             }
//                         </Col>
//                     </div>

//                 </div>

//                 : <></>
//         }
//     </>
// )