import { Button, Col, ColorPicker, DatePicker, Input, Popconfirm, Row, Select, Slider, Switch, Upload, message } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';

import { formatDateToISO, fonts, generateImagesName, IoClose, IoMdAdd, colorFactoryToHex, buttonsColorText, lighter, formatDate, IoPower, TbArrowsMove, darker, LuRuler, PiArrowsCounterClockwiseFill, PiAlignCenterHorizontalFill, PiAlignCenterVerticalFill, PiAlignCenterHorizontal, PiAlignCenterVertical, PiAlignCenterVerticalSimple, RiDeleteBack2Line } from '../../helpers'
import { appContext } from '../../context'

import {
    HiSwitchVertical, LuImagePlus, LuSettings2, FaMinus, LuFrame, FaRegCalendar, BiHide, BiShow,
    FaArrowRightLong, FaArrowLeftLong
} from '../../helpers';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ios_settings from '../../assets/iphone-settings.svg'
import Countdown from '../../components/invitation/CountDown';
import { FaPlus } from 'react-icons/fa';

dayjs.extend(utc);
dayjs.extend(timezone);

const { Option } = Select;





export const BuildCover = ({ invitation, setInvitation, setSaved, invitationID, onDesign, onTry, setSettingsModal, settingsModal }) => {

    const { setFont } = useContext(appContext)

    const coordenadas = [
        {
            justify: 'left',
            align: 'flex-start',
            active: null,
            text: 'Superior izquierda'
        },
        {
            justify: 'center',
            align: 'flex-start',
            active: null,
            text: 'Superior centro'
        },
        {
            justify: 'right',
            align: 'flex-start',
            active: null,
            text: 'Superior derecha'
        },
        {
            justify: 'left',
            align: 'center',
            active: null,
            text: 'Centro izquierda'
        },
        {
            justify: 'center',
            align: 'center',
            active: null,
            text: 'Centro'
        },
        {
            justify: 'right',
            align: 'center',
            active: null,
            text: 'Centro derecha'
        },
        {
            justify: 'left',
            align: 'flex-end',
            active: null,
            text: 'Inferior izquierda'
        },
        {
            justify: 'center',
            align: 'flex-end',
            active: null,
            text: 'Inferior centro'
        },
        {
            justify: 'right',
            align: 'flex-end',
            active: null,
            text: 'Inferior derecha'
        },
    ]

    const [position, setPosition] = useState(coordenadas)
    const [datePosition, setDatePosition] = useState(true)
    const [settingsMode, setSettingsMode] = useState(false)

    const [imageMode, setImageMode] = useState(false)
    const [itemAlign, setitemAlign] = useState('')
    const [itemJustify, setitemJustify] = useState('')
    const [url, setUrl] = useState(null);
    const [firstColor, setFirstColor] = useState(null)
    const [secondColor, setSecondColor] = useState(null)
    const [location, setLocation] = useState('cover')
    const [posicion, setPosicion] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [currentTimer, setCurrentTimer] = useState(0)
    const [timerColor, setTimerColor] = useState(null)
    const [onSettings, setOnSettings] = useState(false)



    const [mapPosition, setMapPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [lastMousePosition, setLastMousePosition] = useState({ x: 0, y: 0 });
    const [zoomLevel, setZoomLevel] = useState(1);
    const [ruleLines, setRuleLines] = useState(true)


    const mapContainerRef = useRef(null);
    const zoomStep = 0.01;
    const minZoom = 0.1;
    const maxZoom = 3;

    useEffect(() => {
        if (invitation) {

            const colors = extractAndCompareColors(invitation.cover.background)
            setitemAlign(invitation.cover.align)
            setitemJustify(invitation.cover.justify)
            setTimerColor(invitation.cover.timerColor)
            if (colors) {
                setFirstColor(colors.color1)
                setSecondColor(colors.color2)
            }


            if (invitation.cover.flexDirection === 'column') {
                setDatePosition(true)
            } else setDatePosition(false)
        }
    }, [invitation])


    const onChangeDate = (e) => {

        setSaved(false)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                date: e ? formatDateToISO(e) : null,
            },
        }));
    }

    const handleURL = (url) => {
        // setUrl(url)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                featured_dev: url,
                background: '#FFFFFF',
                mapPosition: { x: 0, y: 0 },
                zoomLevel: 1
            },
        }));
        setSaved(false)
    }


    const handlePosition = (justify, align) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                justify: justify,
                align: align,
            },
        }));
        setSaved(false)
    }

    const handleFlexDirection = (value) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                flexDirection: value ? 'column' : 'column-reverse',
            },
        }));
        setSaved(false)
    }

    const onChangeTitle = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                title: e ? e.target.value : prevInvitation.cover.title,
            },
        }));
        setSaved(false)
    }

    const handleFont = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                image: e,
            },
        }));
        setSaved(false)
        // setFont(e)
    }

    const switchSettings = (type, value) => {
        if (type === 'image') {
            if (value) {
                setImageMode(true);
                setSettingsMode(false);
                setLocation('image')
            } else if (!value && !settingsMode) {
                setImageMode(false);
            }
        } else if (type === 'settings') {
            if (value) {
                setSettingsMode(true);
                setImageMode(false);
                setLocation('settings')
            } else if (!value && !imageMode) {
                setSettingsMode(false);
            }
        }
    };


    const handleCustomRequest = ({ file }) => {
        if (!file) return;

        const isLt3M = file.size / 1024 / 1024 < 5;
        if (!isLt3M) {
            message.error('El archivo debe ser menor a 5MB.');
            return; // Salir de la función si el archivo es mayor a 3MB
        }

        const storageRef = ref(storage, `invitations/${invitationID}/cover/${generateImagesName()}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            snapshot => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                // setProgress(progress);
            },
            error => {
                console.error('Upload error: ', error);
            },

            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                    handleURL(downloadURL)
                });
            }
        );
    };


    const removeImageDB = () => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                featured_dev: null,
            },
        }));
        setSaved(false)
    };

    const onChnageTitleColor = (e) => {
        setFirstColor(colorFactoryToHex(e))
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                color: colorFactoryToHex(e),
                auto: false
            },
        }));
        setSaved(false)
    };

    const onChnageTimerColor = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                timerColor: e ? colorFactoryToHex(e) : null,
            },
        }));
        setSaved(false)
    };

    const onChnageFirstColor = (e) => {
        setFirstColor(colorFactoryToHex(e))
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                background: `linear-gradient(to bottom, ${colorFactoryToHex(e)} 0%, ${secondColor ? secondColor : colorFactoryToHex(e)} 100%)`,
            },
        }));
        setSaved(false)
    };

    const onChnageSecondColor = (e) => {
        setSecondColor(colorFactoryToHex(e))
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                background: `linear-gradient(to bottom, ${firstColor} 0%, ${colorFactoryToHex(e)} 100%)`,
            },
        }));
        setSaved(false)
    };

    const addSecondColor = (e) => {
        setSecondColor(e)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                background: `linear-gradient(to bottom, ${firstColor} 0%, ${e} 100%)`,
            },
        }));
        setSaved(false)
    };

    const extractAndCompareColors = (gradientString) => {

        if (!gradientString) {
            return {
                color1: null,
                color2: null,
                areEqual: true
            };
        }
        // Expresión regular para encontrar colores hexadecimales en el string
        const hexColorRegex = /#([A-Fa-f0-9]{6})/g;
        // Extraer los colores
        const colors = gradientString.match(hexColorRegex);

        if (colors && colors.length >= 2) {
            // Obtener los dos primeros colores
            const color1 = colors[0];
            const color2 = colors[1];

            // Comparar los colores
            const areEqual = color1.toLowerCase() === color2.toLowerCase();

            return {
                color1: color1,
                color2: color2,
                areEqual: areEqual
            };
        } else {
            return {
                color1: null,
                color2: null,
                areEqual: true
            };
        }
    }

    const handletitle = (location) => {
        switch (location) {
            case 'cover': return 'Generales'
            case 'image': return 'Fondo'
            case 'settings': return 'Ajustes Título'
            case 'date': return 'Fecha'

            default:
                break;
        }
    }

    const moveImage = (direction) => {
        const step = 10; // Define el paso de movimiento
        switch (direction) {
            case 'left':
                setPosicion({ ...posicion, x: posicion.x - step });
                break;
            case 'right':
                setPosicion({ ...posicion, x: posicion.x + step });
                break;
            case 'up':
                setPosicion({ ...posicion, y: posicion.y - step });
                break;
            case 'down':
                setPosicion({ ...posicion, y: posicion.y + step });
                break;
            default:
                break;
        }
    };


    const handleZoom = (value) => {
        setZoom(value);
    };

    const handleColorAuto = (e) => {

        if (e) {
            if (invitation.cover.background) {
                const colors = extractAndCompareColors(invitation.cover.background)
                setInvitation(prevInvitation => ({
                    ...prevInvitation,
                    cover: {
                        ...prevInvitation.cover,
                        color: buttonsColorText(colors.color1),
                    },
                }));
            } else {
                // theme ? lighter(colorPalette.primary, 0.6) : lighter(colorPalette.accent, 0.6)
                setInvitation(prevInvitation => ({
                    ...prevInvitation,
                    cover: {
                        ...prevInvitation.cover,
                        color: invitation.generals.theme ? lighter(invitation.generals.palette.primary, 0.6) : lighter(invitation.generals.palette.accent, 0.6),
                    },
                }));
            }
        }

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                auto: e,
            },
        }));


        setSaved(false)


    }

    const timerTypes = [
        {
            borderRadius: '12px',
            background: 'red',
            width: '90%',
            padding: '10px 0'
        },
        {
            borderRadius: '0px',
            background: 'red',
            width: '100%',
            padding: '10px 0'
        },
        {
            borderRadius: '0px',
            background: 'transparent',
            width: '100%',
            padding: '10px 0'
        }
    ]

    const types = [
        {
            name: 'Redondeado',
            content: {
                borderRadius: '12px',
                background: timerColor,
                width: '90%',
                padding: '10px 0'
            },
        },
        {
            name: 'Completo',
            content: {
                borderRadius: '0px',
                background: timerColor,
                width: '100%',
                padding: '10px 0'
            },
        },
        {
            name: 'Transparente',
            content: {
                borderRadius: '0px',
                background: 'transparent',
                width: '100%',
                padding: '10px 0'
            }
        }
    ]

    const startDrag = (event) => {
        setIsDragging(true);
        setLastMousePosition({ x: event.clientX, y: event.clientY });
    };

    const drag = (event) => {
        if (isDragging) {
            const deltaX = event.clientX - lastMousePosition.x;
            const deltaY = event.clientY - lastMousePosition.y;

            setMapPosition((prevPosition) => {
                // Definir límites
                const minX = -550;
                const maxX = 550; // Ancho máximo del contenedor
                const minY = -500;
                const maxY = 150; // Altura máxima del contenedor

                // Calcular nueva posición con límites
                const newX = Math.min(Math.max(prevPosition.x + deltaX, minX), maxX);
                const newY = Math.min(Math.max(prevPosition.y + deltaY, minY), maxY);

                return { x: newX, y: newY };
            });

            setLastMousePosition({ x: event.clientX, y: event.clientY });
        }
    };

    const stopDrag = () => {
        setIsDragging(false);
    };

    const resetPositions = () => {
        setMapPosition({ x: 0, y: 0 })
        setZoomLevel(1)
    }

    useEffect(() => {
        setSaved(false)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                mapPosition: mapPosition,
            },
        }));
    }, [mapPosition])

    useEffect(() => {
        setSaved(false)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                zoomLevel: zoomLevel,
            },
        }));
    }, [zoomLevel])




    return (


        invitation ?
            <>
                {
                    <div className='scroll-item generals-main-container' >
                        <div className='build-component-elements'>
                            <span className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                                style={{
                                    width: '100%',
                                }}
                            >Portada</span>
                            <span className='gc-content-label'>Título</span>

                            <div className='general-cards-single-row' style={{ width: '100%', gap: '4px' }}>
                                <Input
                                    placeholder={'Título'}
                                    value={invitation.cover.title}
                                    onChange={onChangeTitle}
                                    className='gc-input-text' />
                                <Button
                                    type='ghost'
                                    onClick={() => setOnSettings(!onSettings)}
                                    id={`build-cover-date-buttons${onSettings ? '--active' : ''}`}
                                    icon={<LuSettings2 size={18} />} />
                            </div>



                            <span className='gc-content-label'>Posición</span>

                            <div className='gc-position-container'>

                                {
                                    position.map((item, index) => (
                                        <div
                                            key={index}
                                            onClick={() => handlePosition(item.justify, item.align)}
                                            className='gc-position-item'>


                                            {
                                                item.justify === itemJustify && item.align === itemAlign ?
                                                    <div
                                                        className='gc-position-selected-container '
                                                        style={{
                                                            alignItems: itemJustify === 'left' ? 'flex-start' : itemJustify === 'right' ? 'flex-end' : 'center',
                                                        }}>

                                                        <div
                                                            className='gc-position-selected-item'
                                                            style={{
                                                                width: '70%'
                                                            }} />
                                                        <div
                                                            className='gc-position-selected-item'
                                                            style={{
                                                                width: '100%',
                                                                margin: '3px 0',
                                                            }} />
                                                        <div
                                                            className='gc-position-selected-item'
                                                            style={{
                                                                width: '30%',
                                                            }} />



                                                    </div>

                                                    : <div style={{
                                                        height: '5px', aspectRatio: '1', borderRadius: '50%',
                                                        backgroundColor: '#d9d9d9'
                                                    }} />


                                            }

                                        </div>
                                    ))
                                }

                            </div>



                            {
                                onSettings && (
                                    <div className='generals-settings-popup'>

                                        <span className='gc-content-label'>Tipo de letra</span>

                                        <Select

                                            value={invitation.cover.image}
                                            onChange={(e) => handleFont(e)}
                                            style={{ width: '100%', fontFamily: invitation.cover.image, }}>
                                            {fonts.map((font, index) => (
                                                <Option style={{ fontFamily: font }} key={index} value={font}>{font}</Option>
                                            ))}

                                        </Select>

                                        <Col style={{
                                            width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column',
                                            marginTop: '10px'
                                        }}>
                                            <span className='gc-content-label'>Tamaño</span>

                                            <Slider
                                                style={{ width: '95%', }}
                                                min={2}
                                                max={10}
                                                step={0.01}
                                                onChange={(e) => setInvitation(prevInvitation => ({
                                                    ...prevInvitation,
                                                    cover: {
                                                        ...prevInvitation.cover,
                                                        fontSize: e ? e : prevInvitation.cover.fontSize,
                                                    },
                                                }))}
                                                // onChange={onChange}
                                                value={invitation.cover.fontSize}
                                            />

                                            <Row style={{
                                                width: '100%', display: 'flex', alignItems: 'center',
                                                justifyContent: 'space-between', flexDirection: 'row'
                                            }}>
                                                <Col style={{
                                                    width: '48%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column'
                                                }}>

                                                    <span className='gc-content-label'>Opacidad</span>


                                                    <Slider
                                                        style={{ width: '95%' }}
                                                        // trackStyle={"#000"}
                                                        // handleStyle={{ borderColor: 'red' }}
                                                        min={0.1}
                                                        max={1}
                                                        step={0.01}
                                                        onChange={(e) => setInvitation(prevInvitation => ({
                                                            ...prevInvitation,
                                                            cover: {
                                                                ...prevInvitation.cover,
                                                                opacity: e ? e : prevInvitation.cover.opacity,
                                                            },
                                                        }))}
                                                        // onChange={onChange}
                                                        value={invitation.cover.opacity}
                                                    />

                                                </Col>

                                                <Col style={{
                                                    width: '48%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column'
                                                }}>
                                                    <span className='gc-content-label'>Grosor</span>



                                                    <Slider
                                                        style={{ width: '95%' }}
                                                        min={100}
                                                        max={1000}
                                                        step={100}

                                                        onChange={(e) => setInvitation(prevInvitation => ({
                                                            ...prevInvitation,
                                                            cover: {
                                                                ...prevInvitation.cover,
                                                                fontWeight: e ? e : prevInvitation.cover.fontWeight,
                                                            },
                                                        }))}

                                                        value={invitation.cover.fontWeight}
                                                    />

                                                </Col>
                                            </Row>
                                        </Col>

                                        <Row style={{
                                            display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                                            flexDirection: 'row', width: '100%'
                                        }}>
                                            <span className='gc-content-label'>Color</span>
                                            {/* <div className='general-cards-single-row'>
                                                <span>Match inteligente</span>
                                                <Switch
                                                    size='small'
                                                    value={invitation.cover.auto}
                                                    onChange={handleColorAuto} />
                                            </div> */}

                                        </Row>

                                        <div className='generl-card-color-item'>
                                            <span>{invitation.cover.color}</span>
                                            <div className='general-cards-single-row'>
                                                <ColorPicker
                                                    disabledAlpha={false}
                                                    value={invitation.cover.color}
                                                    style={{ width: '80px' }}
                                                    onChange={(e) => onChnageTitleColor(e)}>
                                                </ColorPicker>
                                            </div>

                                        </div>

                                    </div>
                                )
                            }


                        </div>

                        <div className='build-component-elements'>
                            <div className='general-cards-single-row' style={{ width: '100%', justifyContent: 'space-between' }}>

                                <span className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                                >Fecha</span>

                                <div className='general-cards-single-row' style={{ gap: '6px' }}>
                                    <Button
                                        onClick={() => handleFlexDirection(!datePosition)}
                                        icon={<HiSwitchVertical />} id={datePosition ? "build-cover-date-buttons" : "build-cover-date-buttons--active"}>Invertir</Button>

                                    <Button
                                        onClick={invitation.cover.date ? () => onChangeDate(null) : () => onChangeDate(dayjs('2030-01-01'))}
                                        icon={!invitation.cover.date ? <BiHide /> : <BiShow />}
                                        id={invitation.cover.date ? "build-cover-date-buttons" : "build-cover-date-buttons--active"} />

                                </div>


                            </div>



                            {
                                invitation.cover.date &&
                                <>
                                    <span className='gc-content-label'>Fecha del evento</span>

                                    <DatePicker
                                        className='gc-date-picker'
                                        style={{
                                            marginBottom: datePosition ? '0px' : '5px',
                                        }}
                                        onChange={onChangeDate}
                                        value={invitation.cover.date ? dayjs(invitation.cover.date.split('T')[0]) : null}
                                    />

                                    <Row style={{
                                        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                                        flexDirection: 'row', width: '100%'
                                    }}>
                                        <span className='gc-content-label'>Color</span>
                                        {/* <div className='general-cards-single-row'>
                                            <span>Match inteligente</span>
                                            <Switch
                                                size='small'
                                                value={invitation.cover.timerColor ? false : true}
                                                onChange={() => onChnageTimerColor(invitation.cover.timerColor ? null : null)} />
                                        </div> */}

                                    </Row>

                                    <div className='generl-card-color-item'>
                                        <span>{invitation.cover.timerColor ? invitation.cover.timerColor : invitation.cover.color}</span>
                                        <div className='general-cards-single-row'>
                                            <ColorPicker
                                                disabledAlpha={false}
                                                value={invitation.cover.timerColor ? invitation.cover.timerColor : invitation.cover.color}
                                                style={{ width: '80px' }}
                                                onChange={(e) => onChnageTimerColor(e)}>
                                            </ColorPicker>
                                        </div>

                                    </div>

                                </>
                            }

                        </div>

                        <div className='build-component-elements'>

                            <div style={{
                                display: 'flex', alignItems: 'center', justifyContent: 'space-between', alignSelf: 'stretch'
                            }}>
                                <span className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                                >Imagen</span>

                                {
                                    invitation.cover.featured_dev &&
                                    <Button
                                        onClick={() => setSettingsModal(true)}
                                        icon={<TbArrowsMove />} id={"build-cover-date-buttons"}>Editar posición</Button>
                                }


                            </div>

                            <div className='gc-edit-featured-container' style={{ position: 'relative' }}>

                                {
                                    invitation.cover.featured_dev ?

                                        <>
                                            <img src={invitation.cover.featured_dev}
                                                style={{ transform: `translate(${position.x}px, ${position.y}px) scale(${zoom})` }}
                                            />

                                            <div style={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '6px',
                                                position: 'absolute', bottom: '10px', right: '10px'
                                            }}>

                                                <Button
                                                    type='ghost'
                                                    style={{ opacity: 0.8 }}
                                                    id="build-cover-date-buttons"
                                                    onClick={removeImageDB}
                                                    icon={<RiDeleteBack2Line size={16} />}
                                                >Eliminar</Button>



                                            </div>
                                        </>


                                        :

                                        <Upload

                                            onChange={handleCustomRequest}
                                            showUploadList={false} // Oculta la lista de archivos subidos
                                            beforeUpload={() => false} // Evita la carga automática de archivos
                                        >

                                            <Button className="primarybutton" icon={<LuImagePlus />}>Agregar portada</Button>

                                        </Upload>
                                }



                            </div>



                        </div>



                    </div >
                }

                {
                    settingsModal &&
                    <div style={{
                    }} className='adjust-image-modal-main-cont'>

                        <div className='adj-cover-cont'>
                            {/* <div className='adj-image-msg-container'>
                                <span className='adj-title-text'>
                                    Ajusta tu imagen de portada
                                </span>
                            </div> */}

                            <div
                                style={{ transform: `scale(0.8)` }}
                                className={`inv-device-main-container-ios`}>
                                <div className={`device-buttons-container-ios`}>
                                    <div className={`device-button-ios`} />
                                    <div className={`device-button-ios`} />
                                    <div className={`device-button-ios`} />
                                </div>
                                <div className={`device-power-button-ios`} />
                                <div className={`inv-device-container-ios`}>

                                    <div className={`inv-black-space-ios`}>
                                        <span>5:15</span>
                                        <div className={`camera-ios`} />
                                        <div>
                                            <img src={ios_settings} style={{
                                                height: '100%', objectFit: 'cover'
                                            }} />
                                        </div>
                                    </div>

                                    <div
                                        className={`ios-invitation inv-set-position grid-guides`}
                                        style={{
                                            background: invitation.generals.palette.primary,
                                        }}
                                    >

                                        <div
                                            onMouseDown={startDrag}
                                            onMouseMove={drag}
                                            onMouseUp={stopDrag}
                                            onMouseLeave={stopDrag}
                                            ref={mapContainerRef}
                                            style={{
                                                top: `${invitation.cover.mapPosition.y}px`,
                                                left: `${invitation.cover.mapPosition.x}px`,
                                                transform: `scale(${invitation.cover.zoomLevel})`,
                                                zIndex: 3
                                            }}
                                            className='adj-image-container'>
                                            <img
                                                src={invitation.cover.featured_dev}
                                            />
                                            <div style={{
                                                position: 'absolute', width: '100%', height: '100%', top: '0px', left: '0px',
                                                background: `linear-gradient(to top, ${invitation.generals.theme ? darker(invitation.generals.palette.primary, 0.2) : darker(invitation.generals.palette.primary, 0.2)}, rgba(0,0,0,0))`
                                            }}></div>


                                        </div>
                                        <div className='background-cover'
                                            style={{
                                                flexDirection: invitation.cover.flexDirection,
                                                pointerEvents: 'none',
                                                zIndex: 3
                                            }}>



                                            <div className='cover--title-container' style={{
                                                alignItems: invitation.cover.align, height: invitation.cover.date ? '75%' : '100%',
                                                padding: invitation.cover.date ? 0 : '10px',

                                            }}>
                                                <span style={{
                                                    color: !invitation.cover.color ? invitation.generals.theme ? lighter(invitation.generals.palette.primary, 0.6) : lighter(invitation.generals.palette.accent, 0.6) : invitation.cover.color, width: '100%',
                                                    textAlign: invitation.cover.justify, fontSize: `${invitation.cover.fontSize}em`, wordBreak: 'break-word',
                                                    opacity: invitation.cover.opacity, fontFamily: invitation.cover.image, fontWeight: invitation.cover.fontWeight,
                                                    lineHeight: '1'
                                                }}>{invitation.cover.title}</span>
                                            </div>

                                            {
                                                invitation.cover.date && (
                                                    <div style={{
                                                        width: '100%',
                                                        backgroundColor: `transparent`,

                                                        height: '25%', marginTop: '10px',
                                                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                                                    }}>

                                                        <Countdown mainColor={invitation.cover.color} color={invitation.cover.timerColor} colorPalette={invitation.generals.palette} targetDate={invitation.cover.date} theme={invitation.generals.theme} font={invitation.cover.image} fontWeight={invitation.cover.fontWeight} dev={true} />

                                                    </div>
                                                )
                                            }



                                        </div>

                                        {
                                            ruleLines &&
                                            <div className="guides-container">
                                                <div style={{ background: `${invitation.generals.palette.buttons}80` }} className="line vertical center"></div>
                                                <div style={{ background: `${invitation.generals.palette.buttons}80` }} className="line horizontal center"></div>
                                                <div style={{ background: `${invitation.generals.palette.buttons}80` }} className="line vertical left-quarter"></div>
                                                <div style={{ background: `${invitation.generals.palette.buttons}80` }} className="line vertical right-quarter"></div>
                                                <div style={{ background: `${invitation.generals.palette.buttons}80` }} className="line horizontal top-quarter"></div>
                                                <div style={{ background: `${invitation.generals.palette.buttons}80` }} className="line horizontal bottom-quarter"></div>
                                            </div>
                                        }

                                    </div>
                                    <div className={`inv-light-space-ios`} />
                                </div>
                            </div>

                            <div className='adj-image-tools-container'>

                                <Button
                                    id="expandedbutton"
                                    className={`full-screen-button${ruleLines ? '--active' : ''}`}
                                    onClick={() => setRuleLines(!ruleLines)}
                                    icon={<LuRuler size={20} />}
                                    style={{ backgroundColor: '#FFF' }}
                                />


                                <div className='slider-container-adj-image' >
                                    <FaPlus size={12} />
                                    <Slider
                                        vertical
                                        min={minZoom}
                                        max={maxZoom}
                                        step={zoomStep}
                                        onChange={(e) => setZoomLevel(e)}
                                        value={zoomLevel}
                                        style={{ flex: 1 }}
                                    />
                                    <FaMinus size={12} />
                                </div>

                                <Button
                                    id="expandedbutton"
                                    className={`full-screen-button`}
                                    onClick={resetPositions}
                                    icon={<PiAlignCenterVerticalSimple size={20} />}
                                    style={{ backgroundColor: '#FFF' }}
                                />

                                <Button
                                    id="expandedbutton"
                                    className={`full-screen-button`}
                                    onClick={() => setMapPosition({ x: 0, y: mapPosition.y })}
                                    icon={<PiAlignCenterHorizontal size={20} />}
                                    style={{ backgroundColor: '#FFF' }}
                                />

                                <Button
                                    id="expandedbutton"
                                    className={`full-screen-button`}
                                    onClick={() => setMapPosition({ x: mapPosition.x, y: 0 })}
                                    icon={<PiAlignCenterVertical size={20} />}
                                    style={{ backgroundColor: '#FFF' }}
                                />



                            </div>

                            <Button
                                type='ghost'
                                // id="expandedbutton"
                                // className='full-screen-button'
                                onClick={() => setSettingsModal(false)}
                                icon={<IoClose size={20} />}
                                style={{ position: 'absolute', top: '15px', right: '15px' }}
                            />
                        </div>

                    </div>
                }


            </>


            : <></>



    )
}






