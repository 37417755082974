import React, { useEffect, useRef, useState } from 'react'
import './dynamic-table.css'

export const DynamicTable = ({ onSelectedTable, setOnSelectedTable, tables, setTables, onMoving, totalChairs, occupiedChairs, position, id }) => {
    const [chairs, setChairs] = useState([]);
    const [mapPosition, setMapPosition] = useState(position); // Se mantiene fija la posición inicial
    const [isDragging, setIsDragging] = useState(false);
    const [lastMousePosition, setLastMousePosition] = useState(position);


    const mapContainerRef = useRef(null);

    useEffect(() => {
        const containerSize = 200;
        const tableSize = 50;
        const radius = (containerSize / 2) - (tableSize / 2) - 20;
        const centerX = containerSize / 2;
        const centerY = containerSize / 2;

        const newChairs = Array.from({ length: totalChairs }, (_, i) => {
            const angle = (i * (360 / totalChairs)) * (Math.PI / 180);
            return {
                id: i + 1,
                x: centerX + radius * Math.cos(angle),
                y: centerY + radius * Math.sin(angle),
                occupied: i < occupiedChairs,
            };
        });

        setChairs(newChairs);
    }, [totalChairs, occupiedChairs]);

    const startDrag = (event) => {
        if (onMoving) {
            setIsDragging(true);
            setLastMousePosition({ x: event.clientX, y: event.clientY });
        }
    };

    const drag = (event) => {
        if (isDragging) {
            const deltaX = event.clientX - lastMousePosition.x;
            const deltaY = event.clientY - lastMousePosition.y;

            setMapPosition((prevPosition) => ({
                x: prevPosition.x + deltaX,
                y: prevPosition.y + deltaY,
            }));

            setLastMousePosition({ x: event.clientX, y: event.clientY });
        }
    };

    const stopDrag = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        setTables((prevTables) =>
            prevTables.map((table) =>
                table.id === id ? { ...table, position: mapPosition } : table
            )
        );
    }, [mapPosition, id, setTables]);

    return (
        <div
            onClick={() => setOnSelectedTable(!onMoving ? onSelectedTable === id ? null : id : null)}
            onMouseDown={startDrag}
            onMouseMove={drag}
            onMouseUp={stopDrag}
            onMouseLeave={stopDrag}
            ref={mapContainerRef}
            style={{
                top: `${mapPosition.y}px`,
                left: `${mapPosition.x}px`,
                cursor: onMoving ? 'grab' : 'pointer'
            }}
            className="dynamic-container">
            <div className={`container ${onMoving && 'moving-container'}`}  >
                <div
                    className={`table ${onMoving && 'moving-table'}`}
                    style={{
                        backgroundColor: onSelectedTable === id ? '#FFF5D0' : totalChairs === occupiedChairs ? '#F5F3F2' : '#FFB32D',
                        border: onSelectedTable === id && '2px solid #FFB32D',

                    }}
                >
                    #{id}
                </div>
                {chairs.map((chair) => (
                    <div
                        key={chair.id}
                        className={`chair ${chair.occupied ? "occupied" : "available"} ${onMoving && 'moving-table'}`}
                        style={{
                            left: `${chair.x}px`,
                            top: `${chair.y}px`,
                            backgroundColor: onSelectedTable === id && '#FFF5D0',
                            border: onSelectedTable === id && '2px solid #FFB32D',

                        }}
                    >
                        {chair.id}
                    </div>
                ))}
            </div>
        </div>
    );
};