import React, { useContext, useEffect, useRef, useState } from 'react'
import { Card } from '../../components/invitation/Card'
import { darker, lighter } from '../../helpers/functions'
import { appContext } from '../../context/AuthContext'
import { Separador } from '../../components/invitation/Logos';
import '../../styles/modules/itinerary.css'
import '../../styles/modules/module-generals.css'


export const Itinerary = ({ content, itineraryRef, dev }) => {
    const { MainColor, theme, font, colorPalette } = useContext(appContext);
    // const itineraryRef = useRef(null)


    return (
        <>
            {
                content.active && colorPalette ?
                    <>
                        <div ref={itineraryRef} className='gm-container'

                            style={{
                                backgroundColor: content.background ? colorPalette.secondary : 'transparent',
                                padding: content.background ? '32px' : '0px 32px'
                            }}
                        >
                            <div className="g-module-info-container" >

                                <span
                                    className={!dev ? "g-module-title" : "g-module-title-dev"}
                                    style={{
                                        color: colorPalette.accent,
                                        fontFamily: font,
                                    }}
                                >
                                    ITINERARIO
                                </span>
                                <div className='itinerary-cards-container'>
                                    <Card background={content.background} dev={dev} steps={content.object} MainColor={MainColor} theme={theme} font={font} colorPalette={colorPalette} />
                                </div>

                            </div>

                        </div>
                        {content.separator &&
                            <Separador MainColor={colorPalette.accent} theme={theme} />
                        }
                    </>

                    : <></>
            }
        </>
    );
};

