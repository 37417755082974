import React, { useContext, useEffect, useRef } from 'react'
import { GiftCards } from '../../components/invitation/GiftCards'
import { darker, lighter } from '../../helpers/functions'
import { appContext } from '../../context/AuthContext'
import { Separador } from '../../components/invitation/Logos'
import '../../styles/modules/gifts.css'



export const TableGifts = ({ content, giftsRef, dev, land }) => {

    const { MainColor, theme, font, colorPalette } = useContext(appContext)
    // const giftsRef = useRef(null)


    return (
        <>
            {content.active && colorPalette ? (
                <>
                    <div ref={giftsRef} className='gm-container'
                        style={{
                            backgroundColor: content.background ? colorPalette.secondary : 'transparent',
                            padding: '0px 32px'
                        }}
                    >
                        <div className="g-module-info-container">

                            <span className={!dev ? "g-module-title" : "g-module-title-dev"} style={{ color: colorPalette.accent, fontFamily: font }}>
                                {content.title}
                            </span>

                            <span className={!dev ? "g-mdoule-regular-text" : "g-mdoule-regular-text-dev"} style={{ color: colorPalette.accent, fontFamily: font }}>
                                {content.description}
                            </span>

                            <div className={dev || land ? "gifts-scroll-invitation-dev" : "gifts-scroll-invitation"} >
                                <GiftCards dev={dev} cards={content.cards} MainColor={MainColor} theme={theme} font={font} colorPalette={colorPalette} />
                            </div>
                        </div>



                    </div>
                    {content.separator &&
                        <Separador MainColor={colorPalette.accent} theme={theme} />
                    }
                </>

            ) : null}
        </>
    )
}
