import { Button, Col, Drawer, Empty, Input, InputNumber, Row, Switch, TimePicker } from 'antd'
import React, { useState, useRef, useEffect, useContext } from 'react'
import dayjs from 'dayjs';
import { convert12HrTo24Hr, formatTimeTo12Hours, iconsItinerary, IoIosArrowDown, load, RiDeleteBack2Line, TbEyeClosed, } from '../../helpers';
import { IconsModal } from '../../components/build'

import {
    IoMdAdd, LuSeparatorHorizontal, FaArrowUp, BiSolidColorFill, LuBadgeHelp,
    IoReturnDownBack, GrLocation, LiaSpotify, MdEdit, PiSpotifyLogoBold, FaRegTrashAlt,
    BsWindow, IoAddCircleOutline
} from '../../helpers';
import { HelpDrawer } from '../../components/build/HelpDrawer';
import { HowToDrawer } from '../../components/build/HowToDrawer';
import { CustomButton } from '../../components/CustomButton';
import { appContext } from '../../context';

const playlistmsg = "Por el momento, solo es posible compartir álbumes y playlists. Si intentas agregar una canción individual, aparecerá un error. ¡Gracias por tu comprensión!"
const addressmsg = "Para mostrar el mapa y el clima de un lugar, es necesario que completes todos los campos de la dirección. Esto nos permitirá ubicar con precisión la ubicación."


export const BuildItinerary = ({ invitation, setInvitation, setSaved, onDesign }) => {


    const instanciasContainer = useRef(null);
    // const { onDesign } = useContext(appContext)
    const [currentItem, setCurrentItem] = useState(null)
    const [onInstances, setOnInstances] = useState(false)
    const [onSpotify, setOnSpotify] = useState(false)
    const [onAdress, setOnAdress] = useState(false)
    const [onSaveClose, setOnSaveClose] = useState(false)
    const [onDelete, setOnDelete] = useState(false)
    const [onWheather, setOnWheather] = useState(false)
    const [onNewInstance, setOnNewInstance] = useState(false)
    const [currentIcon, setCurrentIcon] = useState(1)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [playlistValue, setPlaylistValue] = useState()

    const [onAddressMode, setOnAdressMode] = useState(false)
    const [onSubitemsMode, setOnSubitemsMode] = useState(false)
    const [onPlaylistMode, setOnPlaylistMode] = useState(false)
    const [visible, setVisible] = useState(false)
    const [howToVisible, setHowToVisible] = useState(false)
    const [currentSelection, setCurrentSelection] = useState('generals')

    const [type, setType] = useState(null)
    const [location, setLocation] = useState('generals')



    useEffect(() => {
        setLocation('generals')
    }, [currentItem])



    const handleNewItem = () => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: [
                    ...prevInvitation.itinerary.object,
                    {
                        name: "Nuevo momento", // Nombre del nuevo ítem
                        time: "00:00 am", // Horario del nuevo ítem
                        subname: " ",
                        address: null,
                        subitems: null,
                        playlist: null,
                        active: false,
                        image: LuBadgeHelp,
                        id: Math.random().toString(36).substr(2, 9) // ID único generado aleatoriamente
                    }
                ],
            },
        }));
        setSaved(false)
    }

    const onNameChange = (objectId, newName) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            name: newName // Cambiamos el nombre del objeto
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    };
    const onTimeChange = (objectId, time) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            time: time // Cambiamos el nombre del objeto
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    };
    const onSubnameChange = (objectId, subname) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            subname: subname // Cambiamos el nombre del objeto
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    };

    //Adress
    const onCalleChange = (objectId, calle) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            address: {
                                ...obj.address,
                                calle: calle
                            }
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const onNumeroChange = (objectId, new_) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            address: {
                                ...obj.address,
                                numero: new_
                            }
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const onColoniaChange = (objectId, new_) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            address: {
                                ...obj.address,
                                colonia: new_
                            }
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const onCPChange = (objectId, new_) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            address: {
                                ...obj.address,
                                CP: new_
                            }
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const onCiudadChange = (objectId, new_) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            address: {
                                ...obj.address,
                                ciudad: new_
                            }
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const onEstadoChange = (objectId, new_) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            address: {
                                ...obj.address,
                                estado: new_
                            }
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const onUrlChange = (objectId, new_) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            address: {
                                ...obj.address,
                                url: new_
                            }
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }


    //subtimes

    const onSubNameChange = (objectId, index, newName) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            subitems: obj.subitems.map((subitem, subIndex) => {
                                // Si el índice coincide, actualizamos el nombre
                                if (subIndex === index) {
                                    return {
                                        ...subitem,
                                        name: newName
                                    };
                                }
                                // Si no coincide, devolvemos el subitem sin cambios
                                return subitem;
                            })
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const onSubTimeChange = (objectId, index, newValue) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            subitems: obj.subitems.map((subitem, subIndex) => {
                                // Si el índice coincide, actualizamos el nombre
                                if (subIndex === index) {
                                    return {
                                        ...subitem,
                                        time: newValue
                                    };
                                }
                                // Si no coincide, devolvemos el subitem sin cambios
                                return subitem;
                            })
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const onSubDescriptionChange = (objectId, index, newValue) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            subitems: obj.subitems.map((subitem, subIndex) => {
                                // Si el índice coincide, actualizamos el nombre
                                if (subIndex === index) {
                                    return {
                                        ...subitem,
                                        description: newValue
                                    };
                                }
                                // Si no coincide, devolvemos el subitem sin cambios
                                return subitem;
                            })
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const addNewSubitem = (item) => {
        setOnNewInstance(false)
        instanciasToBottom(item)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === item.id) {
                        return {
                            ...obj,
                            subitems: [
                                ...obj.subitems,
                                {
                                    name: "",
                                    time: "00:00 am",
                                    description: ""
                                }
                            ]
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const removeSubitem = (objectId, index) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            subitems: obj.subitems.filter((subitem, i) => i !== index)
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const uploadPlyalist = (objectId) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            playlist: [playlistValue] // Cambiamos el nombre del objeto
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }



    // Adding itrems


    const handleImage = (objectId, value) => {
        // setCurrentIcon(null)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            image: value
                        };
                    }
                    return obj;
                })
            }
        }));
        setSaved(false)
    };

    const handleTime = (objectId) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        // Si ya hay una playlist, la eliminamos (ponemos null), si no, agregamos el enlace de la playlist
                        return {
                            ...obj,
                            time: obj.time ? null : "00:00 am"
                        };
                    }
                    return obj;
                })
            }
        }));
        setSaved(false)
    };

    const handleSubname = (objectId) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        // Si ya hay una playlist, la eliminamos (ponemos null), si no, agregamos el enlace de la playlist
                        return {
                            ...obj,
                            subname: obj.subname ? null : "Descripción"
                        };
                    }
                    return obj;
                })
            }
        }));
        setSaved(false)
    };

    const handleAdress = (objectId) => {
        setOnAdress(false)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        // Si ya hay una playlist, la eliminamos (ponemos null), si no, agregamos el enlace de la playlist
                        return {
                            ...obj,
                            address: obj.address ? null : {
                                calle: null,
                                numero: null,
                                colonia: null,
                                CP: null,
                                ciudad: null,
                                estado: null,
                                url: null
                            }
                        };
                    }
                    return obj;
                })
            }
        }));
        setSaved(false)
    };

    const handleSubitems = (objectId) => {
        setOnInstances(false)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        // Si ya hay una playlist, la eliminamos (ponemos null), si no, agregamos el enlace de la playlist
                        return {
                            ...obj,
                            subitems: obj.subitems ? null : [
                                {
                                    name: null,
                                    time: null,
                                    description: null
                                }
                            ]
                        };
                    }
                    return obj;
                })
            }
        }));
        setSaved(false)
    };

    const handlePlaylist = (objectId) => {
        setOnSpotify(false)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        // Si ya hay una playlist, la eliminamos (ponemos null), si no, agregamos el enlace de la playlist
                        return {
                            ...obj,
                            playlist: obj.playlist ? null : []
                        };
                    }
                    return obj;
                })
            }
        }));
        setSaved(false)
    };

    const removeObjectById = (objectId) => {
        setOnDelete(false)
        setCurrentItem(null)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.filter(obj => obj.id !== objectId)
            }
        }));
        setSaved(false)
    };

    const handleActive = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                active: e,
            },
        }));
        setSaved(false)
    }

    const onSaveCloseItem = () => {
        setOnSaveClose(false)
        setCurrentItem(null)
    }

    const onChangeBackground = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                background: e,
            },
        }));
        setSaved(false)
    }

    const onChangeSeparator = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                separator: e,
            },
        }));
        setSaved(false)
    }

    const switchSettings = (type, value) => {
        if (type === 'address') {
            if (value) {
                setOnAdressMode(true);
                setOnSubitemsMode(false);
                setOnPlaylistMode(false)
            } else if (!value && !onSubitemsMode && !onPlaylistMode) {
                setOnAdressMode(false);
            }

        } else if (type === 'subitmes') {
            if (value) {
                setOnAdressMode(false);
                setOnSubitemsMode(true);
                setOnPlaylistMode(false)
            } else if (!value && !onAddressMode && !onPlaylistMode) {
                setOnSubitemsMode(false);
            }
        } else if (type === 'playlist') {
            if (value) {
                setOnAdressMode(false);
                setOnSubitemsMode(false);
                setOnPlaylistMode(true)
            } else if (!value && !onAddressMode && !onSubitemsMode) {
                setOnPlaylistMode(false);
            }
        }

    };

    const instanciasToBottom = (item) => {
        if (instanciasContainer.current) {
            instanciasContainer.current.scrollTo({
                top: (323 * (item.subitems.length + 1)),
                behavior: 'smooth'
            });
        }
    };

    const handleDrawer = (type) => {
        setType(type)
        setVisible(true)
        // console.log(type)
    }

    const handleHowTo = (type) => {
        setType(type)
        setHowToVisible(true)
        // console.log(type)
    }

    const renderIcon = (index, size) => {
        const icon = iconsItinerary.find(icon => icon.index === index);
        if (icon) {
            const IconComponent = icon.value;
            return <IconComponent size={size} />;
        }
        return <LuBadgeHelp size={size} />;
    };

    const handleTitle = (location) => {
        switch (location) {
            case 'generals': return 'Generales'
            case 'address': return 'Dirección'
            case 'items': return 'Instancias'
            case 'playlist': return 'Música'

                break;

            default:
                break;
        }
    }

    const handleClick = (item) => {
        if (currentItem !== item.id) {
            onSaveCloseItem();
        } else {
            setCurrentItem(item.id);
        }
    }


    return (
        <>
            {
                invitation ?
                    <div className='scroll-item generals-main-container'>
                        <div className='build-component-elements'>

                            <div className='general-cards-single-row' style={{ width: '100%', justifyContent: 'space-between' }}>
                                <div className='general-cards-single-row'>
                                    <span className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                                        style={{
                                            width: 'auto',
                                        }}
                                    >Itinerario</span>
                                    <Switch
                                        size='small'
                                        value={invitation.itinerary.active}
                                        onChange={handleActive} />
                                </div>


                                <div className='general-cards-single-row' style={{ gap: '5px' }}>
                                    {
                                        invitation.itinerary.active && (

                                            <>

                                                <Button
                                                    type='ghost'
                                                    onClick={() => onChangeSeparator(!invitation.itinerary.separator)}
                                                    id={`build-cover-date-buttons${invitation.itinerary.separator ? '--active' : ''}`}
                                                    icon={<LuSeparatorHorizontal size={18} />} />

                                                <Button
                                                    type='ghost'
                                                    onClick={() => onChangeBackground(!invitation.itinerary.background)}
                                                    id={`build-cover-date-buttons${invitation.itinerary.background ? '--active' : ''}`}
                                                    icon={<BiSolidColorFill size={18} />} />
                                            </>
                                        )
                                    }
                                </div>

                            </div>

                            {

                                invitation.itinerary.active &&
                                <CustomButton
                                    onClick={handleNewItem}
                                    icon={<IoMdAdd />} text={'Nuevo Momento'} style={{
                                        margin: '10px 0px'
                                    }} />
                            }



                        </div>

                        {

                            invitation.itinerary.active ?
                                <div className='build-component-elements'>

                                    {
                                        invitation.itinerary.object.map((item, index) => (

                                            <div
                                                key={index}
                                                // onClick={() => setCurrentItem(item.id)}
                                                className='generl-card-color-item' style={{ padding: '12px', cursor: 'pointer', backgroundColor: currentItem === item.id && '#F5F3F280', flexDirection: 'column', gap: '18px' }}>

                                                <div className='general-cards-single-row' style={{
                                                    width: '100%', justifyContent: 'space-between',
                                                    // backgroundColor: currentItem === item.id && '#E5E3E2',
                                                    // padding: currentItem === item.id && '8px',
                                                    borderRadius: '8px',
                                                }}>
                                                    <div className='general-cards-single-row'>
                                                        {
                                                            item.image ?
                                                                renderIcon(item.image, 34)
                                                                : <LuBadgeHelp size={34} />
                                                        }

                                                        <div className='build-generals-simple-column' style={{ gap: 0 }}>
                                                            <span className='single-card-name '>{item.name}</span>
                                                            <span className='single-card-time'>{item.time}</span>
                                                        </div>

                                                    </div>

                                                    <Button
                                                        type='ghost'
                                                        onClick={() => setCurrentItem(currentItem === item.id ? null : item.id)}
                                                        id={`build-cover-date-buttons`}
                                                        icon={<IoIosArrowDown size={18} style={{ transition: 'all 0.3s ease', transform: currentItem === item.id && 'rotate(180deg)' }} />} />


                                                </div>
                                                {
                                                    currentItem === item.id &&
                                                    <>
                                                        <div className='build-component-elements' style={{ gap: '2px' }}>
                                                            <div className='general-cards-single-row' style={{ width: '100%', justifyContent: 'space-between' }}>
                                                                <span className='gc-content-label'><b>Generales</b></span>
                                                                <Button
                                                                    type='ghost'
                                                                    onClick={() => setCurrentSelection(currentSelection === 'generals' ? null : 'generals')}
                                                                    id={`build-cover-date-buttons`}
                                                                    icon={<IoIosArrowDown size={18} style={{ transition: 'all 0.3s ease', transform: currentSelection === 'generals' && 'rotate(180deg)' }} />} />
                                                            </div>

                                                            {
                                                                currentSelection === 'generals'
                                                                &&
                                                                <>
                                                                    <IconsModal
                                                                        handleImage={handleImage} id={item.id}
                                                                        isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} currentIcon={currentIcon} setCurrentIcon={setCurrentIcon} />

                                                                    <div className='general-cards-single-row' style={{ width: '100%', gap: '12px' }}>
                                                                        <div className='build-generals-simple-column' style={{ width: 'auto', gap: '0px' }}>
                                                                            <span className='gc-content-label' style={{ fontSize: '12px' }}>Icono</span>
                                                                            <div style={{
                                                                                display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
                                                                                backgroundColor: '#FFFFFF', gap: '12px', border: '1px solid #EBEBEB',
                                                                                padding: '8px', borderRadius: '12px', height: '100%'
                                                                            }}>
                                                                                {
                                                                                    item.image ?
                                                                                        renderIcon(item.image, 30)
                                                                                        : <LuBadgeHelp size={30} />
                                                                                }

                                                                                <Button
                                                                                    type='ghost'
                                                                                    id="build-cover-date-buttons"
                                                                                    onClick={() => setIsModalOpen(true)}
                                                                                    icon={<MdEdit size={16} />}
                                                                                >Editar</Button>


                                                                            </div>
                                                                        </div>

                                                                        <div className='build-generals-simple-column' style={{ gap: '6px', width: '100%' }}>
                                                                            <div className='build-generals-simple-column' style={{ gap: '2px', width: '100%' }}>
                                                                                <span className='gc-content-label' style={{ fontSize: '12px' }}>Nombre</span>
                                                                                <Input className='gc-input-text'
                                                                                    onChange={(e) => onNameChange(item.id, e.target.value)}
                                                                                    value={item.name} />
                                                                            </div>
                                                                            <div className='build-generals-simple-column' style={{ gap: '2px', width: '100%' }}>
                                                                                <div className='general-cards-single-row' style={{
                                                                                    width: '100%', justifyContent: 'space-between'
                                                                                }}>
                                                                                    <span className='gc-content-label' style={{ fontSize: '12px' }}>Hora</span>
                                                                                    <Switch
                                                                                        onChange={() => handleTime(item.id)}
                                                                                        checked={item.time ? true : false}
                                                                                        size='small' style={{
                                                                                            backgroundColor: item.time ? '#1777FF' : '#AAA', border: 'none',
                                                                                        }}
                                                                                    />


                                                                                </div>
                                                                                <TimePicker
                                                                                    disabled={item.time ? false : true}
                                                                                    className='gc-input-text'
                                                                                    value={dayjs(convert12HrTo24Hr(item.time), 'HH:mm')}
                                                                                    format={'HH:mm'}
                                                                                    onChange={(e) => onTimeChange(item.id, formatTimeTo12Hours(e))}

                                                                                />

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className='build-generals-simple-column' style={{ gap: '2px' }}>
                                                                        <div className='general-cards-single-row' style={{
                                                                            width: '100%', justifyContent: 'space-between'
                                                                        }}>
                                                                            <span className='gc-content-label' style={{ fontSize: '12px' }}>Nombre del lugar</span>
                                                                            <Switch
                                                                                onChange={() => handleSubname(item.id)}

                                                                                checked={item.subname ? true : false}
                                                                                size='small' style={{
                                                                                    backgroundColor: item.subname ? '#1777FF' : '#AAA', border: 'none',
                                                                                }}
                                                                            />



                                                                        </div>
                                                                        <Input

                                                                            disabled={item.subname ? false : true}
                                                                            className='gc-input-text'
                                                                            onChange={(e) => onSubnameChange(item.id, e.target.value)}
                                                                            value={item.subname} />
                                                                    </div>
                                                                </>
                                                            }




                                                        </div>

                                                        <div style={{
                                                            width: '100%', height: '1px', backgroundColor: '#00000010'
                                                        }} />

                                                        <div className='build-component-elements' style={{ gap: '2px' }}>
                                                            <div className='general-cards-single-row' style={{ width: '100%', justifyContent: 'space-between' }} >
                                                                <div className='general-cards-single-row' style={{}} >
                                                                    <Switch
                                                                        size='small'
                                                                        checked={item.address ? true : false}
                                                                        onChange={(e) => handleAdress(item.id)} />
                                                                    <span className='gc-content-label'><b>Dirección</b></span>
                                                                </div>
                                                                {
                                                                    item.address &&
                                                                    <Button
                                                                        // disabled={!item.address ? true : false}
                                                                        type='ghost'
                                                                        onClick={() => setCurrentSelection(currentSelection === 'address' ? null : 'address')}
                                                                        id={`build-cover-date-buttons`}
                                                                        icon={<IoIosArrowDown size={18} style={{ transition: 'all 0.3s ease', transform: currentSelection === 'address' && 'rotate(180deg)' }} />} />
                                                                }

                                                            </div>


                                                            {
                                                                item.address && currentSelection === 'address' &&
                                                                <>
                                                                    <div className='build-generals-simple-column' style={{ gap: '2px' }}>
                                                                        <span className='gc-content-label' style={{ fontSize: '12px' }}>Calle</span>
                                                                        <Input
                                                                            disabled={item.address ? false : true}
                                                                            className='gc-input-text'
                                                                            onChange={(e) => onCalleChange(item.id, e.target.value)}
                                                                            value={item.address ? item.address.calle : ''} />
                                                                    </div>

                                                                    <div className='general-cards-single-row' style={{ width: '100%', justifyContent: 'space-between' }}>
                                                                        <div className='build-generals-simple-column' style={{ gap: '2px' }}>
                                                                            <span className='gc-content-label' style={{ fontSize: '12px' }}>Número</span>
                                                                            <InputNumber
                                                                                disabled={item.address ? false : true}
                                                                                className='gc-input-text'
                                                                                onChange={(e) => onNumeroChange(item.id, e)}
                                                                                value={item.address ? item.address.numero : ''} />
                                                                        </div>

                                                                        <div className='build-generals-simple-column' style={{ gap: '2px' }}>
                                                                            <span className='gc-content-label' style={{ fontSize: '12px' }}>Colonia</span>
                                                                            <Input
                                                                                disabled={item.address ? false : true}
                                                                                className='gc-input-text'
                                                                                onChange={(e) => onColoniaChange(item.id, e.target.value)}
                                                                                value={item.address ? item.address.colonia : ''} />
                                                                        </div>

                                                                    </div>

                                                                    <div className='general-cards-single-row' style={{ width: '100%', justifyContent: 'space-between' }}>
                                                                        <div className='build-generals-simple-column' style={{ gap: '2px' }}>
                                                                            <span className='gc-content-label' style={{ fontSize: '12px' }}>Código Postal</span>
                                                                            <InputNumber
                                                                                disabled={item.address ? false : true}
                                                                                className='gc-input-text'
                                                                                onChange={(e) => onCPChange(item.id, e)}
                                                                                value={item.address ? item.address.CP : ''} />
                                                                        </div>

                                                                        <div className='build-generals-simple-column' style={{ gap: '2px' }}>
                                                                            <span className='gc-content-label' style={{ fontSize: '12px' }}>Estado</span>
                                                                            <Input
                                                                                disabled={item.address ? false : true}
                                                                                className='gc-input-text'
                                                                                onChange={(e) => onEstadoChange(item.id, e.target.value)}
                                                                                value={item.address ? item.address.estado : ''} />
                                                                        </div>

                                                                    </div>

                                                                    <div className='build-generals-simple-column' style={{ gap: '2px' }}>
                                                                        <span className='gc-content-label' style={{ fontSize: '12px' }}>Ciudad</span>
                                                                        <Input
                                                                            disabled={item.address ? false : true}
                                                                            className='gc-input-text'
                                                                            onChange={(e) => onCiudadChange(item.id, e.target.value)}
                                                                            value={item.address ? item.address.ciudad : ''} />
                                                                    </div>

                                                                    <div className='build-generals-simple-column' style={{ gap: '2px' }}>
                                                                        <div className='general-cards-single-row'>
                                                                            <span className='gc-content-label' style={{ fontSize: '12px' }}>URL Google Maps</span>

                                                                            <LuBadgeHelp size={15}
                                                                                onClick={() => handleHowTo('maps')}
                                                                                style={{
                                                                                    color: '#FFA700', cursor: 'pointer',
                                                                                }} />
                                                                        </div>
                                                                        <Input
                                                                            disabled={item.address ? false : true}
                                                                            className='gc-input-text'
                                                                            onChange={(e) => onUrlChange(item.id, e.target.value)}
                                                                            value={item.address ? item.address.url : ''} />
                                                                    </div>

                                                                </>
                                                            }

                                                        </div>

                                                        <div style={{
                                                            width: '100%', height: '1px', backgroundColor: '#00000010'
                                                        }} />

                                                        <div className='build-component-elements' style={{ gap: '2px' }}>
                                                            <div className='general-cards-single-row' style={{
                                                                width: '100%', justifyContent: 'space-between'
                                                            }}>
                                                                <div className='general-cards-single-row' style={{}} >
                                                                    <Switch
                                                                        size='small'
                                                                        checked={item.subitems ? true : false}
                                                                        onChange={() => handleSubitems(item.id)} />
                                                                    <span className='gc-content-label'><b>Instancias</b></span>
                                                                </div>
                                                                {
                                                                    item.subitems
                                                                    &&
                                                                    <Button
                                                                        // disabled={!item.subitems ? true : false}
                                                                        type='ghost'
                                                                        onClick={() => setCurrentSelection(currentSelection === 'items' ? null : 'items')}
                                                                        id={`build-cover-date-buttons`}
                                                                        icon={<IoIosArrowDown size={18} style={{ transition: 'all 0.3s ease', transform: currentSelection === 'items' && 'rotate(180deg)' }} />} />
                                                                }

                                                            </div>

                                                            {
                                                                item.subitems && currentSelection === 'items' &&


                                                                <div style={{
                                                                    width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px 0px'
                                                                }}>

                                                                    <Button
                                                                        type='ghost'

                                                                        onClick={() => addNewSubitem(item)}
                                                                        id="build-cover-date-buttons"
                                                                        icon={<IoMdAdd size={20} />}>
                                                                        Nueva instancia
                                                                    </Button>
                                                                </div>

                                                            }



                                                            {

                                                                item.subitems && currentSelection === 'items' ?
                                                                    item.subitems.map((subitem, index) => (


                                                                        <div className='build-generals-simple-column instancia-container' >

                                                                            <div className='general-cards-single-row'>

                                                                                <div className='build-generals-simple-column' style={{ gap: '2px' }}>
                                                                                    <span className='gc-content-label' style={{ fontSize: '12px' }}>Nombre</span>
                                                                                    <Input
                                                                                        className='gc-input-text'
                                                                                        placeholder='Instancia'
                                                                                        value={subitem.name}
                                                                                        onChange={(e) => onSubNameChange(item.id, index, e.target.value)} />
                                                                                </div>

                                                                                <div className='build-generals-simple-column' style={{ gap: '2px' }}>
                                                                                    <div className='general-cards-single-row' style={{ width: '100%', justifyContent: 'space-between' }} >
                                                                                        <span className='gc-content-label' style={{ fontSize: '12px' }}>Hora</span>
                                                                                        <Switch
                                                                                            // onChange={() => handleSubname(item.id)}
                                                                                            onChange={() => onSubTimeChange(item.id, index, subitem.time ? null : "00:00 am")}
                                                                                            checked={subitem.time ? true : false}
                                                                                            size='small'
                                                                                        />
                                                                                    </div>

                                                                                    <TimePicker
                                                                                        disabled={subitem.time ? false : true}
                                                                                        placeholder='0:00'
                                                                                        className='gc-input-text'
                                                                                        value={dayjs(convert12HrTo24Hr(subitem.time), 'HH:mm')} format={'HH:mm'}
                                                                                        onChange={(e) => onSubTimeChange(item.id, index, formatTimeTo12Hours(e))} />
                                                                                </div>

                                                                            </div>

                                                                            <div className='build-generals-simple-column' style={{ gap: '2px' }}>

                                                                                <span className='gc-content-label' style={{ fontSize: '11px' }}>Descripción</span>
                                                                                <Input.TextArea
                                                                                    className='gc-input-text'
                                                                                    placeholder='¿De qué trata tu instancia?'
                                                                                    autoSize={{ minRows: 4, maxRows: 5 }}
                                                                                    style={{ borderRadius: '12px' }}
                                                                                    value={subitem.description}
                                                                                    onChange={(e) => onSubDescriptionChange(item.id, index, e.target.value)} />

                                                                                <div style={{
                                                                                    width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5px'
                                                                                }}>
                                                                                    <Button
                                                                                        type='ghost'
                                                                                        style={{ opacity: 0.8 }}
                                                                                        id="build-cover-date-buttons"
                                                                                        onClick={() => removeSubitem(item.id, index)}
                                                                                        icon={<RiDeleteBack2Line size={16} />}
                                                                                    >Eliminar</Button>
                                                                                </div>

                                                                            </div>

                                                                        </div>


                                                                    ))
                                                                    : <></>


                                                            }
                                                        </div>

                                                        <div style={{
                                                            width: '100%', height: '1px', backgroundColor: '#00000010'
                                                        }} />

                                                        <div className='build-component-elements' style={{ gap: '2px' }}>
                                                            <div className='general-cards-single-row' style={{
                                                                width: '100%', justifyContent: 'space-between'
                                                            }}>
                                                                <div className='general-cards-single-row' style={{}} >
                                                                    <Switch
                                                                        size='small'
                                                                        checked={item.playlist ? true : false}
                                                                        onChange={() => handlePlaylist(item.id)} />
                                                                    <span className='gc-content-label' ><b>Playlist / Album</b></span>
                                                                </div>
                                                                {
                                                                    item.playlist
                                                                    &&
                                                                    <Button
                                                                        // disabled={!item.subitems ? true : false}
                                                                        type='ghost'
                                                                        onClick={() => setCurrentSelection(currentSelection === 'playlist' ? null : 'playlist')}
                                                                        id={`build-cover-date-buttons`}
                                                                        icon={<IoIosArrowDown size={18} style={{ transition: 'all 0.3s ease', transform: currentSelection === 'playlist' && 'rotate(180deg)' }} />} />
                                                                }

                                                            </div>
                                                            {
                                                                currentSelection === 'playlist'
                                                                &&
                                                                <>
                                                                    <div className='general-cards-single-row'>
                                                                        <span className='gc-content-label' style={{ fontSize: '12px' }}>URL Spotify</span>

                                                                        <LuBadgeHelp size={15}
                                                                            onClick={() => handleHowTo('spotify')}
                                                                            style={{
                                                                                color: '#FFA700', cursor: 'pointer',
                                                                            }} />

                                                                    </div>

                                                                    <div className='general-cards-single-row' style={{
                                                                        width: '100%'
                                                                    }}>
                                                                        <Input
                                                                            value={item.playlist ? item.playlist[0] : ''}
                                                                            onChange={(e) => setPlaylistValue(e.target.value)}
                                                                            placeholder={item.playlist ? item.playlist[0] : ''}
                                                                            className='gc-input-text' />

                                                                        <CustomButton
                                                                            onClick={() => uploadPlyalist(item.id)}
                                                                            icon={<IoMdAdd />} text={'Guardar'} style={{
                                                                                // marginTop: '20px'
                                                                            }} />
                                                                    </div>
                                                                </>
                                                            }

                                                        </div>
                                                    </>
                                                }

                                            </div>



                                        ))

                                    }
                                </div>

                                : <div style={{
                                    width: '100%', height: '300px',
                                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}><TbEyeClosed size={32} style={{ color: '#717171' }} /></div>

                        }





                    </div >
                    : <></>
            }
            <HelpDrawer visible={visible} setVisible={setVisible} type={type} setType={setType} />
            <HowToDrawer visible={howToVisible} setVisible={setHowToVisible} type={type} setType={setType} />

        </>
    )
}
