
import { Footer } from 'antd/es/layout/layout';
import { FaArrowUp, FaArrowLeft, IoArrowUndo } from "../../helpers";
import { Button, Popconfirm } from 'antd';
import { RiMenu5Fill } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { CalendarLogo } from '../../components/Logo';

import { storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';

export const FooterBuild = ({
    invitation, buttons, currentSection, handleClick, setMode, onSaveChanges, saved, onTry, buttonsTry,
    position, setOnHide
}) => {

    const handleActions = (item) => {
        setOnHide(false)
        handleClick(item);
    }

    const handleMoode = () => {
        setMode('my-invitations')
    }


    const moodeAndDelete = async () => {
        setMode('my-invitations')

        await deleteIfDifferent(invitation.cover?.featured_prod, invitation.cover?.featured_dev, deleteImageFB);

        if (invitation.gallery?.gallery_dev) {
            await Promise.all(invitation.gallery.gallery_dev.map(dev =>
                deleteIfDifferent(invitation.gallery.gallery_prod?.includes(dev) ? dev : null, dev, deleteImageFB)
            ));
        }

        if (invitation.dresscode?.images_dev) {
            await Promise.all(invitation.dresscode.images_dev.map(dev =>
                deleteIfDifferent(invitation.dresscode.images_prod?.includes(dev) ? dev : null, dev, deleteImageFB)
            ));
        }

    }

    const deleteIfDifferent = async (prod, dev, deleteFn) => {
        if (dev && dev !== prod) {
            await deleteFn(dev);
        }
    };

    const deleteImageFB = async (url) => {
        const filePath = extractFilePathFromURL(url)

        const imageRef = ref(storage, filePath);

        try {
            await deleteObject(imageRef);
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const extractFilePathFromURL = (url) => {
        const regex = /https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/([^/]+)\/o\/(.+)\?alt=media&token=.+/;
        const match = url.match(regex);
        if (!match) {
            throw new Error('URL does not match Firebase Storage base URL.');
        }
        const filePath = decodeURIComponent(match[2]);
        return filePath;
    };



    return (
        <div className={onTry ? 'tools-main-container-onTry' : 'tools-main-container'} style={{
        }}>
            <div className={onTry ? 'tools-container-onTry' : 'tools-container'}>

                {
                    onTry ?
                        buttonsTry.map((item, index) => (
                            <div className='single-button-tag-container'>
                                <a
                                    className={`single-button${position === index ? '--selected' : ''} tag-button-tools`}
                                    key={index}
                                    onClick={() => handleActions(item)} >
                                    {item.icon}
                                </a>
                                {/* <div className='single-button-name-tag'>{item.name}</div> */}

                            </div>
                        ))
                        :
                        buttons.map((item, index) => (
                            <div className='single-button-tag-container'>
                                <a
                                    className={`single-button${currentSection === index + 1 ? '--selected' : ''} tag-button-tools`}
                                    key={index}
                                    onClick={() => handleActions(item)} >
                                    {item.icon}
                                </a>
                                <div className='single-button-name-tag'>{item.name}</div>

                            </div>
                        ))
                }

            </div>

        </div>

    )
}
