import React, { useContext } from 'react'
import { Button, Col, Dropdown, Layout, Menu, Row, Select, Switch } from 'antd';
import { appContext } from '../context/AuthContext';
import { darker, lighter } from '../helpers/functions';
import { LoveLink } from '../components/invitation/Logos';
const { Header } = Layout;


export const HeaderInvitation = ({
    visible, content
}) => {

    const { theme, font, colorPalette } = useContext(appContext)

    return (
        <div
            className="header-invitation"
            style={{
                background: `linear-gradient(to bottom, ${darker(colorPalette.primary, 0.8)} 0%, ${darker(colorPalette.primary, 0.95)} 51%, ${colorPalette.primary} 100%)`,
                display: visible ? 'flex' : 'none',
                zIndex: 99,
            }}
        >
            <span
                className="header-invitation-title"
                style={{
                    fontFamily: font,
                    color: lighter(colorPalette.accent, 0.5),
                }}
            >
                {content.title}
            </span>
        </div>
    )
}
