import React, { useContext, useEffect, useState } from 'react'
import { Button, Input, Layout, message } from 'antd';
import { CoverApp } from '../modules/invitation/Cover';
import { Greeting } from '../modules/invitation/Greeting';
import { Family } from '../modules/invitation/Family';
import { Quote } from '../modules/invitation/Quote';
import { Itinerary } from '../modules/invitation/Itinerary';
import { DressCode } from '../modules/invitation/DressCode';
import { TableGifts } from '../modules/invitation/TableGifts';
import { Notices } from '../modules/invitation/Notices';
import { Gallery } from '../modules/invitation/Gallery';
import { HeaderInvitation } from '../layouts/HeaderInvitation';
import { FooterInvitation } from '../layouts/FooterInvitation';
import { buttonsColorText, darker, lighter } from '../helpers/functions';
import { appContext } from '../context/AuthContext';
import { useInvitation } from '../hooks/customHook';
import { getInvitationbyID } from '../services/apiInvitation';
import { load, FaLock, IoClose } from '../helpers';
import { getGuestsByInvitationID, guestLogin } from '../services/apiGuests';
import { ConfirmDrawer, ConfirmDrawerMobile, ConfirmDrawerWeb } from '../components/ConfirmDrawer';
import { Helmet } from 'react-helmet';


const { Content } = Layout;

const colorApp = "#686033"

export const SeeHowPage = ({ invitationID }) => {


    const [isVisible, setIsVisible] = useState(false);
    const { response, loading, error, operation } = useInvitation()
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const { setMainColor, MainColor, setTheme, theme, setFont, font, setColorPalette, colorPalette } = useContext(appContext)
    const [loader, setLoader] = useState(false)
    const [validated, setValidated] = useState(true)
    const [invitation, setInvitation] = useState(null)
    const [type, setType] = useState(null)
    const [onDisclaimer, setOnDisclaimer] = useState(true)




    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const margin = 680; // Ajusta este valor según tu preferencia


            if (currentScrollPos < margin) {
                setIsVisible(false);
            } else {
                setIsVisible(prevScrollPos > currentScrollPos);

                setPrevScrollPos(currentScrollPos);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    const getData = () => {
        setLoader(true)
        getInvitationbyID(operation, invitationID)
    }

    useEffect(() => {
        if (response) {
            if (response.data.ok) {
                switch (response.data.msg) {
                    case "Get invitation By Id":
                        const inv = response.data.data
                        setMainColor(inv.generals.color)
                        if (inv.generals.palette) {
                            setColorPalette({
                                primary: inv.generals.palette.primary,
                                secondary: inv.generals.palette.secondary,
                                accent: inv.generals.palette.accent,
                                buttons: inv.generals.palette.buttons,
                            })
                        } else {
                            setColorPalette({
                                primary: lighter('#000000', 0.9),
                                secondary: lighter('#000000', 0.8),
                                accent: darker('#000000', 0.4),
                                buttons: lighter('#000000', 0.5)
                            })
                        }
                        setTheme(inv.generals.theme)
                        setFont(inv.generals.font)
                        setInvitation(inv)
                        setValidated(true)
                        setLoader(false)
                        if (type === 'open') {
                            message.success(`¡Hola!`)
                        }
                        break;

                    default:
                        break;
                }
            }

        }
    }, [response])


    const handlePosition = (id, index) => {
        switch (id) {
            case 1: return <Greeting key={index} id="greeting" content={invitation.greeting} dev={false} />
            case 2: return <Family key={index} id="family" content={invitation.family} dev={false} />
            case 3: return <Quote key={index} id="quote" content={invitation.quote} dev={false} />
            case 4: return <Itinerary key={index} id="itinerary" content={invitation.itinerary} dev={false} />
            case 5: return <DressCode key={index} id="dresscode" content={invitation.dresscode} dev={false} />
            case 6: return <TableGifts key={index} id="gifts" content={invitation.gifts} dev={false} />
            case 7: return <Notices key={index} id="notices" content={invitation.notices} dev={false} />
            case 8: return <Gallery key={index} id="gallery" content={invitation.gallery} dev={false} />

            default:
                break;
        }
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (type) {
            if (type === 'open') {
                setLoader(true)
                getInvitationbyID(operation, invitationID)
                setValidated(true)
            } else if (type === 'closed') {
                setValidated(false)
            }
        }
    }, [type])

    useEffect(() => {
        if (invitation) {
            document.title = invitation.cover.title;
        } else {
            document.title = 'I attend';
        }

    }, [invitation]);


    return (

        <>
            {
                !loader && invitation ?
                    <Layout style={{
                        display: 'flex',
                        width1: '100%'
                    }}>
                        <HeaderInvitation visible={isVisible} content={invitation.cover} />
                        <div
                            style={{
                                marginTop: '0vh',
                                overflowY: 'scroll',
                                height: 'auto',
                                display: 'flex',
                                alignItems: 'center', justifyContent: 'flex-start',
                                flexDirection: 'column',
                                backgroundColor: colorPalette.primary,
                                position: 'relative', width: '100%',
                                gap: '52px',
                                paddingBottom: '36px'
                            }}
                        >
                            {
                                onDisclaimer &&
                                <div className='test-inv-disclaimer'>
                                    <div className='row-disclaimer'>
                                        <b>Importante: </b>
                                        <Button type='ghost' onClick={() => setOnDisclaimer(false)} icon={<IoClose size={16} />} />
                                    </div>
                                    <span>Esta invitación es solo para que veas cómo se ve en tu dispositivo. No es la versión que debes compartir con tus invitados. </span>
                                </div>
                            }
                            <CoverApp content={invitation.cover} dev={false} height={'100vh'} />

                            {
                                invitation.generals.positions.map((position, index) => (
                                    handlePosition(position, index)
                                ))
                            }

                        </div>

                    </Layout>

                    : <div style={{
                        height: '100vh', display: 'flex', alignItems: 'center',
                        justifyContent: 'center',
                        // backgroundColor: lighter(MainColor, 0.9)
                    }}>
                        <img src={load} style={{
                            width: '10%'
                        }} />
                    </div>
            }


        </>

    )
}
