import React, { useContext, useEffect, useRef, useState } from 'react'
import { buttonsColorText, darker, lighter } from '../../helpers/functions'
import { appContext } from '../../context/AuthContext'
import { Separador } from '../../components/invitation/Logos'
import { Button, Col, Image, Row } from 'antd'
import { Link } from 'react-router-dom'
import '../../styles/modules/dresscode.css'
import '../../styles/modules/module-generals.css'


export const DressCode = ({ content, dresscodeRef, dev, land }) => {

    const { MainColor, theme, font, colorPalette } = useContext(appContext)
    const [images, setImages] = useState(null)

    useEffect(() => {
        if (content) {
            setImages(dev && !land ? content.images_dev : content.images_prod)
        }
    }, [content])



    return (
        <>
            {content.active && colorPalette ? (
                <>
                    <div ref={dresscodeRef} className='gm-container'
                        style={{
                            backgroundColor: content.background ? colorPalette.secondary : 'transparent',
                            padding: content.background ? '32px' : '0px 32px'
                        }}>
                        <div className="g-module-info-container">

                            <span className={!dev && !land ? "g-module-title" : "g-module-title-dev"} style={{ color: colorPalette.accent, fontFamily: font }}>
                                {content.title}
                            </span>

                            <span className={!dev && !land ? "g-mdoule-regular-text" : "g-mdoule-regular-text-dev"} style={{ color: colorPalette.accent, fontFamily: font }}>
                                {content.description}
                            </span>

                            {content.colors && (
                                <div className="dresscode-colors">
                                    {content.colors.map((color, index) => (
                                        <div key={index} className="dresscode-color" style={{ backgroundColor: color, marginRight: index + 1 === content.colors.length ? '0' : '10px' }} />
                                    ))}
                                </div>
                            )}

                            {content.onImages && (
                                <div className={dev || !land ? "gifts-scroll-invitation-dev" : "gifts-scroll-invitation"} >
                                    {images && images.map((image, index) => (
                                        <div key={index} className={dev && !land ? "dresscode-image-container-dev" : "dresscode-image-container"}>
                                            <img src={image} className="dresscode-image" alt={`Dresscode ${index}`} />
                                        </div>
                                    ))}
                                </div>
                            )}

                            {content.onLinks && (
                                <div className={dev && !land ? "dresscode-links-dev" : "dresscode-links"}>
                                    {content.links && content.links.map((link, index) => (
                                        <Link key={index} to={link.URL} target='_blank' className="dresscode-link">
                                            <Button className={dev && !land ? "dresscode-link-button" : "dresscode-link-button"} style={{ fontFamily: font, background: colorPalette.buttons, color: buttonsColorText(colorPalette.buttons) }}>
                                                {link.name}
                                            </Button>
                                        </Link>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    {content.separator &&
                        <Separador MainColor={colorPalette.accent} theme={theme} />
                    }</>

            ) : null}
        </>

    )
}


