import React, { useContext, useEffect, useRef } from 'react'
import { darker, lighter } from '../../helpers/functions'
import { appContext } from '../../context/AuthContext'
import { Separador, SmallSeparador } from '../../components/invitation/Logos'
import '../../styles/modules/module-generals.css'


export const Notices = ({ content, noticesRef, dev }) => {

    const { MainColor, theme, font, colorPalette } = useContext(appContext)
    // const noticesRef = useRef(null)


    return (
        <>
            {content.active && colorPalette ? (
                <>
                    <div ref={noticesRef} className='gm-container' style={{
                        backgroundColor: content.background ? colorPalette.secondary : 'transparent',
                        padding: content.background ? '32px' : '0px 32px'
                    }}>
                        <div className="g-module-info-container">

                            <span className={!dev ? "g-module-title" : "g-module-title-dev"} style={{ fontFamily: font, color: colorPalette.accent }}>
                                AVISOS
                            </span>

                            {content.notices && content.notices.map((item, index) => (
                                <div key={index} className="g-module-items-single-col">
                                    <span
                                        className={!dev ? "g-mdoule-regular-text" : "g-mdoule-regular-text-dev"}
                                        style={{
                                            color: colorPalette.accent,
                                            fontFamily: font,
                                            gap: '24px'
                                        }}
                                    >
                                        {item}
                                    </span>

                                    {index < content.notices.length - 1 && (
                                        <span className={!dev ? "g-mdoule-regular-text" : "g-mdoule-regular-text-dev"} style={{ fontFamily: font, color: colorPalette.accent }}>
                                            ...
                                        </span>
                                    )}
                                </div>
                            ))}
                        </div>



                    </div>
                    {content.separator &&
                        <Separador MainColor={colorPalette.accent} theme={theme} />
                    }
                </>

            ) : null}
        </>
    )
}
