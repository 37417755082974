import React, { useEffect, useState } from 'react';
import { Image } from 'antd';
import { extratImageID, lighter } from '../../helpers/functions';
import '../../styles/modules/gallery.css'

export const GalleryItems = ({ MainColor, content, dev, land }) => {

    const [images, setImages] = useState(null)

    useEffect(() => {
        if (content) {
            setImages(dev && !land ? content.gallery_dev : content.gallery_prod)
        }
    }, [content])


    return (

        <>
            {images && (
                images.map((item, index) => (
                    <div key={index} className="gallery-items-container">
                        <div
                            className={dev ? "gallery-items-inner-container-dev" : "gallery-items-inner-container"}
                            style={{ backgroundColor: lighter(MainColor, 0.8) }}
                        >
                            <img
                                src={item}
                                className="gallery-items-image"
                            />
                        </div>
                    </div>
                ))
            )}
        </>

    );
};
