import { Button, Input, message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { RiEyeCloseLine, RiEye2Line, IoClose, IoMdLogIn, tokenExpires, images, FaWhatsapp } from '../../helpers'
import { onLogin, onNewUser } from '../../services/apiLogin'
import { useInvitation } from '../../hooks'
import { appContext } from '../../context'
import { Link, useNavigate } from 'react-router-dom'

export const Login = ({ }) => {

    const { response, loading, error, operation } = useInvitation()
    const navigate = useNavigate();
    const { login, logged } = useContext(appContext)

    const [mini, setMinim] = useState(false)

    const [createAccount, setCreateAccount] = useState(false)
    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState(null)

    const [newName, setNewName] = useState(null)
    const [newUsername, setNewUsername] = useState(null)
    const [newPassword, setNewPassword] = useState(null)

    const [seePasswrod, setSeePasswrod] = useState(false)


    const handleLogin = () => {
        if (username && password) {
            onLogin(operation, username, password)

        } else {
            if (!username) {
                message.error('Por favor escribe un correo electrónico')
            }
            else {
                message.error('Por favor escribe una constraseña')
            }
        }

    }

    const handleCreate = () => {
        if (newName && newUsername && newPassword) {
            onNewUser(operation, newName, newUsername, newPassword)

        } else {
            if (!newName) {
                message.error('Por favor escribe un nombre');
            } else if (!newUsername) {
                message.error('Por favor escribe un correo electrónico');
            } else {
                message.error('Por favor escribe una contraseña');
            }
        }
    }

    useEffect(() => {
        if (response) {
            if (response.data.ok) {
                switch (response.data.msg) {
                    case "User uploaded":
                        message.success('¡Nuevo usuario creado!');
                        setUsername(newUsername);
                        setPassword(newPassword);
                        setNewName(null);
                        setNewUsername(null);
                        setNewPassword(null);
                        setCreateAccount(false)
                        break;

                    case "Valid user":
                        localStorage.setItem("user-email", username)
                        console.log('user: ', response.data.data)
                        setUsername(null)
                        setPassword(null)
                        // setOpenLogin(false)
                        const token = response.data.data.token
                        localStorage.setItem("token", token)
                        localStorage.setItem("token-expires", tokenExpires())
                        navigate("/invitations")

                        // console.log(response)


                        const user = {
                            name: response.data.data.username,
                            uid: response.data.data.uid,
                            role: response.data.data.role,
                            enterprise: response.data.data.enterprise
                        }

                        message.success("Sesión iniciada")
                        login(user)
                        break

                    default:
                        break;
                }


            } else {
                message.error(response.data.msg)
            }

        }
    }, [response])


    return (

        !logged ?
            // !mini ?
            <div className='login-container'>

                {

                    !createAccount ?

                        <>
                            <div className='login-image-container'>
                                <img src={images.login} />
                            </div>



                            <div className='login-form-container'>
                                <Input onChange={(e) => setUsername(e.target.value)} value={username} placeholder='Email' className='gc-input-text login-input' />
                                <div className='password-button-container'>
                                    <Input type={!seePasswrod ? 'password' : ''} onChange={(e) => setPassword(e.target.value)} value={password} placeholder='Contraseña' className='gc-input-text login-input' />
                                    <Button
                                        onClick={() => setSeePasswrod(!seePasswrod)} type='ghost' className='see-password-button'
                                        icon={!seePasswrod ? <RiEyeCloseLine size={18} /> : <RiEye2Line size={18} />} />
                                </div>

                                {/* <a className={'label-forgot-password'}>Olvide mi contraseña</a> */}
                                <Button onClick={handleLogin} id={"gc-cta-secondary-button-login"}>Iniciar Sesión</Button>
                                <span className={'login-label'}>¿No tienes cuenta? <a onClick={() => setCreateAccount(true)} className={'label-forgot-password'}>Crear cuenta</a></span>
                                {/* <span style={{ marginTop: '30px' }} className={'login-label'}>¿Aún no tienes cuenta?</span> */}

                                <Link to={`https://wa.me/6145394836?text=${encodeURIComponent("Hola, estoy interesado en las invitaciones digitales")}`} target='_blank' style={{
                                    margin: '10px 0px', width: '100%', textDecoration: 'none',
                                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}>

                                    <Button
                                        id="whatsapp-button"
                                        style={{ width: 'auto' }}
                                        icon={<FaWhatsapp size={18} />}
                                    >
                                        ¿Tienes alguna duda?
                                    </Button>
                                </Link>

                            </div>
                        </>

                        : <>
                            <>
                                <div className='login-image-container'>
                                    <img src={images.login} />
                                </div>

                                <div className='login-form-container'>
                                    <Input onChange={(e) => setNewName(e.target.value)} value={newName} placeholder='Nombre' className='gc-input-text login-input' />
                                    <Input onChange={(e) => setNewUsername(e.target.value)} value={newUsername} placeholder='Email' className='gc-input-text login-input' />
                                    <div className='password-button-container'>
                                        <Input type={!seePasswrod ? 'password' : ''} onChange={(e) => setNewPassword(e.target.value)} value={newPassword} placeholder='Contraseña' className='gc-input-text login-input' />
                                        <Button
                                            onClick={() => setSeePasswrod(!seePasswrod)} type='ghost' className='see-password-button'
                                            icon={!seePasswrod ? <RiEyeCloseLine size={18} /> : <RiEye2Line size={18} />} />
                                    </div>
                                    <Button onClick={handleCreate} id={"gc-cta-secondary-button-login"} className='mt'>Crear cuenta</Button>
                                    <span className={'login-label'}>¿Ya tienes una cuenta? <a onClick={() => setCreateAccount(false)} className={'label-forgot-password'}>Inicia sesión</a></span>

                                </div>
                            </>
                        </>

                }

                {/* <Button
                        type='ghost'
                        icon={<IoClose size={18} />}
                        onClick={() => setMinim(true)} className='login-state-button' /> */}

            </div>
            // : <Button
            //     icon={<IoMdLogIn size={30} />}
            //     className='login-container-mini'
            //     onClick={() => setMinim(false)} />

            : <></>


    )
}

export const CreateAccount = ({ refreshData, setVisible }) => {

    const { response, loading, error, operation } = useInvitation()
    const navigate = useNavigate();
    const { login, logged } = useContext(appContext)

    const [mini, setMinim] = useState(false)

    const [createAccount, setCreateAccount] = useState(false)
    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState(null)

    const [newName, setNewName] = useState(null)
    const [newUsername, setNewUsername] = useState(null)
    const [newPassword, setNewPassword] = useState(null)

    const [seePasswrod, setSeePasswrod] = useState(false)


    const handleCreate = () => {
        if (newName && newUsername && newPassword) {
            onNewUser(operation, newName, newUsername, newPassword)

        } else {
            if (!newName) {
                message.error('Por favor escribe un nombre');
            } else if (!newUsername) {
                message.error('Por favor escribe un correo electrónico');
            } else {
                message.error('Por favor escribe una contraseña');
            }
        }
    }

    useEffect(() => {
        if (response) {
            if (response.data.ok) {
                switch (response.data.msg) {
                    case "User uploaded":
                        message.success('¡Nuevo usuario creado!');
                        setUsername(newUsername);
                        setPassword(newPassword);
                        setNewName(null);
                        setNewUsername(null);
                        setNewPassword(null);
                        setCreateAccount(false)
                        refreshData()
                        setVisible(false)
                        break;

                    case "Valid user":
                        localStorage.setItem("user-email", username)
                        setUsername(null)
                        setPassword(null)

                        const token = response.data.data.token
                        localStorage.setItem("token", token)
                        localStorage.setItem("token-expires", tokenExpires())
                        navigate("/invitations")

                        const user = {
                            name: response.data.data.username,
                            uid: response.data.data.uid,
                            role: response.data.data.role
                        }

                        message.success("Sesión iniciada")
                        login(user)
                        break

                    default:
                        break;
                }


            } else {
                message.error(response.data.msg)
            }

        }
    }, [response])


    return (

        <div className='login-form-container' style={{ width: '100%' }}>
            <Input onChange={(e) => setNewName(e.target.value)} value={newName} placeholder='Nombre' className='gc-input-text login-input' />
            <Input onChange={(e) => setNewUsername(e.target.value)} value={newUsername} placeholder='Email' className='gc-input-text login-input' />
            <div className='password-button-container'>
                <Input type={!seePasswrod ? 'password' : ''} onChange={(e) => setNewPassword(e.target.value)} value={newPassword} placeholder='Contraseña' className='gc-input-text login-input' />
                <Button
                    onClick={() => setSeePasswrod(!seePasswrod)} type='ghost' className='see-password-button'
                    icon={!seePasswrod ? <RiEyeCloseLine size={18} /> : <RiEye2Line size={18} />} />
            </div>
            <Button onClick={handleCreate} id={"gc-cta-secondary-button-login"} className='mt'>Crear cuenta</Button>
            {/* <span className={'login-label'}>¿Ya tienes una cuenta? <a onClick={() => setCreateAccount(false)} className={'label-forgot-password'}>Inicia sesión</a></span> */}

        </div>


    )
}

