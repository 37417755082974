
import { Button, Layout, Popconfirm } from 'antd';

import {
    BuildCover, BuildGreeting, BuildFamily, BuildQuote, BuildItinerary,
    BuildDressCode, BuildGifts, BuildNotices, BuildGallery, BuildGenerals
} from '../../modules/build';
import { useContext, useEffect, useState } from 'react';
import { appContext } from '../../context';
import '../../pages/BuildInvitation/build-invitation.css'

const { Content } = Layout;



export const SiderBuild = ({ buttons, invitation, setInvitation, currentSection, coverUpdated, setCoverUpdated, invitationID, setSaved, setSettingsModal, settingsModal,
    saved, mode, setMode, onHide
}) => {

    const { onDesign, } = useContext(appContext)


    const handleEditor = (type) => {
        switch (type) {
            case 'generals': return <BuildGenerals onDesign={onDesign} invitation={invitation} setInvitation={setInvitation} setSaved={setSaved} />
            case 'cover': return <BuildCover settingsModal={settingsModal} setSettingsModal={setSettingsModal} onDesign={onDesign} invitationID={invitationID} invitation={invitation} setInvitation={setInvitation} coverUpdated={coverUpdated} setCoverUpdated={setCoverUpdated} setSaved={setSaved} />
            case 'greeting': return <BuildGreeting onDesign={onDesign} invitation={invitation} setInvitation={setInvitation} setSaved={setSaved} />
            case 'family': return <BuildFamily onDesign={onDesign} invitation={invitation} setInvitation={setInvitation} setSaved={setSaved} />
            case 'quote': return <BuildQuote onDesign={onDesign} invitation={invitation} setInvitation={setInvitation} setSaved={setSaved} />
            case 'itinerary': return <BuildItinerary onDesign={onDesign} invitation={invitation} setInvitation={setInvitation} setSaved={setSaved} />
            case 'dresscode': return <BuildDressCode onDesign={onDesign} invitation={invitation} setInvitation={setInvitation} invitationID={invitationID} setSaved={setSaved} />
            case 'gifts': return <BuildGifts onDesign={onDesign} invitation={invitation} setInvitation={setInvitation} setSaved={setSaved} />
            case 'notices': return <BuildNotices onDesign={onDesign} invitation={invitation} setInvitation={setInvitation} setSaved={setSaved} />
            case 'gallery': return <BuildGallery onDesign={onDesign} invitationID={invitationID} invitation={invitation} setInvitation={setInvitation} setSaved={setSaved} />
            default:
                break;
        }
    }


    return (

        <div
            className='build-content-modules-main-container scrollable-div'
            style={{
                width: onHide ? '0px' : '370px',
                padding: onHide ? '0px' : '22px',
                overflow: 'auto',
                zIndex: settingsModal ? 5 : 2,
                maxHeight: '850px'
            }} >
            {!onHide &&
                handleEditor(buttons[currentSection - 1].type)
            }
        </div>

    )
}
