import { Button, Col, ColorPicker, Drawer, Input, Row, Select, Switch, } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import { colorFactoryToHex, darker, lighter, moveDown, moveUp, fonts, LuBadgeHelp, FaArrowsAltV, buttonsColorText, MdOutlineWbSunny, IoMoonOutline, IoColorFillOutline } from '../../helpers'
import { appContext } from '../../context'

import {
    IoMdMoon, IoMdArrowRoundUp, IoMdArrowRoundDown, VscDebugRestart, IoMdColorFill, PiWall, TbClick,
    FaSun, LuSeparatorHorizontal, CgReorder, TbArrowsDownUp, HiSwitchVertical, PiTextT, PiSelectionBackground,
    IoShapesOutline, IoSettingsOutline, MdVerticalAlignTop, MdVerticalAlignBottom
} from "../../helpers";
import { HelpDrawer } from '../../components/build/HelpDrawer';
import { BiMoon } from 'react-icons/bi';


const { Option } = Select;



export const BuildGenerals = ({ invitation, setInvitation, setSaved, onDesign }) => {

    const { setMainColor, setTheme, setFont, MainColor, theme, setColorPalette, colorPalette, font } = useContext(appContext)
    const [currentPosition, setCurrentPosition] = useState(null)
    const [currentItem, setCurrentItem] = useState(null)
    const [orderMode, setOrderMode] = useState(false)
    const [separatorMode, setSeparatorMode] = useState(false)
    const [visible, setVisible] = useState(false)
    const [type, setType] = useState(null)
    const [showInfo, setShowInfo] = useState(false)
    const [currentColor, setCurrentColor] = useState(null)
    const [onPalette, setOnPalette] = useState(false)
    const [location, setLocation] = useState('config')

    const handleClick = (item, index) => {
        setCurrentItem(item)
        setCurrentPosition(index)
    }

    useEffect(() => {
        if (invitation) {
            setMainColor(invitation.generals.color)
            setCurrentColor(invitation.generals.palette.base)
            setColorPalette(invitation.generals.palette)
        }
    }, [invitation])


    const onChangeColor = (e) => {
        setCurrentColor(colorFactoryToHex(e))
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                // color: e ? colorFactoryToHex(e) : prevInvitation.generals.color,
                palette: e ? theme ? {
                    base: colorFactoryToHex(e),
                    primary: lighter(colorFactoryToHex(e), 0.9),
                    secondary: lighter(colorFactoryToHex(e), 0.8),
                    accent: darker(colorFactoryToHex(e), 0.4),
                    buttons: lighter(colorFactoryToHex(e), 0.4)
                } :
                    {
                        base: colorFactoryToHex(e),
                        primary: darker(colorFactoryToHex(e), 0.6),
                        secondary: darker(colorFactoryToHex(e), 0.5),
                        accent: lighter(colorFactoryToHex(e), 0.5),
                        buttons: darker(colorFactoryToHex(e), 0.9)
                    } :
                    prevInvitation.generals.color
            },
        }));
        setSaved(false)
    }

    const onChangeTheme = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                theme: e,
                palette: e ? {
                    base: currentColor,
                    primary: lighter(currentColor, 0.9),
                    secondary: lighter(currentColor, 0.8),
                    accent: darker(currentColor, 0.4),
                    buttons: lighter(currentColor, 0.4)

                } : {
                    base: currentColor,
                    primary: darker(currentColor, 0.6),
                    secondary: darker(currentColor, 0.5),
                    accent: lighter(currentColor, 0.5),
                    buttons: darker(currentColor, 0.9)
                }
            },
        }));
        setSaved(false)
        setTheme(e)
    }

    const onChangePrimary = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                palette: {
                    ...prevInvitation.generals.palette,
                    primary: colorFactoryToHex(e)
                }
            },
        }));
        setSaved(false);
    };

    const onChangeSecondary = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                palette: {
                    ...prevInvitation.generals.palette,
                    secondary: colorFactoryToHex(e)
                }
            },
        }));
        setSaved(false);
    };

    const onChangeAccent = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                palette: {
                    ...prevInvitation.generals.palette,
                    accent: colorFactoryToHex(e)
                }
            },
        }));
        setSaved(false);
    };

    const onChangeButtons = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                palette: {
                    ...prevInvitation.generals.palette,
                    buttons: colorFactoryToHex(e)
                }
            },
        }));
        setSaved(false);
    };

    const handleFont = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                font: e,
            },
        }));
        setSaved(false)
        setFont(e)
    }


    const handleNamePosition = (position) => {
        switch (position) {
            case 1: return 'Saludo'
            case 2: return 'Personas'
            case 3: return 'Cita'
            case 4: return 'Itinerario'
            case 5: return 'Dresscode'
            case 6: return 'Regalos'
            case 7: return 'Avisos'
            case 8: return 'Galería'

            default:
                break;
        }
    }

    const restartPositions = () => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                positions: [1, 2, 3, 4, 5, 6, 7, 8]
            }
        }));
        setCurrentItem(null)
        setCurrentPosition(null)
        setSaved(false)

    }

    const moveUpPosition = (index) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                positions: moveUp(prevInvitation.generals.positions, index)
            }
        }));
        // setCurrentItem(null)
        setSaved(false)
        setCurrentPosition(currentPosition - 1)

    }

    const moveDownPosition = (index) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                positions: moveDown(prevInvitation.generals.positions, index)
            }
        }));
        // setCurrentItem(null)
        setSaved(false)
        setCurrentPosition(currentPosition + 1)
    }

    const switchSettings = (type, value) => {
        if (type === 'order') {
            if (value) {
                setOrderMode(true);
                setSeparatorMode(false);
            } else if (!value && orderMode) {
                setOrderMode(false);
            }
        } else if (type === 'separator') {
            if (value) {
                setSeparatorMode(true);
                setOrderMode(false);
            } else if (!value && separatorMode) {
                setSeparatorMode(false);
            }
        }
    };

    const handleDrawer = (type) => {
        setType(type)
        setVisible(true)
    }

    const handleTitle = (location) => {
        switch (location) {
            case 'config': return 'Configuraciones'
            case 'order': return 'Acomodo'

            default:
                break;
        }
    }



    return (
        <>
            {
                invitation ?
                    <div
                        className='scroll-item generals-main-container' >


                        <div className='build-component-elements'>
                            <span className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                            >Ajustes Generales</span>
                            <span className='gc-content-label'>Tipo de letra</span>

                            <Select

                                value={invitation.generals.font}
                                onChange={(e) => handleFont(e)}
                                id='gc-content-select'
                                style={{ width: '100%', fontFamily: invitation.generals.font, }}>
                                {fonts.map((font, index) => (
                                    <Option style={{ fontFamily: font }} key={index} value={font}>{font}</Option>
                                ))}

                            </Select>
                            <Row style={{
                                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                                marginTop: '10px',
                            }}>
                                <span className='gc-content-label'>Color de la invitación</span>

                            </Row>
                            <div className='general-cards-single-row' style={{ justifyContent: 'space-between', width: '100%' }}>
                                <ColorPicker
                                    disabledAlpha={false}
                                    value={invitation.generals.palette.base}
                                    onChange={!onPalette ? onChangeColor : onChangeSecondary}>
                                    <Button id="pickcolorbutton" icon={<IoColorFillOutline />}
                                    >Selecciona un color</Button>
                                </ColorPicker>
                                <div className='general-cards-single-row'>
                                    {
                                        invitation.generals.theme ? <MdOutlineWbSunny size={16} /> : <IoMoonOutline size={16} />
                                    }
                                    <Switch
                                        size='small'
                                        value={invitation.generals.theme}
                                        onChange={onChangeTheme} />
                                </div>
                            </div>


                            <div className='generl-card-color-item'>
                                <span>Fondo</span>
                                <div className='general-cards-single-row'>
                                    <ColorPicker
                                        disabledAlpha={false}
                                        value={invitation.generals.palette.primary}
                                        style={{ width: '80px' }}
                                        onChange={onChangePrimary}>
                                    </ColorPicker>
                                </div>

                            </div>
                            <div className='generl-card-color-item'>
                                <span>Contrastes</span>
                                <div className='general-cards-single-row'>
                                    <ColorPicker
                                        disabledAlpha={false}
                                        value={invitation.generals.palette.secondary}
                                        style={{ width: '80px' }}
                                        onChange={onChangeSecondary}>
                                    </ColorPicker>
                                </div>

                            </div>
                            <div className='generl-card-color-item'>
                                <span>Textos</span>
                                <div className='general-cards-single-row'>
                                    <ColorPicker
                                        disabledAlpha={false}
                                        value={invitation.generals.palette.accent}
                                        style={{ width: '80px' }}
                                        onChange={onChangeAccent}>
                                    </ColorPicker>
                                </div>

                            </div>
                            <div className='generl-card-color-item'>
                                <span>Botones</span>
                                <div className='general-cards-single-row'>
                                    <ColorPicker
                                        disabledAlpha={false}
                                        value={invitation.generals.palette.buttons}
                                        style={{ width: '80px' }}
                                        onChange={onChangeButtons}>
                                    </ColorPicker>
                                </div>

                            </div>

                        </div>



                        <div className='build-component-elements'>
                            <span className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                            >Orden de la invitación</span>

                            <Row className='gc-cta-buttons-container edit-position-controller' style={{
                                justifyContent: 'space-between', width: '100%', marginBottom: '8px'
                            }}>
                                <Button
                                    id='gc-cta-secondary-button'
                                    onClick={restartPositions}
                                    icon={<VscDebugRestart />}>
                                    Reiniciar
                                </Button>
                                <div className='general-cards-single-row'>

                                    <Button
                                        onClick={() => moveUpPosition(currentPosition)}
                                        icon={<IoMdArrowRoundUp />}
                                        id='gc-cta-secondary-button'
                                    // style={{ margin: '0px 5px' }}
                                    >Subir</Button>

                                    <Button
                                        onClick={() => moveDownPosition(currentPosition)}
                                        icon={<IoMdArrowRoundDown />}
                                        id='gc-cta-secondary-button' />
                                </div>



                            </Row>

                            <div className='build-generals-simple-column'>
                                {
                                    invitation.generals.positions.map((item, index) => (
                                        <div
                                            key={index}
                                            onClick={() => handleClick(item, index)}
                                            className={`gc-order-item${item === currentItem ? '--selected' : ''}`}>{handleNamePosition(item)}</div>
                                    ))
                                }
                            </div>



                        </div>



                    </div >
                    : <></>
            }

            <HelpDrawer visible={visible} setVisible={setVisible} type={type} setType={setType} />
        </>
    )
}


{/* <Col style={{
                                width: 'auto', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
                                flexDirection: 'column', marginRight: '15px'
                            }}>

                                <span style={{
                                    width: 'auto', textAlign: 'left', fontFamily: 'Roboto', fontSize: '1em',
                                    marginBottom: '5px'
                                }}>Tipo de letra</span>
                                <Select

                                    value={invitation.generals.font}
                                    onChange={(e) => handleFont(e)}
                                    style={{ width: '300px', fontFamily: invitation.generals.font, }}>
                                    {fonts.map((font, index) => (
                                        <Option style={{ fontFamily: font }} key={index} value={font}>{font}</Option>
                                    ))}

                                </Select>

                            </Col>



                            <Col style={{
                                width: 'auto', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
                                flexDirection: 'column',
                                // marginTop: '15px'
                            }}>

                                <span style={{
                                    width: 'auto', textAlign: 'left', fontFamily: 'Roboto', fontSize: '1em',
                                    marginBottom: '5px'
                                }}>Color de la invitación</span>

                                <ColorPicker
                                    disabledAlpha={false}
                                    value={invitation.generals.color}
                                    onChange={onChangeColor}
                                    style={{
                                        width: '35px',
                                        height: '35px', borderRadius: '50px',
                                        backgroundColor: invitation.generals.color
                                    }} />

                            </Col>

                            <Col style={{
                                width: 'auto', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
                                flexDirection: 'column', margin: '0px 20px'
                            }}>
                                <span style={{
                                    width: 'auto', textAlign: 'left', fontFamily: 'Roboto', fontSize: '1em',
                                    marginBottom: '5px'
                                }}>Tema de la invitación</span>


                                <Button
                                    onClick={() => onChangeTheme(!invitation.generals.theme)}
                                    icon={invitation.generals.theme ? <FiSun size={15} style={{
                                        color: darker(MainColor, 0.4)
                                    }} /> : <IoMdMoon size={15} style={{
                                        color: lighter(MainColor, 0.5)
                                    }} />}
                                    style={{
                                        borderRadius: '50%',
                                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                                        backgroundColor: invitation.generals.theme ? lighter(MainColor, 0.9) : `${darker(MainColor, 0.6)}`,

                                    }} />

                            </Col>

                            <Col style={{
                            width: '30%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
                            flexDirection: 'column', marginTop: '15px'
                        }}>

                            <span style={{
                                width: 'auto', textAlign: 'left', fontFamily: 'Roboto', fontSize: '1em',
                                marginBottom: '5px'
                            }}>URL de la invitación</span>

                            <Input
                                value={invitation.generals.eventName}
                                placeholder={''}
                                style={{
                                    width: '100%',
                                    borderRadius: '15px',
                                }} />

                        </Col>

                        <Col style={{
                            width: '30%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
                            flexDirection: 'column', marginTop: '15px'
                        }}>

                            <span style={{
                                width: 'auto', textAlign: 'left', fontFamily: 'Roboto', fontSize: '1em',
                                marginBottom: '5px'
                            }}>Templates</span>

                            <span style={{
                                width: 'auto', textAlign: 'left', fontFamily: 'Roboto', fontSize: '1em',
                                marginBottom: '5px'
                            }}>Separadores</span>

                            <span style={{
                                width: 'auto', textAlign: 'left', fontFamily: 'Roboto', fontSize: '1em',
                                marginBottom: '5px'
                            }}>Orden</span>



                        </Col> */}
