
import { Button, ColorPicker, Empty, Input, Layout, Popconfirm, Row, Upload, message } from "antd"
import { useContext, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { appContext } from "../../context"
import { ManualLogo } from "../../components/Logo"
import {
    IoPricetagsOutline, IoArrowUndo, FaArrowUp, IoMdRefresh, BsClipboard, FaEnvelopeOpenText, FaRegEnvelope,
    RiSearchLine, HiOutlineEnvelope, BsClipboardFill, IoPricetags, HiEnvelopeOpen, IoIosHelpCircle, RiLoginCircleFill, PiLinkBold, FaWhatsapp, MdOutlineAdminPanelSettings, MdAdminPanelSettings, RiUserStarLine, RiUserStarFill, IoMdAdd, generateImagesName, IoClose, FaRegTrashCan, darker, lighter, formatDate, GoShare, FiShare2, FaPencilRuler, PiPencilCircle, IoPeopleCircleOutline, HiOutlineEllipsisHorizontalCircle, HiOutlineUserCircle, CiMenuBurger, IoMenu, RiUserAddLine, HiOutlineMenu
} from '../../helpers'

import { storage } from '../../firebase';
import { ref, deleteObject, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { Footer } from "antd/es/layout/layout"
import { editUser } from "../../services/apiLogin"
import { useInvitation } from "../../hooks"
import { QRCodeCanvas } from "qrcode.react"
import { toPng } from "html-to-image"
import html2canvas from "html2canvas"

const { Header } = Layout
const baseProd = "https://www.iattend.mx"


export const HeaderBuild = ({ position, setOpenLogin, isVisible }) => {

    const { logged, logout, user, login } = useContext(appContext)
    const { response, loading, error, operation } = useInvitation()
    const [isScrollTop, setIsScrollTop] = useState(false);
    const [role, setRole] = useState('Owner')
    const [superUser, setSuperUser] = useState(false)
    const [enterpriseName, setEnterpriseName] = useState(null)
    const [enterpriseLogo, setEnterpriseLogo] = useState(null)
    const [openMenu, setOpenMenu] = useState(false)


    const getFirstLetterUpperCase = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase();
    }

    useEffect(() => {
        if (position === 'land-page') {
            const handleScroll = () => {
                if (window.scrollY >= 0 && window.scrollY <= 100) {
                    setIsScrollTop(false);
                } else {
                    setIsScrollTop(false);
                }
            };

            window.addEventListener('scroll', handleScroll);

            // Limpiar el event listener al desmontar el componente
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }

        if (user) {
            setRole(user.role)
            if (user.enterprise) {
                setEnterpriseName(user.enterprise.name)
                setEnterpriseLogo(user.enterprise.logo)
            }

            // console.log('user: ', user)
        }

    }, []);


    const navItems = [
        // {
        //     name: 'Explora',
        //     icon: RiSearchLine,
        //     selected: RiSearchFill,
        //     path: "/land-page",
        //     position: "land-page"
        // },
        {
            name: 'Explora',
            icon: HiOutlineEnvelope,
            selected: HiEnvelopeOpen,
            path: "/discover",
            position: "discover"
        },
        {
            name: 'Tablero',
            icon: BsClipboard,
            selected: BsClipboardFill,
            path: "/invitations",
            position: "invitations"
        },
        {
            name: 'Precios',
            icon: IoPricetagsOutline,
            selected: IoPricetags,
            path: "/pricing",
            position: "pricing"
        },
        {
            name: 'Empresa',
            icon: RiUserStarLine,
            selected: RiUserStarFill,
            path: "",
            position: "distributor"
        },
        {
            name: 'Admin',
            icon: MdOutlineAdminPanelSettings,
            selected: MdAdminPanelSettings,
            path: "/admin",
            position: "admin"
        },
        // {
        //     name: 'Ayuda',
        //     icon: IoIosHelpCircleOutline,
        //     selected: IoIosHelpCircle,
        //     path: "/contact-us",
        //     position: "help"
        // },

    ]

    const handleURL = (downloadURL) => {
        //Edit user and add url
        editUser(operation, user.uid, enterpriseName, downloadURL)
        setEnterpriseLogo(downloadURL)
        const newUser = {
            name: user.name,
            uid: user.uid,
            role: user.role,
            enterprise: {
                name: enterpriseName,
                logo: downloadURL,
                discount: user.enterprise.discount,
                color: user.enterprise.discount
            }
        }
        login(newUser)
    };

    const saveAndClose = (downloadURL) => {
        //Edit user and add url
        editUser(operation, user.uid, enterpriseName, enterpriseLogo)
        const newUser = {
            name: user.name,
            uid: user.uid,
            role: user.role,
            enterprise: {
                name: enterpriseName,
                logo: enterpriseLogo,
                discount: user.enterprise.discount,
                color: user.enterprise.discount
            }
        }
        login(newUser)
        setSuperUser(false)
    };

    const handleCustomRequest = ({ file }) => {
        if (!file) return;

        // Verificar si el archivo es menor a 3MB
        const isLt3M = file.size / 1024 / 1024 < 5;
        if (!isLt3M) {
            message.error('El archivo debe ser menor a 5MB.');
            return; // Salir de la función si el archivo es mayor a 3MB
        }

        const storageRef = ref(storage, `superUsers/${user.uid}/${generateImagesName()}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            snapshot => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                // setProgress(progress);
            },
            error => {
                console.error('Upload error: ', error);
            },

            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                    handleURL(downloadURL)
                });
            }
        );


    };

    const removeImageDB = () => {
        //Edit user and remove logo
        editUser(operation, user.uid, enterpriseName, null)
        setEnterpriseLogo(null)
        const newUser = {
            name: user.name,
            uid: user.uid,
            role: user.role,
            enterprise: {
                name: enterpriseName,
                logo: null,
                discount: user.enterprise.discount,
                color: user.enterprise.discount
            }
        }
        // deleteImageFB(url)
        login(newUser)
    };

    const deleteImageFB = async (url) => {
        const filePath = extractFilePathFromURL(url)
        const imageRef = ref(storage, filePath);

        try {
            await deleteObject(imageRef);
            removeImageDB()
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const extractFilePathFromURL = (url) => {
        const regex = /https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/([^/]+)\/o\/(.+)\?alt=media&token=.+/;
        const match = url.match(regex);
        if (!match) {
            throw new Error('URL does not match Firebase Storage base URL.');
        }
        const filePath = decodeURIComponent(match[2]);
        return filePath;
    };


    return (
        <>
            <div className="header-main-container web-opt">
                <Row className="header-container" >
                    <ManualLogo date={new Date()} />
                    <Row style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                        flexDirection: 'row', position: 'relative'
                    }}>

                        <Row style={{
                            width: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                            flexDirection: 'row', gap: '12px'
                        }}>
                            {
                                navItems.map((item, index) => {
                                    // Condiciones para ocultar el elemento 'Admin'
                                    if (item.name === 'Admin' && (!logged || role !== 'Admin')) {
                                        return null;  // No renderiza nada
                                    }

                                    if (item.name === 'Empresa' && (!logged || role === 'Owner')) {
                                        return null;  // No renderiza nada
                                    }

                                    return (
                                        <Link to={item.path} key={item.name}>
                                            <div
                                                onClick={item.name === 'Empresa' ? () => setSuperUser(true) : null}
                                                className={`nav-item-col ${item.position === position ? '--selected' : ''}`}>
                                                {
                                                    item.position === position
                                                        ? <item.selected className="nav-item-icon" />
                                                        : <item.icon className="nav-item-icon" />
                                                }
                                                <span className="nav-item-label">{item.name}</span>
                                            </div>
                                        </Link>
                                    );
                                })
                            }

                            <Link to="https://wa.me/6145394836" target='_blank' style={{
                            }}>
                                <Button
                                    id='contact-us'
                                // icon={<FaWhatsapp size={18} />}
                                >
                                    {logged ? '¿Necesitas ayuda?' : 'CONTÁCTANOS'}
                                </Button>
                            </Link>

                            {
                                logged ?
                                    <Button
                                        onClick={logout}
                                        id={role === 'Admin' ? 'on-logged-admin' : "on-logged-circle"} >
                                        {
                                            user ? getFirstLetterUpperCase(user.name)
                                                : 'A'

                                        }
                                    </Button>

                                    : <Link to="/login">
                                        <Button
                                            // onClick={() => setOpenLogin(true)} 
                                            id="iniciar-sesion-button">
                                            COMIENZA A CREAR
                                        </Button>
                                    </Link>
                            }
                        </Row>


                        <div
                            className="distributor-card"
                            style={{
                                display: superUser ? 'flex' : 'none',
                                gap: '12px'
                            }}>


                            <Row style={{
                                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                                marginBottom: '10px', paddingBottom: '20px', borderBottom: '1px solid #d9d9d980'
                            }}>
                                <span className='distributor-card-title'>Perfil de empresa</span>
                                {/* <Button
                                    // onClick={() => editState(inv._id)}
                                    id='save-tickets-button'
                                >Guardar</Button> */}
                                <Button
                                    type='ghost'
                                    id="gc-cta-buttons-static"
                                    onClick={saveAndClose}
                                    // onClick={onTry ? () => message.warning('No puedes eliminar imágenes en simulador') : () => deleteImageFB(photo)}
                                    icon={<IoClose size={20} />}
                                // style={{ position: 'absolute', top: '5px', right: '5px', }}
                                />
                            </Row>



                            <div className="dist-img-btn-cnt">
                                <div className="dist-card-col-label">
                                    <span className='gc-content-label'>Nombre</span>

                                    <Input
                                        placeholder={'Nombre'}
                                        value={enterpriseName}
                                        onChange={(e) => setEnterpriseName(e.target.value)}
                                        className='gc-input-text' />
                                </div>

                                <div className="dist-card-col-label">
                                    <span className='gc-content-label'>Descuento</span>
                                    <span className='gc-content-label'>30%</span>

                                </div>

                            </div>





                            <span className='gc-content-label'>Logo</span>

                            <div className="dist-img-btn-cnt">
                                <div className="distributor-card-image-container" style={{ position: 'relative' }}>
                                    {
                                        enterpriseLogo ?

                                            <><img src={enterpriseLogo} style={{
                                                width: '100%', height: '100%', objectFit: 'cover'
                                            }} />

                                            </>


                                            : <Empty description={false} />
                                    }

                                </div>
                                <div className="upload-btn-ctn">

                                    {
                                        enterpriseLogo ?
                                            <Button
                                                // type='ghost'
                                                id="gc-cta-buttons-static"
                                                onClick={() => deleteImageFB(enterpriseLogo)}
                                                style={{ border: '1px solid #d9d9d9' }}
                                                // onClick={onTry ? () => message.warning('No puedes eliminar imágenes en simulador') : () => deleteImageFB(photo)}
                                                icon={<FaRegTrashCan size={20} />}
                                            // style={{ position: 'absolute', top: '5px', right: '5px', }}
                                            />
                                            :
                                            <Upload

                                                onChange={handleCustomRequest}
                                                showUploadList={false} // Oculta la lista de archivos subidos
                                                beforeUpload={() => false} // Evita la carga automática de archivos

                                            // style={{ width: '200px' }}
                                            >

                                                <Button
                                                    style={{ border: '1px solid #d9d9d9' }}
                                                    id="gc-cta-buttons-static" icon={<IoMdAdd size={20} />}></Button>

                                            </Upload>
                                    }


                                </div>

                            </div>



                        </div>

                    </Row>




                </Row>

            </div >


            <div style={{
                opacity: isVisible ? 1 : 0
            }} className="header-main-container mobile-opt"
            >
                <div
                    style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                        width: '90%', position: 'relative',
                    }}>

                    <ManualLogo />

                    <Button onClick={() => setOpenMenu(true)} type="ghost" icon={<HiOutlineMenu size={36} />} />

                    {/* {
                        logged ?
                            <Button
                                onClick={logout}
                                id="on-logged-circle"
                            >
                                {
                                    user ? getFirstLetterUpperCase(user.name)
                                        : 'A'

                                }
                            </Button>

                            :
                            <Link to="/login">
                                <Button
                                    // onClick={() => setOpenLogin(true)} 
                                    id="iniciar-sesion-button">
                                    COMIENZA A CREAR
                                </Button>
                            </Link>

                    } */}
                </div>

                <div style={{
                    right: !openMenu ? '-100vw' : '0px'
                }} className="mobile-menu-container">
                    <div className="header-main-container">
                        <div
                            style={{
                                display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row',
                                width: '90%', position: 'relative',
                            }}>
                            {/* <span className="mobile-menu-label">Menú</span> */}
                            <Button onClick={() => setOpenMenu(false)} type="ghost" icon={<IoClose size={36} />} />
                        </div>
                    </div>

                    <div className="mobile-menu-routes">
                        {
                            navItems.map((item, index) => {
                                // Condiciones para ocultar el elemento 'Admin'
                                if (item.name === 'Admin' && (!logged || role !== 'Admin')) {
                                    return null;  // No renderiza nada
                                }

                                if (item.name === 'Empresa' && (!logged || role === 'Owner')) {
                                    return null;  // No renderiza nada
                                }

                                if (item.name === 'Tablero') {
                                    return null;  // No renderiza nada
                                }

                                return (
                                    <Link
                                        style={{ textDecoration: 'none' }}
                                        to={item.path} key={item.name}>
                                        <span style={{
                                            color: item.position === position && 'var(--accent-color)'
                                        }} className="mobile-nav-item">{item.name}</span>
                                    </Link>
                                );
                            })
                        }

                        <Link style={{ textDecoration: 'none' }} to="https://wa.me/6145394836">
                            <span className="mobile-nav-item">Contacto</span>
                        </Link>

                    </div>

                </div>

            </div >
        </>
    )
}

export const FooterMobile = ({ position, setOpenLogin, isVisible }) => {

    const { logged, logout, user, } = useContext(appContext)
    const [isScrollTop, setIsScrollTop] = useState(false);

    const getFirstLetterUpperCase = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase();
    }

    useEffect(() => {
        if (position === 'land-page') {
            const handleScroll = () => {
                if (window.scrollY >= 0 && window.scrollY <= 100) {
                    setIsScrollTop(false);
                } else {
                    setIsScrollTop(false);
                }
            };

            window.addEventListener('scroll', handleScroll);

            // Limpiar el event listener al desmontar el componente
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }

    }, []);


    const navItems = [
        // {
        //     name: 'Explora',
        //     icon: RiSearchLine,
        //     selected: RiSearchFill,
        //     path: "/land-page",
        //     position: "land-page"
        // },
        {
            name: 'Conoce',
            icon: HiOutlineEnvelope,
            selected: HiEnvelopeOpen,
            path: "/discover",
            position: "discover"
        },
        {
            name: 'Tablero',
            icon: BsClipboard,
            selected: BsClipboardFill,
            path: "/invitations",
            position: "invitations"
        },
        {
            name: 'Precios',
            icon: IoPricetagsOutline,
            selected: IoPricetags,
            path: "/pricing",
            position: "pricing"
        },
        {
            name: 'Contacto',
            icon: FaWhatsapp,
            selected: FaWhatsapp,
            path: 'https://wa.me/6145394836',
            position: 'contact'
        }
        // {
        //     name: 'Ayuda',
        //     icon: IoIosHelpCircleOutline,
        //     selected: IoIosHelpCircle,
        //     path: "/contact-us",
        //     position: "help"
        // },

    ]


    return (
        <Footer className="footer-main-container" style={{
            opacity: isVisible ? 1 : 0,
            transition: 'all 0.5s ease'
        }}>


            <Row style={{
                width: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center',
                flexDirection: 'row', width: '100%', gap: '10px'
            }}>
                {
                    navItems.map((item, index) => (
                        <Link key={index} to={item.path} >
                            <div
                                className={`nav-item-col ${item.position === position ? '--selected' : ''}`}>
                                {
                                    item.position === position ? <item.selected className="nav-item-icon" />
                                        : <item.icon className="nav-item-icon" />
                                }
                                <p className="nav-item-label">{item.name}</p>
                            </div>
                        </Link>

                    ))
                }
            </Row>

        </Footer >
    )
}

export const HeaderDev = ({ shared, invitation, setMode, mode, saved, onSaveChanges, setOnQR }) => {

    const { user, } = useContext(appContext)
    // const [onQR, setOnQR] = useState(false)
    const [content, setContent] = useState(null)
    const [colorPalette, setColorPalette] = useState(null)
    const [activeComponent, setActiveComponent] = useState(false)




    const moodeAndDelete = async (type) => {
        setMode(type)

        await deleteIfDifferent(invitation.cover?.featured_prod, invitation.cover?.featured_dev, deleteImageFB);

        if (invitation.gallery?.gallery_dev) {
            await Promise.all(invitation.gallery.gallery_dev.map(dev =>
                deleteIfDifferent(invitation.gallery.gallery_prod?.includes(dev) ? dev : null, dev, deleteImageFB)
            ));
        }

        if (invitation.dresscode?.images_dev) {
            await Promise.all(invitation.dresscode.images_dev.map(dev =>
                deleteIfDifferent(invitation.dresscode.images_prod?.includes(dev) ? dev : null, dev, deleteImageFB)
            ));
        }

    }

    const deleteIfDifferent = async (prod, dev, deleteFn) => {
        if (dev && dev !== prod) {
            await deleteFn(dev);
        }
    };

    const deleteImageFB = async (url) => {
        const filePath = extractFilePathFromURL(url)
        // console.log(filePath)

        const imageRef = ref(storage, filePath);

        try {
            await deleteObject(imageRef);
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const extractFilePathFromURL = (url) => {
        const regex = /https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/([^/]+)\/o\/(.+)\?alt=media&token=.+/;
        const match = url.match(regex);
        if (!match) {
            throw new Error('URL does not match Firebase Storage base URL.');
        }
        const filePath = decodeURIComponent(match[2]);
        return filePath;
    };



    return (
        <div className={"header-main-container"}>

            <Row className="header-container" >

                {
                    shared ? user.enterprise.logo ?
                        <div style={{
                            overFlow: 'hidden', maxHeight: '65px', maxWidth: '200px',
                            display: 'flex', alignItems: 'center', justifyContent: 'flex-start'
                        }}>
                            <img src={user.enterprise.logo} style={{
                                maxHeight: '65px', maxWidth: '200px',
                                // border: '1px solid red'
                            }} />
                        </div> :
                        shared && user.enterprise.name ?
                            <span className='i-attend-text-logo'>{user.enterprise.name}</span>
                            : <ManualLogo date={new Date()} />
                        : <></>

                }

                <Row style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row'
                }}>




                    {
                        !shared ? !saved
                            ?
                            <Popconfirm
                                title="Salir sin Guardar"
                                description="Tienes cambios sin guardar. Si sales ahora, todos los cambios se perderán. ¿Estás seguro de que deseas continuar?"
                                onConfirm={() => moodeAndDelete('my-invitations')}
                                placement="bottomLeft"
                                okText="Salir"
                                cancelText="Cancelar"
                                style={{ width: '400px' }}
                                id="popup-confirm"
                            >
                                <div style={{
                                    display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '12px'
                                }}>
                                    <Button

                                        id='gc-cta-buttons'
                                        type='ghost'
                                        icon={<IoArrowUndo size={25} />} />
                                    <ManualLogo date={new Date()} />
                                </div>
                            </Popconfirm>
                            :
                            <div style={{
                                display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '12px'
                            }}>
                                <Button
                                    onClick={() => setMode('my-invitations')}
                                    id='gc-cta-buttons'
                                    type='ghost'
                                    icon={<IoArrowUndo size={25} />} />
                                <ManualLogo date={new Date()} />
                            </div>
                            : <></>
                    }


                    <div

                        onMouseEnter={() => setActiveComponent(true)} onMouseLeave={() => setActiveComponent(false)}
                        style={{

                            position: 'absolute', bottom: 'calc(-100vh + 100px)', right: '30px',
                            display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', gap: '8px', flexDirection: 'column',
                        }}>
                        {
                            activeComponent &&
                            <div className="nav-items-container">

                                <div onClick={() => setMode('on-edit')} className={`nav-items-item ${mode === 'on-edit' ? 'nav-items-item-active' : ''}`}>
                                    <PiPencilCircle />
                                    <span>Diseño</span>

                                </div>



                                {
                                    !saved ?
                                        <Popconfirm
                                            title="Salir sin Guardar"
                                            description="Tienes cambios sin guardar. Si sales ahora, todos los cambios se perderán. ¿Estás seguro de que deseas continuar?"
                                            onConfirm={() => moodeAndDelete('on-guests')}
                                            placement="bottomLeft"
                                            okText="Salir"
                                            cancelText="Cancelar"
                                            style={{ width: '400px' }}
                                            id="popup-confirm"
                                        >
                                            <div className={`nav-items-item ${mode === 'on-guests' ? 'nav-items-item-active' : ''}`}>
                                                <HiOutlineUserCircle />
                                                <span>Invitados</span>

                                            </div>
                                        </Popconfirm>
                                        :
                                        <div onClick={() => setMode('on-guests')} className={`nav-items-item ${mode === 'on-guests' ? 'nav-items-item-active' : ''}`}>
                                            <HiOutlineUserCircle />
                                            <span>Invitados</span>

                                        </div>
                                }

                                {
                                    user.role === 'Admin' &&
                                    <div onClick={() => setMode('on-order')} className="nav-items-item">
                                        <HiOutlineEllipsisHorizontalCircle />
                                        <span>Acomodo</span>

                                    </div>
                                }



                            </div>
                        }

                        <div className="header-main-menu-button">
                            <IoMenu size={32} />

                        </div>

                    </div>


                </Row>


                <div style={{
                    width: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row',
                    gap: '12px'

                }}>
                    {/* <div>
                        <Button
                            id={'refresh-changes-button'}
                            icon={<RiUserAddLine size={18} />}
                            onClick={() => setOnQR(true)}

                        >
                            Compartir Edición
                        </Button>

                    </div> */}
                    <div>
                        <Button
                            id={'refresh-changes-button'}
                            icon={<FiShare2 size={18} />}
                            onClick={() => setOnQR(true)}

                        >
                            Compartir Invitación
                        </Button>

                    </div>

                    <Link to="https://wa.me/6145394836" target='_blank' style={{
                    }}>
                        <Button
                            id='refresh-changes-button'
                            icon={<FaWhatsapp size={18} />}
                        >
                            ¿Necesitas ayuda?
                        </Button>
                    </Link>

                    <Button
                        onClick={onSaveChanges}
                        id='save-changes-button'
                        icon={<FaArrowUp size={15} />}
                        style={{ position: 'relative', }}>

                        Publicar cambios

                        {
                            !saved && (
                                <div style={{
                                    position: 'absolute', top: 0, right: -4,
                                    height: '15px', width: '15px', borderRadius: '50px',
                                    backgroundColor: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }} >
                                    <div style={{

                                        height: '10px', width: '10px', borderRadius: '50px',
                                        backgroundColor: '#FF0033'
                                    }}>

                                    </div>
                                </div>
                            )
                        }

                    </Button>


                </div>


            </Row>

        </div >
    )
}

export const HeaderGuests = ({ mode, shared, setMode, refreshPage, setOnQR }) => {

    const { user, } = useContext(appContext)
    const [activeComponent, setActiveComponent] = useState(false)

    return (
        <div className={"header-main-container"}>

            <Row className="header-container" >

                {
                    shared ? user.enterprise.logo ?
                        <div style={{
                            overflow: 'hidden', maxHeight: '65px', maxWidth: '200px',
                            display: 'flex', alignItems: 'center', justifyContent: 'flex-start'
                        }}>
                            <img src={user.enterprise.logo} style={{
                                maxHeight: '65px', maxWidth: '200px',
                            }} />
                        </div> :
                        shared && user.enterprise.name ?
                            <span className='i-attend-text-logo'>{user.enterprise.name}</span>
                            : <ManualLogo date={new Date()} />
                        : <></>

                }


                <Row style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row'
                }}>



                    {
                        !shared &&
                        <div style={{
                            display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '12px'
                        }}>
                            <Button
                                onClick={() => setMode('my-invitations')}
                                id='gc-cta-buttons'
                                type='ghost'
                                icon={<IoArrowUndo size={25} />} />
                            <ManualLogo date={new Date()} />
                        </div>
                    }







                    <div

                        onMouseEnter={() => setActiveComponent(true)} onMouseLeave={() => setActiveComponent(false)}
                        style={{

                            position: 'absolute', bottom: 'calc(-100vh + 100px)', right: '30px',
                            display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', gap: '8px', flexDirection: 'column',
                        }}>
                        {
                            activeComponent &&
                            <div className="nav-items-container">

                                <div onClick={() => setMode('on-edit')} className={`nav-items-item`}>
                                    <PiPencilCircle />
                                    <span>Diseño</span>

                                </div>

                                <div onClick={() => setMode('on-guests')} className={`nav-items-item ${mode === 'on-guests' && 'nav-items-item-active'}`}>
                                    <HiOutlineUserCircle />
                                    <span>Invitados</span>

                                </div>


                                {
                                    user.role === 'Admin' &&
                                    <div onClick={() => setMode('on-order')} className={`nav-items-item ${mode === 'on-order' && 'nav-items-item-active'}`}>
                                        <HiOutlineEllipsisHorizontalCircle />
                                        <span>Acomodo</span>

                                    </div>
                                }


                            </div>
                        }

                        <div className="header-main-menu-button">
                            <IoMenu size={32} />

                        </div>

                    </div>
                </Row>


                <div>
                    <Button
                        id={'refresh-changes-button'}
                        icon={<FiShare2 size={18} />}
                        // style={{
                        //     backgroundColor: onQR && colorPalette && colorPalette.buttons
                        // }}
                        onClick={() => setOnQR(true)}
                    // onClick={() => copyToClipboard(`${baseProd}/${invitation.label}/${invitation.generals.eventName}`)}
                    >
                        Compartir Invitación
                    </Button>

                    <Link to="https://wa.me/6145394836" target='_blank' style={{
                        margin: '0px 10px'
                    }}>
                        <Button
                            id='refresh-changes-button'
                            icon={<FaWhatsapp size={18} />}
                        >
                            ¿Necesitas ayuda?
                        </Button>
                    </Link>

                    <Button
                        onClick={refreshPage}
                        id='refresh-changes-button-secondary'
                        icon={<IoMdRefresh size={20} />}
                        style={{ position: 'relative', }}>

                        Actualizar datos

                    </Button>

                </div>















            </Row>

        </div >
    )
}

