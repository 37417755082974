import React, { useContext, useRef, useState, useEffect } from 'react'
import { Layout } from 'antd';
import {
    CoverAppDev, Family, Quote, Itinerary, DressCode, TableGifts,
    Notices, Gallery, GreetingDev, FamilyDev, Greeting, CoverApp
} from '../modules/invitation';

import { darker, lighter, load } from '../helpers';
import { appContext } from '../context';





export const InvitationTest = ({ setCurrentOffsetTop, positionY, invitation, size, land }) => {

    const { MainColor, theme, colorPalette } = useContext(appContext)
    const [loader, setLoader] = useState(false)

    const coverRef = useRef(null)
    const greetingRef = useRef(null)
    const familyRef = useRef(null)
    const quoteRef = useRef(null)
    const itineraryRef = useRef(null)
    const dresscodeRef = useRef(null)
    const giftsRef = useRef(null)
    const noticesRef = useRef(null)
    const galleryRef = useRef(null)

    const handlePosition = (id, index) => {
        switch (id) {
            case 1: return <Greeting key={index} id="greeting" greetingRef={greetingRef} content={invitation.greeting} dev={true} />
            case 2: return <Family key={index} id="family" familyRef={familyRef} content={invitation.family} dev={true} />
            case 3: return <Quote key={index} id="quote" quoteRef={quoteRef} content={invitation.quote} dev={true} />
            case 4: return <Itinerary key={index} id="itinerary" itineraryRef={itineraryRef} content={invitation.itinerary} dev={true} />
            case 5: return <DressCode key={index} id="dresscode" dresscodeRef={dresscodeRef} content={invitation.dresscode} dev={true} land={land} />
            case 6: return <TableGifts key={index} id="gifts" giftsRef={giftsRef} content={invitation.gifts} dev={true} land={land} />
            case 7: return <Notices key={index} id="notices" noticesRef={noticesRef} content={invitation.notices} dev={true} />
            case 8: return <Gallery key={index} id="gallery" galleryRef={galleryRef} content={invitation.gallery} dev={true} land={land} />

            default:
                break;
        }
    }


    useEffect(() => {
        if (positionY) {
            switch (positionY) {
                case 'cover':
                    setCurrentOffsetTop(coverRef.current.offsetTop)
                    break;

                case 'greeting':
                    if (invitation.greeting.active) {
                        setCurrentOffsetTop(greetingRef.current.offsetTop)
                    }
                    break;

                case 'family':
                    if (invitation.family.active) {
                        setCurrentOffsetTop(familyRef.current.offsetTop)
                    }
                    break;

                case 'quote':
                    if (invitation.quote.active) {
                        setCurrentOffsetTop(quoteRef.current.offsetTop)
                    }
                    break;

                case 'itinerary':
                    if (invitation.itinerary.active) {
                        setCurrentOffsetTop(itineraryRef.current.offsetTop)
                    }
                    break;

                case 'dresscode':
                    if (invitation.dresscode.active) {
                        setCurrentOffsetTop(dresscodeRef.current.offsetTop)
                    }
                    break;

                case 'gifts':
                    if (invitation.gifts.active) {
                        setCurrentOffsetTop(giftsRef.current.offsetTop)
                    }
                    break;

                case 'notices':
                    if (invitation.notices.active) {
                        setCurrentOffsetTop(noticesRef.current.offsetTop)
                    }
                    break;

                case 'gallery':
                    if (invitation.gallery.active) {
                        setCurrentOffsetTop(galleryRef.current.offsetTop)
                    }
                    break;


                default:
                    break;
            }
        }

    }, [positionY])



    return (

        <>
            {
                !loader && invitation ?
                    <Layout style={{
                        display: 'flex', transition: 'all 0.3s ease',
                    }}>

                        <div
                            style={{
                                marginTop: '0vh',
                                display: 'flex',
                                alignItems: 'center', justifyContent: 'flex-start',
                                flexDirection: 'column',
                                backgroundColor: colorPalette ? colorPalette.primary : '#F5F5F7',
                                position: 'relative',
                                gap: '52px',
                                paddingBottom: '36px'

                            }}
                        >

                            <CoverApp coverRef={coverRef} content={invitation.cover} inv={invitation} dev={true} height={size.height} land={land} />

                            {
                                invitation.generals.positions.map((position, index) => (
                                    handlePosition(position, index)
                                ))
                            }

                        </div>
                    </Layout>
                    : <div style={{
                        height: '100vh', display: 'flex', alignItems: 'center',
                        justifyContent: 'center',
                        // backgroundColor: lighter(MainColor, 0.9)
                    }}>
                        <img src={load} style={{
                            width: '10%'
                        }} />
                    </div>


            }
        </>

    )
}
