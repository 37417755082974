import React, { useContext, useEffect, useRef } from 'react';
import { darker, lighter } from '../../helpers/functions';
import { appContext } from '../../context/AuthContext';
import { Separador } from '../../components/invitation/Logos';
import '../../styles/modules/module-generals.css';
import backgroundImage from '../../assets/textures/wall.jpg'

export const Greeting = ({ content, greetingRef, dev }) => {

    const { MainColor, theme, font, colorPalette } = useContext(appContext);

    return (
        <>
            {content.active && colorPalette ? (
                <>
                    <div ref={greetingRef} className='gm-container'>
                        <div
                            className={"g-module-info-container"}
                            style={{
                                backgroundColor: content.background ? colorPalette.secondary : 'transparent',
                                padding: content.background ? '32px' : '0px 32px',
                                width: '100%', height: '100%',
                            }}
                        >
                            <span
                                className={!dev ? "g-module-title" : "g-module-title-dev"}
                                style={{ color: colorPalette.accent, fontFamily: font }}
                            >
                                {content.title}
                            </span>
                            <span
                                className={!dev ? "g-mdoule-regular-text" : "g-mdoule-regular-text-dev"}
                                style={{ color: colorPalette.accent, fontFamily: font }}
                            >
                                {content.description}
                            </span>
                        </div>
                    </div>

                    {
                        content.separator &&
                        <Separador MainColor={colorPalette.accent} theme={theme} />
                    }
                </>
            ) : null}
        </>
    );
};