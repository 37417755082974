import React, { useContext, useEffect, useRef, useState, } from 'react'
import { Button, Layout, Select, Slider } from 'antd';
import { InvitationTest } from '../../pages';
import 'react-resizable/css/styles.css';
import ios_settings from '../../assets/iphone-settings.svg'
import android_settings from '../../assets/android-settings.png'
import { MdFullscreen } from 'react-icons/md';
import { FaTabletScreenButton } from 'react-icons/fa6';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { TbPlayerPlay } from 'react-icons/tb';
import { QRCodeCanvas } from 'qrcode.react';


const baseProd = "https://www.iattend.mx"
const baseTest = "http://localhost:3000"

const devices = [
    {
        name: 'iOS',
        value: 'ios'
    },
    {
        name: 'Android',
        value: 'android'
    }
]

export const ContentBuild = ({ currentOffsetTop, setCurrentOffsetTop, positionY, setPositionY, invitation, coverUpdated, onTry, currentDevice, setDevice, setOnHideHeader, onHideHeader, settingsModal }) => {

    const [size, setSize] = useState({ width: 'calc(100vh / 2.5)', height: '668px' });
    const [mapPosition, setMapPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [lastMousePosition, setLastMousePosition] = useState({ x: 0, y: 0 });
    const [zoomLevel, setZoomLevel] = useState(0.9);
    const [handleDevices, setHandleDevices] = useState(false)
    const [onSeeHow, setOnSeeHow] = useState(false)

    const mapContainerRef = useRef(null);
    const scrollableContentRef = useRef(null)

    const zoomStep = 0.01;
    const minZoom = 0.5;
    const maxZoom = 2;

    useEffect(() => {
        setPositionY(positionY)
    }, [coverUpdated])

    useEffect(() => {
        if (currentDevice === 'ios') {
            setSize({ width: null, height: '668px' })
        } else {
            setSize({ width: null, height: '679px' })
        }

    }, [currentDevice])

    const startDrag = (event) => {
        setIsDragging(true);
        setLastMousePosition({ x: event.clientX, y: event.clientY });
    };

    const drag = (event) => {
        if (isDragging) {
            const deltaX = event.clientX - lastMousePosition.x;
            const deltaY = event.clientY - lastMousePosition.y;

            setMapPosition((prevPosition) => ({
                x: prevPosition.x + deltaX,
                y: prevPosition.y + deltaY,
            }));

            setLastMousePosition({ x: event.clientX, y: event.clientY });
        }
    };

    const stopDrag = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        setHandleDevices(false)
    }, [currentDevice])

    useEffect(() => {
        const container = scrollableContentRef.current;
        if (container) {
            container.scrollTo({ top: currentOffsetTop - 10, behavior: "smooth" });
        }
    }, [currentOffsetTop])







    return (

        invitation && positionY && !coverUpdated ?
            <div className='device-container'
                style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    flexDirection: 'column',
                    width: `auto`,
                    padding: '10px 30px',
                    position: 'relative',
                    flex: 1,
                }}
            >
                <div className='tools-settings-menu-container'>
                    <Button
                        className='full-screen-button'
                        onClick={() => setOnHideHeader(!onHideHeader)} id="expandedbutton" icon={<MdFullscreen size={22} />} />

                    <div style={{
                        position: 'relative'
                    }}>


                        {
                            handleDevices &&
                            <div className='devices-conatinaer'>
                                {
                                    devices.map((device) => (
                                        <span className='devices-item' onClick={() => setDevice(device.value)} >{device.name}</span>
                                    ))
                                }
                            </div>
                        }

                        <Button
                            className='full-screen-button'
                            onClick={() => setHandleDevices(!handleDevices)} id="expandedbutton" icon={<FaTabletScreenButton size={16} />} />

                    </div>


                    <div className='slider-container'>
                        <FaPlus />
                        <Slider
                            vertical
                            min={minZoom}
                            max={maxZoom}
                            step={zoomStep}
                            onChange={(e) => setZoomLevel(e)}
                            value={zoomLevel}
                        />
                        <FaMinus />
                    </div>
                    <div style={{
                        position: 'relative'
                    }}>


                        {
                            onSeeHow &&
                            <div className='seehow-conatinaer'>
                                <span className='show-label-regular'>Mira como se ve tu invitación desde tu celular</span>
                                <span className='show-label-light'>Asegurate de hacer click en Publicar para ver tus últimos cambios</span>
                                <QRCodeCanvas

                                    bgColor={'transparent'} fgColor={'#000000'}
                                    value={`${baseProd}/${invitation.label}/${invitation._id}/see-how`}
                                    size={160} />
                            </div>
                        }

                        <Button
                            className='full-screen-button'
                            onClick={() => setOnSeeHow(!onSeeHow)} id="expandedbutton" icon={<TbPlayerPlay size={16} />} />

                    </div>




                </div>


                <div
                    onMouseDown={startDrag}
                    onMouseMove={drag}
                    onMouseUp={stopDrag}
                    onMouseLeave={stopDrag}
                    ref={mapContainerRef}
                    style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                        flexDirection: 'column',
                        width: `auto`,
                        position: 'relative',
                        transform: `scale(${zoomLevel})`,
                        top: `${mapPosition.y}px`,
                        left: `${mapPosition.x}px`,
                    }}>
                    <div className={`inv-device-main-container-${currentDevice}`}>
                        <div className={`device-buttons-container-${currentDevice}`}>
                            <div className={`device-button-${currentDevice}`} />
                            <div className={`device-button-${currentDevice}`} />
                            <div className={`device-button-${currentDevice}`} />
                        </div>
                        <div className={`device-power-button-${currentDevice}`} />
                        <div className={`inv-device-container-${currentDevice}`}>

                            <div className={`inv-black-space-${currentDevice}`}>
                                <span>5:15</span>
                                <div className={`camera-${currentDevice}`} />
                                <div>
                                    <img src={currentDevice === 'ios' ? ios_settings : android_settings} style={{
                                        height: '100%', objectFit: 'cover'
                                    }} />
                                </div>
                            </div>

                            <div ref={scrollableContentRef} className={`scroll-invitation ${currentDevice}-invitation`}>
                                <InvitationTest setCurrentOffsetTop={setCurrentOffsetTop} positionY={positionY} invitation={invitation} size={size} />
                            </div>
                            <div className={`inv-light-space-${currentDevice}`} />
                        </div>
                    </div>
                </div>




            </div >
            : <></>


    )
}
