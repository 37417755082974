

import loader from '../assets/loader.gif'
import howToSpotify from '../assets/gifs/spotify-howto.gif'
import howToMaps from '../assets/gifs/maps-howto.gif'
import looking from '../assets/watching-img.svg'
import preview from '../assets/preview-design.svg'
import different from '../assets/different-devices.svg'
import logo from '../assets/logo.svg'
import logo2 from '../assets/logo2.svg'
import uno from '../assets/decoration-images/logo2.svg'
import yellow from '../assets/decoration-images/decoration-yellow.svg'
import admin from '../assets/decoration-images/decoration-admin.svg'
import create from '../assets/decoration-images/decoration-create.svg'
import start from '../assets/decoration-images/decoration-start.svg'
import route from '../assets/ROUTE.svg'
import eyes from '../assets/EYES.svg'
import Nu from '../assets/nu.png'

import module1 from '../assets/example-images/example-1.svg'
import module2 from '../assets/example-images/example-2.svg'
import module3 from '../assets/example-images/example-3.svg'
import module4 from '../assets/example-images/example-4.svg'
import gallery from '../assets/example-images/gallery-example.png'
import settings from '../assets/example-images/settings-example.png'
import itinerary from '../assets/example-images/itinerary-example.png'
import admin_guests from '../assets/example-images/admin-guests.png'
import new_guests from '../assets/example-images/new-guests.svg'
import privacy_guests from '../assets/example-images/privacy-guests.svg'
import share_guests from '../assets/example-images/share-guests.svg'
import login from '../assets/login-asset.svg'

export const load = loader


export const MainColor = '#C26462'

export const images = {
    looking,
    preview,
    different,
    logo,
    logo2,
    gallery,
    settings,
    itinerary,
    admin_guests,
    new_guests,
    privacy_guests,
    share_guests,
    login,
    route,
    eyes,
    Nu
}

export const gifs = {
    howToSpotify,
    howToMaps
}

export const decoration = {
    uno,
    yellow,
    admin,
    create,
    start
}

export const images_modules = [

    module4,
    module1,
    module2,
    module3,
]

export const cover_samples = [
    'https://firebasestorage.googleapis.com/v0/b/iattend-df79a.appspot.com/o/covers_samples%2FIMG_1758.PNG?alt=media&token=4487a70e-2fe0-4945-9985-fd657da4aa78',
    'https://firebasestorage.googleapis.com/v0/b/iattend-df79a.appspot.com/o/covers_samples%2FIMG_2219.jpg?alt=media&token=62cc1c6b-2721-447c-a498-242e01555dbd', //
    'https://firebasestorage.googleapis.com/v0/b/iattend-df79a.appspot.com/o/covers_samples%2FIMG_1759.PNG?alt=media&token=960d6208-1d6a-4f85-a376-ad9e7222912e',
    'https://firebasestorage.googleapis.com/v0/b/iattend-df79a.appspot.com/o/covers_samples%2FIMG_1762.PNG?alt=media&token=b8f9b2ed-c99e-4e0f-bc3a-50f565147fcf',
    'https://firebasestorage.googleapis.com/v0/b/iattend-df79a.appspot.com/o/covers_samples%2FIMG_2230.jpg?alt=media&token=254fd6ec-a587-4268-8afb-a05b09e80e21', //
    'https://firebasestorage.googleapis.com/v0/b/iattend-df79a.appspot.com/o/covers_samples%2FIMG_6591.PNG?alt=media&token=d5af7b40-c0b4-46c0-a86d-c86ee93e655f',
    'https://firebasestorage.googleapis.com/v0/b/iattend-df79a.appspot.com/o/covers_samples%2FIMG_2220.jpg?alt=media&token=847880f7-faf0-4d49-b017-6dbc068c1055', //
    'https://firebasestorage.googleapis.com/v0/b/iattend-df79a.appspot.com/o/covers_samples%2FIMG_6592.PNG?alt=media&token=575f061e-8e95-4312-a67c-50833856d2e8',
    'https://firebasestorage.googleapis.com/v0/b/iattend-df79a.appspot.com/o/covers_samples%2FIMG_6593.PNG?alt=media&token=97404b97-c277-4d93-970b-d6b4a6bc7e12',
    'https://firebasestorage.googleapis.com/v0/b/iattend-df79a.appspot.com/o/covers_samples%2FIMG_6594.PNG?alt=media&token=ed455459-2d95-4f60-addb-035671d859cb',
    'https://firebasestorage.googleapis.com/v0/b/iattend-df79a.appspot.com/o/covers_samples%2FIMG_2222.jpg?alt=media&token=4ba21026-6e51-4d2c-9461-7f66d365840a', //
    'https://firebasestorage.googleapis.com/v0/b/iattend-df79a.appspot.com/o/covers_samples%2FIMG_6595.PNG?alt=media&token=040e1ac9-7d09-413e-945d-db15b5a0c7ac',
    'https://firebasestorage.googleapis.com/v0/b/iattend-df79a.appspot.com/o/covers_samples%2FIMG_6597.PNG?alt=media&token=7090e78c-7bf8-4fe7-850a-a9218c3e3a28',
    'https://firebasestorage.googleapis.com/v0/b/iattend-df79a.appspot.com/o/covers_samples%2FIMG_2221.jpg?alt=media&token=5faa63ad-0b46-470a-9084-5ad37ce9d2d3', //
    'https://firebasestorage.googleapis.com/v0/b/iattend-df79a.appspot.com/o/covers_samples%2FIMG_6598.PNG?alt=media&token=3afefcec-d5cd-491a-abe2-a69a7585a838',
    'https://firebasestorage.googleapis.com/v0/b/iattend-df79a.appspot.com/o/covers_samples%2FIMG_6599.PNG?alt=media&token=8aeb3b06-2c1c-476a-a5e4-024a7e082eac',
    'https://firebasestorage.googleapis.com/v0/b/iattend-df79a.appspot.com/o/covers_samples%2FIMG_2223.jpg?alt=media&token=b405a44e-80eb-4b92-a31b-c9478306b064', //
    'https://firebasestorage.googleapis.com/v0/b/iattend-df79a.appspot.com/o/covers_samples%2FIMG_6601.PNG?alt=media&token=19e219da-080b-41dc-b7c3-f966343cb2be'
]


