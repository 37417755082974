import React, { useEffect, useRef, useState } from 'react'
import { HeaderGuests } from '../../layouts/Build'
import '../../styles/organization-table.css'
import { useInvitation } from '../../hooks'
import { getGuestsByInvitationID, getUpdatesByID } from '../../services/apiGuests'
import { Button, Checkbox, Input, InputNumber, Progress, Slider } from 'antd'
import { BsQuestionCircle, BsSliders } from 'react-icons/bs'
import { IoAdd, IoClose } from 'react-icons/io5'
import { IoMdAdd, LuGrab, MdFullscreen, formatDate, formatDateShort } from '../../helpers'
import { CustomButton } from '../../components/CustomButton'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { DynamicTable } from './DynamicTable'
import { tab } from '@testing-library/user-event/dist/tab'

export const TablesPage = ({ invitationID, mode, shared, setOnQR, setMode, refreshPage }) => {


    const { response, loading, error, operation } = useInvitation()
    const [confirmedGuests, setConfirmedGuests] = useState(null)
    const [confirmedGuestsCopy, setConfirmedGuestsCopy] = useState(null)
    const [confirmedGuestsCopy2, setConfirmedGuestsCopy2] = useState(null)
    const [currentFilter, setCurrentFilter] = useState('all')
    const [onFilter, setOnFilter] = useState(false)
    const [onFilterModal, setOnFilterModal] = useState(false)
    const [onAddingGuests, setOnAddingGuests] = useState(false)
    const [onModal, setOnModal] = useState(false)
    const [aboutMyGuest, setAboutMyGuest] = useState(null)
    const [onExtendedWhos, setOnExtendedWhos] = useState(false)
    const [onMoving, setOnMoving] = useState(false)
    const [zoomLevel, setZoomLevel] = useState(0.9);
    const [mapPosition, setMapPosition] = useState({ x: -75, y: -60 });
    const [isDragging, setIsDragging] = useState(false);
    const [lastMousePosition, setLastMousePosition] = useState({ x: 0, y: 0 });

    const [totalChairs, setTotalChairs] = useState(10)
    const [ocuppiedChairs, setOcuppiedChairs] = useState([])
    const [tablesName, setTablesName] = useState(null)
    const [onGuestList, setOnGuestList] = useState(true)
    const [onSelectedTable, setOnSelectedTable] = useState(false)
    const [selectedTable, setSelectedTable] = useState(null)
    const [onViewTable, setOnViewTable] = useState(false)
    const [onEditingTable, setonEditingTable] = useState(false)
    const [onAssignGuest, setOnAssignGuest] = useState(false)
    const [activeList, setActiveList] = useState(null)

    const [tables, setTables] = useState([
        {
            id: 1, // ID único
            name: 'Fam. Serrano',
            totalChairs: 10,
            occupiedChairs: 8,
            position: { x: 0, y: 0 } // Si no hay mesas, empieza en (0,0)
        }
    ])

    const zoomStep = 0.01;
    const minZoom = 0.7;
    const maxZoom = 2;
    const mapContainerRef = useRef(null);

    const backgroundColors = [
        "#FFD1DC", // Rosa pastel
        "#FFECB3", // Amarillo pastel
        "#B3E5FC", // Azul pastel
        "#C8E6C9", // Verde pastel
        "#E1BEE7", // Lila pastel
        "#FFCCBC", // Melocotón pastel
        "#D1C4E9", // Lavanda pastel
        "#F8BBD0", // Rosa claro pastel
        "#DCEDC8", // Verde menta pastel
        "#FFF9C4"  // Crema pastel
    ];

    const processGuests = (data) => {

        // Filtrar solo los invitados confirmados
        const confirmedGuests = data.filter((guest) => guest.state === "confirmado");

        // Diccionario para búsqueda rápida por nombre
        const guestMap = confirmedGuests.reduce((acc, guest) => {
            acc[guest.name] = {
                last_action: guest.last_action,
                last_update_date: guest.last_update_date,
                creation_date: guest.creation_date,
            };
            return acc;
        }, {});

        let processedList = [];
        let parentCount = {}; // Contador para ver cuántos invitados tienen el mismo `parent`

        confirmedGuests.forEach((guest) => {
            // Crear un conjunto para evitar duplicados
            let uniqueCompanions = new Set([...guest.companions]);

            uniqueCompanions.forEach((companion) => {
                let parentId = guest.id; // ID del invitado principal (parent)

                // Contar cuántos companions tienen el mismo parent
                parentCount[parentId] = (parentCount[parentId] || 0) + 1;

                processedList.push({
                    name: companion,
                    place: null,
                    about: guestMap[companion] || null,
                    parent: parentId,
                });
            });
        });

        // Ahora, recorremos la lista procesada para asignar `family` correctamente
        let familyColorMap = new Map(); // Mapa para asignar `color_id` a cada familia
        let currentColorId = 0; // Comienza desde 0 y va hasta 9

        processedList = processedList.map(guest => {
            let isFamily = parentCount[guest.parent] > 1; // Si hay más de un invitado con el mismo parent, `family: true`, si no, `false`

            if (isFamily) {
                // Si la familia aún no tiene asignado un color, asignar el `currentColorId`
                if (!familyColorMap.has(guest.parent)) {
                    familyColorMap.set(guest.parent, currentColorId);
                    currentColorId = (currentColorId + 1) % 10; // Ciclar entre 0-9
                }
            }

            return {
                ...guest,
                family: isFamily,
                color_id: isFamily ? familyColorMap.get(guest.parent) : null // Si `family: false`, color_id es `null`
            };
        });

        return processedList;
    };

    const onFilterbyName = (e) => {
        const value = e.target.value
        const filteredGuests = confirmedGuests.filter((guest) =>
            guest.name.toLowerCase().includes(value.toLowerCase())
        );
        setConfirmedGuestsCopy(filteredGuests);
    };

    const howsMyGuest = (guest) => {
        if (guest) {
            if (guest.about) {
                const parentCompanion = confirmedGuests.filter((e) => e.parent === guest.parent && e.name !== guest.name)
                setAboutMyGuest({
                    isChild: false,
                    name: guest.name,
                    parent: null,
                    parent_about: guest.about,
                    companion: parentCompanion
                })
            } else {
                const parentFiltered = confirmedGuests.filter((e) => e.parent === guest.parent && e.about);
                const parentCompanion = confirmedGuests.filter((e) => e.parent === parentFiltered[0].parent && e.name !== parentFiltered[0].name)
                setAboutMyGuest({
                    isChild: true,
                    name: guest.name,
                    parent: parentFiltered.length > 0 ? parentFiltered[0].name : null,
                    parent_about: parentFiltered[0].about,
                    companion: parentCompanion
                })
            }
        }
    }

    const startDrag = (event) => {
        if (!onMoving) {
            setIsDragging(true);
            setLastMousePosition({ x: event.clientX, y: event.clientY });
        }

    };

    const drag = (event) => {
        if (isDragging && !onMoving) {
            const deltaX = event.clientX - lastMousePosition.x;
            const deltaY = event.clientY - lastMousePosition.y;

            setMapPosition((prevPosition) => {
                // Definir límites
                const minX = -550;
                const maxX = 550; // Ancho máximo del contenedor
                const minY = -500;
                const maxY = 150; // Altura máxima del contenedor

                // Calcular nueva posición con límites
                const newX = Math.min(Math.max(prevPosition.x + deltaX, minX), maxX);
                const newY = Math.min(Math.max(prevPosition.y + deltaY, minY), maxY);

                return { x: newX, y: newY };
            });

            setLastMousePosition({ x: event.clientX, y: event.clientY });
        }
    };

    const stopDrag = () => {
        if (!onMoving) {
            setIsDragging(false);
        }

    };


    const addNewTable = () => {
        setTables((prevTables) => {
            const lastTable = prevTables.length > 0 ? prevTables[prevTables.length - 1] : null;

            return [
                ...prevTables,
                {
                    id: prevTables.length + 1, // ID único
                    name: tablesName,
                    totalChairs: totalChairs,
                    occupiedChairs: ocuppiedChairs.filter(chair => chair.name !== null).length,
                    position: lastTable
                        ? { x: lastTable.position.x + 150, y: lastTable.position.y } // Sumar 20 a la última mesa
                        : { x: 0, y: 0 } // Si no hay mesas, empieza en (0,0)
                }
            ];
        });
        setOnModal(false)
        setTablesName(null)
        setOnAddingGuests(false)
        setOcuppiedChairs(() => {
            const updatedChairs = Array.from({ length: totalChairs }, (_, i) => ({
                name: null,
                id: Date.now() + i, // Mantiene ID si existe, sino genera uno nuevo
            }));
            return updatedChairs;
        });
    };



    useEffect(() => {
        if (invitationID) {
            getGuestsByInvitationID(operation, invitationID)
            getUpdatesByID(operation, invitationID)
        }
    }, [mode])

    useEffect(() => {
        if (response) {

            if (response.data.ok) {
                switch (response.data.msg) {
                    case "Guests by ID":
                        const guests_list = response.data.guest.guests
                        setConfirmedGuests(processGuests(guests_list))
                        break;

                    // case "Updates by ID":
                    //     setUpdatesList(response.data.recentGuests)
                    //     break;

                    default:
                        break;
                }

            }
        }
    }, [response])

    useEffect(() => {
        setConfirmedGuestsCopy(confirmedGuests)
        setConfirmedGuestsCopy2(confirmedGuests)
    }, [confirmedGuests])

    useEffect(() => {
        switch (currentFilter) {
            case 'all':
                setConfirmedGuestsCopy(confirmedGuests)
                break;

            case 'non-assigned':
                setConfirmedGuestsCopy(confirmedGuests.filter((guest) => guest.place === null))
                break;

            case 'compained':
                setConfirmedGuestsCopy(confirmedGuests.filter((guest) => guest.family))
                break;

            case 'alone':
                setConfirmedGuestsCopy(confirmedGuests.filter((guest) => !guest.family))
                break;

            default:
                break;
        }

        setOnFilter(false)
    }, [currentFilter])

    useEffect(() => {
        setOnExtendedWhos(false)
    }, [aboutMyGuest])

    useEffect(() => {
        setOcuppiedChairs((prevChairs) => {
            const updatedChairs = Array.from({ length: totalChairs }, (_, i) => ({
                name: prevChairs[i]?.name || null,
                id: prevChairs[i]?.id || Date.now() + i, // Mantiene ID si existe, sino genera uno nuevo
            }));
            return updatedChairs;
        });
    }, [totalChairs]);

    useEffect(() => {
        setOnAddingGuests(onEditingTable)
    }, [onEditingTable])


    useEffect(() => {
        if (onSelectedTable) {
            const currentTable = tables.find((table) => table.id === onSelectedTable)
            setSelectedTable(currentTable)
        }
    }, [onSelectedTable])

    useEffect(() => {
        if (onAddingGuests) {
            setOnGuestList(true)
        }
    }, [onAddingGuests])

    useEffect(() => {
        setOnAddingGuests(false)
    }, [onModal])

    useEffect(() => {
        setonEditingTable(false)
        if (onViewTable) {
            setOnModal(true)
        }

    }, [onViewTable])



    const updateChair = (name) => {
        setOcuppiedChairs((prevChairs) => {
            // Buscar si el nombre ya existe
            const nameIndex = prevChairs.findIndex(chair => chair.name === name);

            if (nameIndex !== -1) {
                // Si el nombre ya existe, eliminarlo
                return prevChairs.map((chair, i) =>
                    i === nameIndex ? { ...chair, name: null } : chair
                );
            } else {
                // Buscar el primer lugar disponible (name === null)
                const emptyIndex = prevChairs.findIndex(chair => chair.name === null);

                if (emptyIndex !== -1) {
                    // Si hay espacio disponible, agregar el nombre
                    return prevChairs.map((chair, i) =>
                        i === emptyIndex ? { ...chair, name } : chair
                    );
                }
            }
            return prevChairs; // Si no hay cambios, retorna el mismo estado
        });
    };

    const handleAddingGuests = (state) => {
        setOnAddingGuests(state)
        if (!state) {
            setOcuppiedChairs(() => {
                const updatedChairs = Array.from({ length: totalChairs }, (_, i) => ({
                    name: null,
                    id: Date.now() + i, // Mantiene ID si existe, sino genera uno nuevo
                }));
                return updatedChairs;
            });
        }
    }

    const onClosingModal = () => {
        setOnModal(false)
        setOnViewTable(false)
        setSelectedTable(null)
        setOnSelectedTable(null)
        setOnAssignGuest(false)
    }

    const handleAssigdGuest = (id) => {
        setOnModal(true)
        setOnAssignGuest(true)
    }

    const handleEditTable = (id) => {

        setOnSelectedTable(id)
        setOnAssignGuest(false)

        setOnViewTable(true)
        // setonEditingTable(true)

        // setOnAddingGuests(false)
    }



    return (
        <div className="table-organization-main-container">
            <HeaderGuests mode={mode} shared={shared} setOnQR={setOnQR} position={'invitations'} setMode={setMode} refreshPage={refreshPage} />
            <div className='table-org-general-container'>
                <div className='table-map-container'>
                    <div className='tab-map-header-cont'>
                        <span className='table-org-section-header' style={{ padding: '0px' }}>Organización de Invitados</span>

                        <Button onClick={() => setOnGuestList(!onGuestList)} className={`primarybutton${onGuestList ? '--active' : ''}`}>Lista de invitados</Button>
                        {/* <CustomButton
                            onClick={() => setOnModal(true)}
                            icon={<IoMdAdd />} text={'Nueva Mesa'} style={{
                            }} styleText={{ fontSize: '16x', fontWeight: 600 }} /> */}
                    </div>
                    <div
                        onMouseDown={startDrag}
                        onMouseMove={drag}
                        onMouseUp={stopDrag}
                        onMouseLeave={stopDrag}
                        ref={mapContainerRef}
                        className='org-map-container'>
                        <div
                            className='org-map-work-container'
                            style={{
                                top: `${mapPosition.y}px`,
                                left: `${mapPosition.x}px`,
                                transform: `scale(${zoomLevel})`,
                            }}>

                            <div style={{
                                width: '100%',
                                height: '100%', position: 'relative'
                            }}>
                                {
                                    tables.map((table) => (
                                        <DynamicTable
                                            setOnSelectedTable={setOnSelectedTable} onSelectedTable={onSelectedTable}
                                            setTables={setTables} tables={tables} id={table.id} onMoving={onMoving} totalChairs={table.totalChairs} occupiedChairs={table.occupiedChairs} position={table.position} />
                                    ))
                                }

                            </div>

                        </div>

                        <div className='tools-map-menu-container'>
                            <Button
                                className='full-screen-button'
                                style={{ height: '32px', minWidth: '32px' }}
                                // onClick={() => setOnHideHeader(!onHideHeader)} 
                                id="expandedbutton" icon={<MdFullscreen size={18} />} />

                            <Button
                                className={`full-screen-button ${onMoving && 'grab-active-button'}`}
                                style={{ height: '32px', minWidth: '32px' }}
                                onClick={() => setOnMoving(!onMoving)}
                                id="expandedbutton" icon={<LuGrab size={18} />} />

                            <div className='slider-container' style={{ width: '0px', padding: '14px' }}>
                                <FaPlus size={12} />
                                <Slider
                                    vertical
                                    min={minZoom}
                                    max={maxZoom}
                                    step={zoomStep}
                                    onChange={(e) => setZoomLevel(e)}
                                    value={zoomLevel}
                                />
                                <FaMinus size={12} />
                            </div>



                        </div>

                        {
                            selectedTable &&
                            <div className='selected-table-hover-container'
                                style={{
                                    bottom: onSelectedTable ? '20px' : '-150px'
                                }}
                            >
                                <div className='org-single-col'>
                                    <span className='hover-id-text'>#{selectedTable.id}</span>
                                    <span className='hover-id-name' >{selectedTable.name}</span>
                                </div>

                                <div className='org-single-col'>
                                    <span className='hover-button--inactive'>{selectedTable.totalChairs - selectedTable.occupiedChairs} Espacios</span>
                                    <span onClick={() => setOnViewTable(true)} className='hover-button--active'>Ver mesa</span>
                                </div>


                            </div>
                        }



                        <Button onClick={() => setOnModal(true)} icon={<IoAdd size={44} />} className='createtable-button' />
                    </div>
                </div>

                <div className='table-list-container' style={{
                    width: !onGuestList && '10px'
                }}>
                    {
                        confirmedGuestsCopy && onGuestList &&
                        <>
                            <span className='table-org-section-header'>Lista de invitados confirmados ({confirmedGuestsCopy.length})</span>
                            <div className='tab-org-filter-cont'>
                                <Input
                                    placeholder={'Buscar invitado'}
                                    // value={invitation.cover.title}
                                    onChange={onFilterbyName}
                                    className='tab-org-input' />
                                <Button
                                    onClick={() => setOnFilter(!onFilter)}
                                    icon={<BsSliders size={14} />}
                                    className={!onFilter ? 'filtering-button' : 'filtering-button-active'} />
                                {
                                    onFilter &&
                                    <div className='filters-popup'>
                                        <div className='filters-popup-row'>
                                            <span
                                                style={{ cursor: onAddingGuests && 'not-allowed' }}
                                                onClick={() => setCurrentFilter('all')} className={`filter-item ${currentFilter === 'all' && !onAddingGuests && 'filter-item-active'}`}>Todos</span>
                                            <span onClick={() => setCurrentFilter('non-assigned')} className={`filter-item full-item-w ${currentFilter === 'non-assigned' || onAddingGuests && 'filter-item-active'}`}>Sin asignar</span>
                                        </div>
                                        <div className='filters-popup-row'>
                                            <span onClick={() => setCurrentFilter('compained')} className={`filter-item full-item-w ${currentFilter === 'compained' && 'filter-item-active'}`}>Acompañados</span>
                                            <span onClick={() => setCurrentFilter('alone')} className={`filter-item ${currentFilter === 'alone' && 'filter-item-active'}`}>Solos</span>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div style={{ display: onAddingGuests ? 'flex' : 'none' }} className='padding-container'>
                                <div className='tag-disclaimer'>
                                    Solo puedes agregar invitados que no hayan sido previamente asignados en otra mesa
                                </div>
                            </div>

                            <div className='org-guests-table-container'
                                style={{
                                    maxHeight: onAddingGuests && 'calc(75vh - 90px)'
                                }}
                            >
                                {
                                    onAddingGuests ?

                                        confirmedGuestsCopy.filter((guest) => guest.place === null).map((guest) => (

                                            <div className='org-tab-item' style={{
                                                backgroundColor: currentFilter === 'compained' && `${backgroundColors[guest.color_id]}40`,
                                                borderBottom: currentFilter === 'compained' && '1px solid #FFF'
                                            }}>

                                                <div className="org-single-row">
                                                    <Checkbox onChange={(e) => updateChair(guest.name)} />
                                                    <span className='org-tab-name'>{guest.name}</span>

                                                </div>

                                                <Button
                                                    className='orgtabbutton'
                                                    type='ghost' icon={<BsQuestionCircle size={16} style={{ marginTop: '2px' }} />} onClick={() => howsMyGuest(guest)} style={{ background: currentFilter === 'compained' && backgroundColors[guest.color_id] }} />

                                            </div>
                                        ))
                                        :
                                        confirmedGuestsCopy.map((guest) => (

                                            <div className='org-tab-item' style={{
                                                backgroundColor: currentFilter === 'compained' && `${backgroundColors[guest.color_id]}40`,
                                                borderBottom: currentFilter === 'compained' && '1px solid #FFF'
                                            }}>
                                                <span className='org-tab-name'>{guest.name}</span>
                                                <div className="org-single-row">
                                                    <div className='org-place-tag'>
                                                        {guest.place ? guest.place : 'Sin asignar'}
                                                    </div>
                                                    <Button
                                                        onClick={() => handleAssigdGuest(guest)}
                                                        style={{ background: currentFilter === 'compained' && backgroundColors[guest.color_id] }} className="orgtabbutton">Asignar</Button>
                                                    <Button icon={<BsQuestionCircle size={16} style={{ marginTop: '2px' }} />} onClick={() => howsMyGuest(guest)} style={{ background: currentFilter === 'compained' && backgroundColors[guest.color_id] }} className="orgtabbutton" />
                                                </div>
                                            </div>

                                        ))
                                }
                            </div>
                            {
                                aboutMyGuest &&
                                <div className='who-is-main-container'>
                                    {
                                        aboutMyGuest.isChild ?
                                            <>
                                                <div className='who-is-container'>
                                                    <Button onClick={() => setAboutMyGuest(null)} icon={<IoClose />} type='ghost' className='closehowisbtn' />
                                                    <span><b>{aboutMyGuest.name}</b> es uno de los acompañantes de <b onClick={() => setOnExtendedWhos(!onExtendedWhos)} className='parent-label-whois'>{aboutMyGuest.parent}</b></span>
                                                </div>
                                                {
                                                    onExtendedWhos &&
                                                    <>
                                                        <div className='whos-connector' />
                                                        <div className='who-is-container'>
                                                            <span>Agregaste a <b>{aboutMyGuest.parent}</b> a tu lista de invitados el <b>{formatDate(aboutMyGuest.parent_about.creation_date)}</b> y confirmó su asistencia el <b>{formatDate(aboutMyGuest.parent_about.last_update_date)}</b></span>
                                                            {
                                                                aboutMyGuest.companion && aboutMyGuest.companion.length > 0 &&
                                                                <>
                                                                    <span>Le acompañan: </span>
                                                                    <ul>

                                                                        {
                                                                            aboutMyGuest.companion.map((guest, index) => (
                                                                                <li key={index}>{guest.name}</li>
                                                                            ))
                                                                        }

                                                                    </ul>
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                                }
                                            </>
                                            : <div className='who-is-container'>
                                                <Button onClick={() => setAboutMyGuest(null)} icon={<IoClose />} type='ghost' className='closehowisbtn' />
                                                <span>Agregaste a <b>{aboutMyGuest.name}</b> a tu lista de invitados el <b>{formatDate(aboutMyGuest.parent_about.creation_date)}</b> y confirmó su asistencia el <b>{formatDate(aboutMyGuest.parent_about.last_update_date)}</b></span>
                                                {
                                                    aboutMyGuest.companion && aboutMyGuest.companion.length > 0 ?
                                                        <>
                                                            <span>Le acompañan: </span>
                                                            <ul>

                                                                {
                                                                    aboutMyGuest.companion.map((guest, index) => (
                                                                        <li key={index}>{guest.name}</li>
                                                                    ))
                                                                }

                                                            </ul>
                                                        </> :
                                                        <span className='no-companions-special-label'>*No lleva acompañantes*</span>
                                                }
                                            </div>
                                    }
                                </div>
                            }
                        </>
                    }


                </div>


            </div>

            {
                onModal &&
                <div
                    className='modal-main-container'
                >
                    {
                        onAssignGuest ?
                            <div className='new-table-modal'>
                                <div style={{
                                    width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                                }}>
                                    <span className='table-org-section-header' style={{ padding: '0px', pointerEvents: 'none' }}>Seleccionar mesa</span>



                                    <Button
                                        className='secondarybutton'
                                        onClick={onClosingModal} icon={<IoClose size={18} style={{
                                            marginTop: '2px', color: '#000'
                                        }} />} />



                                </div>

                                <div style={{
                                    display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
                                    flexDirection: 'column', gap: '24px', alignSelf: 'stretch',
                                    paddingBottom: '16px'
                                }}>
                                    {
                                        tables.map((item) => (
                                            <div className='org-table-card'>
                                                <div className='org-tab-card-row'>
                                                    <div className='org-single-col' style={{ gap: '0px' }}>
                                                        <span className='single-label'><b>#{item.id}</b></span>
                                                        <span className='single-label'>{item.name}</span>
                                                    </div>


                                                    <Button
                                                        className='primarybutton--active'>
                                                        Seleccionar mesa
                                                    </Button>

                                                </div>

                                                <div className='org-tab-single-row'>
                                                    <Progress
                                                        style={{ flex: 1 }}
                                                        strokeColor={"#FFB32D"}
                                                        showInfo={false}
                                                        percent={(item.occupiedChairs * 100) / item.totalChairs} />
                                                    <span className='single-label'>{item.occupiedChairs} / {item.totalChairs}</span>

                                                </div>

                                                <div className='org-tab-card-row'>
                                                    <Button onClick={() => setActiveList(activeList === item.id ? null : item.id)} className='secondarybutton'>
                                                        {activeList === item.id ? 'Cerrar' : 'Ver invitados'}
                                                    </Button>

                                                    {
                                                        activeList === item.id &&
                                                        <Button
                                                            style={{ fontSize: '14px' }}
                                                            onClick={() => handleEditTable(item.id)}
                                                            className='orgtabbutton'>
                                                            Editar mesa
                                                        </Button>
                                                    }


                                                </div>

                                                {
                                                    activeList === item.id &&
                                                    <div className='org-single-col' style={{
                                                        alignSelf: 'stretch',
                                                    }}>
                                                        {
                                                            ocuppiedChairs.map((chair, index) => (
                                                                <div className='popup-available-spaces-item'>
                                                                    <span className='single-label'>{index + 1}.</span>
                                                                    <span className='single-label'>{chair.name}</span>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>

                                                }





                                            </div>
                                        ))
                                    }

                                </div>

                            </div>

                            :

                            onViewTable && selectedTable ?
                                <div className='new-table-modal'>
                                    <div style={{
                                        width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'
                                    }}>
                                        <span className='table-org-section-header' style={{ padding: '0px', pointerEvents: 'none' }}>#{selectedTable.id}</span>

                                        <div style={{
                                            display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '6px'
                                        }}>
                                            <Button className={`primarybutton${onEditingTable ? '--active' : ''}`} onClick={() => setonEditingTable(!onEditingTable)}>
                                                {onEditingTable ? 'Guardar' : 'Editar'}
                                            </Button>
                                            <Button
                                                className='secondarybutton'
                                                onClick={onClosingModal} icon={<IoClose size={18} style={{
                                                    marginTop: '2px', color: '#000'
                                                }} />} />
                                        </div>

                                    </div>

                                    <div style={{
                                        display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
                                        width: '100%', gap: '20px'
                                    }}>
                                        {
                                            onEditingTable ?
                                                <div className='org-small-col'>
                                                    <span className='single-label'>Nombre</span>
                                                    <Input
                                                        style={{ minHeight: '30px', borderRadius: '8px', backgroundColor: '#FFFFFF' }}
                                                        placeholder={'Nombre de la mesa'}
                                                        value={tablesName}
                                                        onChange={(e) => setTablesName(e.target.value)}
                                                        // onChange={onFilterbyName}
                                                        className='tab-org-input' />
                                                </div>
                                                : <span>{selectedTable.name}</span>
                                        }

                                        {
                                            !onEditingTable && <span>/</span>
                                        }

                                        {
                                            onEditingTable ?
                                                <div className='org-small-col'>
                                                    <span className='single-label'>Número de asientos</span>
                                                    <InputNumber
                                                        style={{
                                                            minHeight: '30px', width: '100%',
                                                            backgroundColor: '#FFFFFF', borderRadius: '8px'
                                                        }}
                                                        value={totalChairs}
                                                        onChange={(e) => setTotalChairs(e)}
                                                        className='tab-org-input' />
                                                </div>
                                                : <span>{selectedTable.totalChairs} Lugares</span>
                                        }

                                    </div>



                                    <div className='tag-disclaimer'>
                                        {selectedTable.totalChairs - selectedTable.occupiedChairs} Espacios disponibles
                                    </div>

                                    {
                                        onEditingTable &&
                                        <span className='single-label' style={{ opacity: 0.4, textAlign: 'center' }}>
                                            Selecciona los invitados que deseas agregar o eliminar de tu mesa
                                        </span>
                                    }



                                    <div className='popup-available-spaces-list' >
                                        {
                                            ocuppiedChairs.map((chair, index) => (
                                                <div className='popup-available-spaces-item'>
                                                    <span className='single-label'>{index + 1}.</span>
                                                    <span className='single-label'>{chair.name}</span>
                                                </div>
                                            ))
                                        }
                                    </div>


                                </div>

                                :

                                <div className='new-table-modal'>
                                    <div style={{
                                        width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'
                                    }}>
                                        <span className='table-org-section-header' style={{ padding: '0px', pointerEvents: 'none' }}>Agregar nueva mesa</span>

                                        <div style={{
                                            display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '6px'
                                        }}>
                                            <Button className='primarybutton' onClick={addNewTable}>
                                                Crear Mesa
                                            </Button>
                                            <Button
                                                className='secondarybutton'
                                                onClick={() => setOnModal(false)} icon={<IoClose size={18} style={{
                                                    marginTop: '2px', color: '#000'
                                                }} />} />
                                        </div>


                                    </div>

                                    <div style={{
                                        display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
                                        width: '100%', gap: '20px'
                                    }}>
                                        <div className='org-small-col'>
                                            <span className='single-label'>Nombre</span>
                                            <Input
                                                style={{ minHeight: '30px', borderRadius: '8px', backgroundColor: '#FFFFFF' }}
                                                placeholder={'Nombre de la mesa'}
                                                value={tablesName}
                                                onChange={(e) => setTablesName(e.target.value)}
                                                // onChange={onFilterbyName}
                                                className='tab-org-input' />
                                        </div>

                                        <div className='org-small-col'>
                                            <span className='single-label'>Número de asientos</span>
                                            <InputNumber
                                                style={{
                                                    minHeight: '30px', width: '100%',
                                                    backgroundColor: '#FFFFFF', borderRadius: '8px'
                                                }}
                                                value={totalChairs}
                                                onChange={(e) => setTotalChairs(e)}
                                                className='tab-org-input' />
                                        </div>
                                    </div>

                                    <div className='org-tab-card-row'>

                                        {
                                            onAddingGuests &&
                                            <span className='single-label'>
                                                Selecciona los invitados que deseas agregar a tu mesa
                                            </span>
                                        }

                                        <Button className='primarybutton' onClick={() => handleAddingGuests(!onAddingGuests)} >
                                            {
                                                onAddingGuests ? 'Agregar Después'
                                                    : 'Agregar Invitados'
                                            }
                                        </Button>
                                    </div>






                                    <div className='popup-available-spaces-list' style={{
                                        display: onAddingGuests ? 'flex' : 'none'
                                    }}>
                                        {
                                            ocuppiedChairs.map((chair, index) => (
                                                <div className='popup-available-spaces-item'>
                                                    <span className='single-label'>{index + 1}.</span>
                                                    <span className='single-label'>{chair.name}</span>
                                                </div>
                                            ))
                                        }
                                    </div>

                                </div>
                    }



                </div>
            }
        </div>


    )
}
