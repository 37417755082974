import React, { useContext, useEffect, useState, } from 'react'
import { Button, Col, Dropdown, Empty, Input, Layout, Progress, Row, message } from 'antd';
import { storage } from '../../firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import {
    IoMdAdd, FaRegCalendarAlt, LuBadgeHelp, IoMdOpen, formatDate, formatDateShort, IoMdSettings, IoArrowRedoSharp,
    FaRegCopy, TiThMenu, CgOptions, TbExternalLink, PiLinkBold, FaLock, FaUnlockAlt, HiLockClosed,
    IoMdArrowRoundForward, FiCopy, load, PiMonitorPlayBold,
    HiLockOpen, MdOutlineTimeline, MdDevices, MdBubbleChart,
    images,
    images_modules,
    decoration,
    TiUserAddOutline,
    IoCloseSharp,
    IoAddCircleOutline,
    BiHide,
    BiShow,
    FaRegTrashCan,
    GoShare,
    RiUserAddLine,
    GoShareAndroid,
    FiShare2,
    SlArrowRight,
    RiArrowRightSLine,
    IoMdLink,
    BsFillGridFill,
    BsGrid,
    RxGrid,
    RiListUnordered,
    HiMiniArrowUpRight,
    lighter,
    darker
} from '../../helpers'
import { Link } from 'react-router-dom';
import { useInvitation } from '../../hooks';
import { getAllInvitations, getAllUserInvitations, getAllUserTags, getInvitationbyID } from '../../services';
import { appContext } from '../../context';
import { Login } from '../../components/land-page/Login';
import { CustomButton } from '../../components/CustomButton';
import { NewInvitationDrawer } from '../../components/NewInvitationDrawer';
import { FooterMobile, HeaderBuild } from '../Build';
import justWeb from '../../assets/just_web.svg'
import { useLocation } from 'react-router-dom';
import { FaLessThanEqual, FaLink, FaListUl, FaUserPlus, FaWhatsapp } from 'react-icons/fa';
import { addShare, deleteShare, getGuestsByInvitationID } from '../../services/apiGuests';
import ios_settings from '../../assets/iphone-settings.svg'
import Countdown from '../../components/invitation/CountDown';
const { Content } = Layout;
const items = [
    {
        key: '1',
        label: (
            <a >
                Eliminar Invitacion
            </a>
        ),
    },

];

const desginCards = [
    {
        icon: <MdBubbleChart />,
        title: "Personalización Total",
        text: "Elige entre una amplia variedad de paletas de colores y tipografías, y ajusta cada elemento de la invitación para reflejar perfectamente el tema de tu evento.",
        image: images.itinerary,
    },
    {
        icon: <MdDevices />,
        title: "Flexibilidad en el Diseño",
        text: "Reorganiza los elementos a tu gusto, cambia imágenes, colores, y más. La libertad creativa está en tus manos para hacer que cada invitación sea única.",
        image: images.gallery,
    },
    {
        icon: <MdOutlineTimeline />,
        title: "Previsualización en Tiempo Real",
        text: "Ve cómo se verá tu invitación a medida que la diseñas. Con la previsualización en tiempo real, puedes hacer ajustes instantáneos y asegurarte de que todo esté perfecto antes de enviarla.",
        image: images.settings,
    }
]

const baseProd = "https://www.iattend.mx"
const baseDev = "http://localhost:3000";

export const ContentInvitations = ({ setMode, setCurrentInvitation, mode, saved, setOpenLogin, setSaved, handleQRO }) => {

    // getAllInvitations
    const { response, loading, error, operation } = useInvitation()
    const [myInvitations, setMyInvitations] = useState(null)
    const [invitationsCopy, setInvitationsCopy] = useState(null)
    const [visible, setVisible] = useState(false)
    const { logged, user, setOnDate, date } = useContext(appContext)
    const [loader, setLoader] = useState(false)
    const [currentPosition, setCurrentPosition] = useState(0)
    const [isVisible, setIsVisible] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const { pathname } = useLocation();
    const [onShare, setOnShare] = useState(false)
    const [newShared, setNewShared] = useState(null)
    const [sharedWith, setSharedWith] = useState([])
    const [onViewPasswords, setOnViewPasswords] = useState(false)
    const [invitationID, setinvitationID] = useState(null)
    const [onGrid, setOnGrid] = useState(false)
    const [filterInvs, setFilterInvs] = useState(null)



    const getTimeUntilFutureDate = (futureDate) => {
        const currentDate = new Date();
        const targetDate = new Date(futureDate);

        // Calcular la diferencia en milisegundos
        const diffInMilliseconds = targetDate - currentDate;

        // Calcular la diferencia en días
        const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

        if (diffInDays > 3) {
            return `${diffInDays} días`;
        } else {
            // Calcular la diferencia en horas si faltan 3 días o menos
            const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
            return `${diffInHours} horas`;
        }
    }

    const refreshInvitations = () => {
        if (logged && user) {
            user.uid != "66a57fd93d724e3f40549da0" ?
                getAllUserInvitations(operation, user.uid)
                : getAllUserTags(operation, user.uid)
            setLoader(true)
        }

    }

    const copyToClipboard = async (textToCopy) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            message.success('Link copiado')
        } catch (err) {
            console.error('Error al copiar el texto: ', err);
        }
    };

    const handleMoode = (mode, id) => {
        setMode(mode)
        setCurrentInvitation(id)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    useEffect(() => {
        if (logged && user) {
            user.uid != "66a57fd93d724e3f40549da0" ?
                getAllUserInvitations(operation, user.uid)
                : getAllUserTags(operation, user.uid)
            setLoader(true)
        } else {
            setLoader(false)
        }

    }, [])

    useEffect(() => {
        if (logged && user) {
            user.uid != "66a57fd93d724e3f40549da0" ?
                getAllUserInvitations(operation, user.uid)
                : getAllUserTags(operation, user.uid)
            setLoader(true)
        } else {
            setLoader(false)
        }

    }, [logged])

    useEffect(() => {
        setInvitationsCopy(myInvitations)
    }, [myInvitations])



    useEffect(() => {
        if (response) {
            if (response.data.ok) {
                switch (response.data.msg) {

                    case "Get invitations by user ID":
                        setMyInvitations(response.data.data)
                        setLoader(false)
                        break

                    case "Guests by ID":
                        // console.log('my guests: ', response.data)
                        const guests_list = response.data.guest
                        setSharedWith(guests_list.share)
                        setOnShare(true)

                        break;

                    case "Share item added successfully":
                        setSharedWith(response.data.share)
                        setNewShared(null)
                        break;

                    case "Share item removed successfully":
                        setSharedWith(response.data.share)
                        break;

                    default:
                        break;
                }
            }
        }
    }, [response])

    useEffect(() => {
        setOnDate(new Date())
        setIsVisible(true)
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const margin = 0; // Ajusta este valor según tu preferencia


            if (currentScrollPos < margin) {
                setIsVisible(false);
            } else {
                setIsVisible(prevScrollPos > currentScrollPos);

                setPrevScrollPos(currentScrollPos);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const onNewShare = () => {

        if (newShared) {
            if (validateEmail(newShared)) {
                addShare(operation, invitationID, newShared);
            } else {
                message.warning('El email debe tener un formato correcto');
            }
        }


    };

    const onDeleteShare = (id) => {
        deleteShare(operation, invitationID, id);
    }

    const handleShareWith = (_id) => {
        if (invitationID === _id) {
            if (onShare) {
                setOnShare(false)
            } else {
                getGuestsByInvitationID(operation, _id)
            }
        } else {
            setOnShare(false)
            getGuestsByInvitationID(operation, _id)
        }

        setinvitationID(_id)
    }

    const getFirstLetterUpperCase = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase();
    }

    const handleFilter = (value) => {
        setInvitationsCopy(
            myInvitations.filter((inv) => inv.cover?.title?.toLowerCase().includes(value.toLowerCase()))
        );
    };

    // 




    return (

        <>
            <Layout
                className='responsive-web'
                style={{
                    position: 'relative',
                    alignItems: 'center', justifyContent: 'center',
                    backgroundColor: '#FFF',
                    // height: '100vh'
                }}>
                <HeaderBuild position={'invitations'} setOpenLogin={setOpenLogin} isVisible={isVisible} />
                <Layout className='scroll-invitation build-invitation-layout' style={{
                    // paddingBottom: '30px',
                    marginTop: '80px',
                }} >
                    {
                        loader ?
                            <div style={{
                                height: '80vh', display: 'flex', alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',

                                // backgroundColor: lighter(MainColor, 0.9)
                            }}>
                                <img src={load} style={{
                                    width: '10%'
                                }} />
                            </div>


                            : <Content className='invitations-main-content'>
                                <div style={{
                                    display: onShare ? 'flex' : 'none',
                                    right: '20px', top: '100px'
                                }} className='on-share-container'>

                                    <div className='on-share-title-container'>
                                        <span className='on-share--title'>Compartir acceso</span>
                                        <Button
                                            onClick={() => setOnShare(false)}
                                            id='gc-cta-buttons'
                                            type='ghost'
                                            icon={<IoCloseSharp size={15} />} />
                                    </div>

                                    <div className='add-new-shared-container'>
                                        <Input placeholder={'Agrega correo'}
                                            style={{ flex: 1 }}
                                            value={newShared}
                                            onChange={(e) => setNewShared(e.target.value)}
                                            className='gc-input-text' />

                                        <Button
                                            onClick={onNewShare}
                                            id='gc-cta-buttons'
                                            type='ghost'
                                            style={{
                                                borderRadius: '10px', marginLeft: '5px',
                                                display: 'flex', alignItems: 'center', justifyContent: 'center'
                                            }}
                                            icon={<IoAddCircleOutline size={25} />} />
                                    </div>

                                    <div className='on-share-view-pass-container' style={{ justifyContent: 'flex-start' }}>

                                        <Button
                                            onClick={() => setOnViewPasswords(!onViewPasswords)}
                                            id='gc-cta-buttons'
                                            type='ghost'
                                            icon={onViewPasswords ? <BiHide size={20} /> : <BiShow size={20} />} />
                                        <span className='on-share--label'>Personas que tienen acceso</span>
                                    </div>

                                    <div className='shared-people-container'>
                                        {
                                            sharedWith.map((person, index) => (
                                                <>
                                                    <div key={index} className='shared-person-row-container'>
                                                        <div className='shared-person-row-container' style={{ margin: 0, padding: 0 }}>
                                                            <div className='shared-person-icon'>
                                                                {
                                                                    person ? getFirstLetterUpperCase(person.email)
                                                                        : 'A'

                                                                }
                                                            </div>
                                                            <Col style={{
                                                                display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column'
                                                            }}>
                                                                <span style={{
                                                                    marginLeft: '8px'
                                                                }} className='on-share-single-label'>{person.email}</span>
                                                                <div style={{
                                                                    display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row'
                                                                }}>
                                                                    <Button
                                                                        onClick={() => copyToClipboard(person.password)}
                                                                        id='gc-cta-buttons'
                                                                        type='ghost'
                                                                        style={{ marginRight: '5px' }}
                                                                        icon={<FiCopy size={15} />}
                                                                    />
                                                                    <span style={{ width: '100%' }} className='on-share-single-label'>{onViewPasswords ? person.password : '••••••••••'}</span>
                                                                </div>

                                                            </Col>

                                                        </div>

                                                        <Button
                                                            onClick={() => onDeleteShare(person.id)}
                                                            id='gc-cta-buttons'
                                                            type='ghost'
                                                            style={{ marginRight: '5px', zIndex: '10' }}
                                                            icon={<FaRegTrashCan size={15} />} />
                                                    </div>



                                                </>


                                            ))
                                        }
                                    </div>


                                    <div className='share-copy-container'>
                                        <Button
                                            onClick={() => copyToClipboard(`${baseProd}/share/${invitationID}`)}
                                            icon={<IoMdLink size={22} />}
                                            id='share-copy-button'>Copiar link</Button>
                                    </div>



                                </div>
                                {
                                    logged ?
                                        <div style={{
                                            display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                                            position: 'relative',
                                            padding: '10px',
                                            width: '100%', maxWidth: '1480px', position: 'relative',


                                        }}>
                                            <div className='inv-title-cta-filter'>


                                                <div className='tag-info mobile-tag' >
                                                    Crea y edita invitaciones desde una tablet o computadora
                                                </div>

                                                <Row style={{
                                                    margin: '30px 0', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                                                    gap: '14px', flex: 1
                                                }}>
                                                    <Input
                                                        placeholder={'Búscar invitación'}
                                                        // value={invitation.cover.title}
                                                        onChange={(e) => handleFilter(e.target.value)}
                                                        className='invs-searcher' />
                                                    <div className='grid-layout'>
                                                        <Button
                                                            // disabled={!invitation.active}
                                                            onClick={() => setOnGrid(false)}
                                                            id='gc-cta-buttons'
                                                            type='ghost'
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                margin: '0px',
                                                                color: !onGrid && '#FFB32D'
                                                            }}
                                                            icon={<RiListUnordered size={16} />}
                                                        />

                                                        <div className='grid-layout-divisor' />
                                                        <Button
                                                            // disabled={!invitation.active}
                                                            onClick={() => setOnGrid(true)}
                                                            id='gc-cta-buttons'
                                                            type='ghost'
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                margin: '0px',
                                                                color: onGrid && '#FFB32D'
                                                            }}
                                                            icon={<RxGrid size={16} />}
                                                        />

                                                    </div>






                                                </Row>

                                                <div className="new-invitation-button">
                                                    <CustomButton

                                                        onClick={() => setVisible(true)}
                                                        icon={<IoMdAdd />} text={'Nueva Invitación'} style={{

                                                        }} styleText={{ fontSize: '20px', marginLeft: '5px', fontWeight: 700 }} />
                                                </div>





                                            </div>

                                            <div className={`inv-invitations-container${onGrid ? '-grid' : ''}`}>
                                                {
                                                    load ? (
                                                        invitationsCopy?.length > 0 ? (
                                                            invitationsCopy.map((invitation) => (
                                                                // <div
                                                                //     style={{ transform: `scale(0.8)`, marginTop: '-50px' }}
                                                                //     className={`inv-device-main-container-ios`}>
                                                                //     <div className={`device-buttons-container-ios`}>
                                                                //         <div className={`device-button-ios`} />
                                                                //         <div className={`device-button-ios`} />
                                                                //         <div className={`device-button-ios`} />
                                                                //     </div>
                                                                //     <div className={`device-power-button-ios`} />
                                                                //     <div className={`inv-device-container-ios`}>

                                                                //         <div className={`inv-black-space-ios`}>
                                                                //             <span>5:15</span>
                                                                //             <div className={`camera-ios`} />
                                                                //             <div>
                                                                //                 <img src={ios_settings} style={{
                                                                //                     height: '100%', objectFit: 'cover'
                                                                //                 }} />
                                                                //             </div>
                                                                //         </div>

                                                                //         <div
                                                                //             className={`ios-invitation inv-set-position grid-guides`}
                                                                //             style={{
                                                                //                 background: invitation.generals.palette.primary,
                                                                //             }}
                                                                //         >

                                                                //             <div
                                                                //                 className='adj-image-container'>
                                                                //                 <img
                                                                //                     src={invitation.cover.featured_prod}
                                                                //                 />
                                                                //                 <div style={{
                                                                //                     position: 'absolute', width: '100%', height: '100%', top: '0px', left: '0px',
                                                                //                     background: `linear-gradient(to top, ${invitation.generals.theme ? darker(invitation.generals.palette.primary, 0.2) : darker(invitation.generals.palette.primary, 0.2)}, rgba(0,0,0,0))`
                                                                //                 }}></div>


                                                                //             </div>
                                                                //             <div className='background-cover'
                                                                //                 style={{
                                                                //                     flexDirection: invitation.cover.flexDirection,
                                                                //                     pointerEvents: 'none',
                                                                //                     zIndex: 3
                                                                //                 }}>



                                                                //                 <div className='cover--title-container' style={{
                                                                //                     alignItems: invitation.cover.align, height: invitation.cover.date ? '75%' : '100%',
                                                                //                     padding: invitation.cover.date ? 0 : '10px',

                                                                //                 }}>
                                                                //                     <span style={{
                                                                //                         color: !invitation.cover.color ? invitation.generals.theme ? lighter(invitation.generals.palette.primary, 0.6) : lighter(invitation.generals.palette.accent, 0.6) : invitation.cover.color, width: '100%',
                                                                //                         textAlign: invitation.cover.justify, fontSize: `${invitation.cover.fontSize}em`, wordBreak: 'break-word',
                                                                //                         opacity: invitation.cover.opacity, fontFamily: invitation.cover.image, fontWeight: invitation.cover.fontWeight,
                                                                //                         lineHeight: '1'
                                                                //                     }}>{invitation.cover.title}</span>
                                                                //                 </div>

                                                                //                 {
                                                                //                     invitation.cover.date && (
                                                                //                         <div style={{
                                                                //                             width: '100%',
                                                                //                             backgroundColor: `transparent`,

                                                                //                             height: '25%', marginTop: '10px',
                                                                //                             display: 'flex', alignItems: 'center', justifyContent: 'center'
                                                                //                         }}>

                                                                //                             <Countdown mainColor={invitation.cover.color} color={invitation.cover.timerColor} colorPalette={invitation.generals.palette} targetDate={invitation.cover.date} theme={invitation.generals.theme} font={invitation.cover.image} fontWeight={invitation.cover.fontWeight} dev={true} />

                                                                //                         </div>
                                                                //                     )
                                                                //                 }



                                                                //             </div>

                                                                //         </div>
                                                                //         <div className={`inv-light-space-ios`} />
                                                                //     </div>
                                                                // </div>
                                                                <div
                                                                    key={invitation._id}
                                                                    className={`invitation-container${onGrid ? '-grid' : ''}`}
                                                                >
                                                                    <div className={`invitation-image-container${onGrid ? '-grid' : ''}`}>
                                                                        {
                                                                            invitation.cover.featured_prod ? (
                                                                                <>
                                                                                    <img src={invitation.cover.featured_prod} alt="Featured product" />
                                                                                    <div style={{
                                                                                        position: 'absolute', width: '100%', height: '100%', top: '0px', left: '0px',
                                                                                        background: `linear-gradient(to top, ${invitation.generals.theme ? darker(invitation.generals.palette.primary, 0.2) : darker(invitation.generals.palette.primary, 0.2)}, rgba(0,0,0,0))`
                                                                                    }}></div>
                                                                                    {
                                                                                        !invitation.active || invitation.payment === 'false' &&
                                                                                        <div
                                                                                            className='background-color'
                                                                                            style={{
                                                                                                background: '#fff',
                                                                                                opacity: 0.75,
                                                                                            }}
                                                                                        />
                                                                                    }

                                                                                    {
                                                                                        !onGrid ?


                                                                                            <div className='background-cover'
                                                                                                style={{
                                                                                                    flexDirection: invitation.cover.flexDirection,
                                                                                                    pointerEvents: 'none',
                                                                                                    zIndex: 3
                                                                                                }}>




                                                                                                <div className='cover--title-container' style={{
                                                                                                    alignItems: invitation.cover.align, height: invitation.cover.date ? '75%' : '100%',
                                                                                                    padding: invitation.cover.date ? 0 : '10px',

                                                                                                }}>
                                                                                                    <span style={{
                                                                                                        color: !invitation.cover.color ? invitation.generals.theme ? lighter(invitation.generals.palette.primary, 0.6) : lighter(invitation.generals.palette.accent, 0.6) : invitation.cover.color, width: '100%',
                                                                                                        textAlign: invitation.cover.justify, fontSize: `${(invitation.cover.fontSize) - 1}em`, wordBreak: 'break-word',
                                                                                                        opacity: invitation.cover.opacity, fontFamily: invitation.cover.image, fontWeight: invitation.cover.fontWeight,
                                                                                                        lineHeight: '1'
                                                                                                    }}>{invitation.cover.title}</span>
                                                                                                </div>

                                                                                                {
                                                                                                    invitation.cover.date && (
                                                                                                        <div style={{
                                                                                                            width: '100%',
                                                                                                            backgroundColor: `transparent`,

                                                                                                            height: '25%', marginTop: '10px',
                                                                                                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                                                                                                        }}>

                                                                                                            <Countdown mainColor={invitation.cover.color} color={invitation.cover.timerColor} colorPalette={invitation.generals.palette} targetDate={invitation.cover.date} theme={invitation.generals.theme} font={invitation.cover.image} fontWeight={invitation.cover.fontWeight} dev={true} />

                                                                                                        </div>
                                                                                                    )
                                                                                                }



                                                                                            </div>
                                                                                            :

                                                                                            <span
                                                                                                style={{
                                                                                                    fontFamily: invitation.cover.image,
                                                                                                    fontWeight: invitation.cover.fontWeight,
                                                                                                    color: invitation.cover.color,
                                                                                                }}
                                                                                                className='invitation-image-title'
                                                                                            >
                                                                                                {invitation.cover.title}
                                                                                            </span>
                                                                                    }
                                                                                </>


                                                                            ) : (
                                                                                <div
                                                                                    className='background-color'
                                                                                    style={{
                                                                                        background: invitation.cover.background,
                                                                                        opacity: 0.85,
                                                                                    }}
                                                                                />
                                                                            )
                                                                        }

                                                                    </div>


                                                                    <div style={{
                                                                        alignSelf: 'stretch', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                                                                        position: onGrid ? 'static' : 'absolute', top: onGrid && '0px', width: !onGrid && '320px',
                                                                        backgroundColor: onGrid && '#F5F3F2'
                                                                    }}>

                                                                        <div className='invitation-settings-container'>
                                                                            <Row className='inv-status-container'>
                                                                                <a className='gc--title label'
                                                                                    style={{
                                                                                        fontSize: onGrid && '13px'
                                                                                    }}
                                                                                >
                                                                                    {invitation.generals.eventName}
                                                                                </a>
                                                                            </Row>
                                                                            <div className='icon-deadline-container'>
                                                                                <Button
                                                                                    disabled={!invitation.active}
                                                                                    onClick={() => handleShareWith(invitation._id)}
                                                                                    id='gc-cta-buttons'
                                                                                    type='ghost'
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center',
                                                                                        margin: '0px'
                                                                                    }}
                                                                                    icon={<RiUserAddLine size={onGrid ? 18 : 20} />} />
                                                                                <Button
                                                                                    disabled={!invitation.active}
                                                                                    onClick={() => handleQRO(invitation._id)}
                                                                                    id='gc-cta-buttons'
                                                                                    type='ghost'
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center',
                                                                                        margin: '0px'
                                                                                    }}
                                                                                    icon={<FiShare2 size={onGrid ? 18 : 20} />}
                                                                                />

                                                                            </div>
                                                                        </div>

                                                                        <div className='invitation-info-container' style={{
                                                                            height: 'auto'
                                                                        }}>

                                                                            {
                                                                                onGrid ?
                                                                                    <div className="grid-buttons-container">
                                                                                        <Row style={{
                                                                                            display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row',
                                                                                            gap: '8px'
                                                                                        }}>
                                                                                            <div
                                                                                                className='status-dot'
                                                                                                style={{
                                                                                                    backgroundColor: invitation.active ? '#62bd19' : '#CECCCB',
                                                                                                }}
                                                                                            ></div>
                                                                                            <span>Invitación {invitation.active ? 'Activa' : 'Inactiva'}</span>
                                                                                        </Row>


                                                                                        {
                                                                                            invitation.active &&
                                                                                            <Button
                                                                                                icon={<HiMiniArrowUpRight size={16} />}
                                                                                                onClick={() =>
                                                                                                    handleMoode('on-edit', invitation._id)
                                                                                                }
                                                                                                id='ic-edit-button-grid'
                                                                                            >Abrir</Button>
                                                                                        }

                                                                                    </div>
                                                                                    :
                                                                                    <>
                                                                                        <div className='inv-date-progress-container'>
                                                                                            {
                                                                                                invitation.payment === 'false' ?

                                                                                                    <div className='inv-info-col'>

                                                                                                        <span style={{
                                                                                                            marginTop: '12px'
                                                                                                        }} className='inv-info-col-label'>La edición de la invitación está bloqueada por un pago pendiente, pero sigue activa y funcionando para tus invitados.</span>

                                                                                                    </div> :

                                                                                                    invitation.active
                                                                                                        ?
                                                                                                        <div className='inv-info-col'>
                                                                                                            <div
                                                                                                                className='status-dot'
                                                                                                                style={{
                                                                                                                    backgroundColor: invitation.active ? '#62bd19' : '#CECCCB',
                                                                                                                }}
                                                                                                            ></div>
                                                                                                            <span className='inv-info-col-label'>Invitación Activa</span>

                                                                                                        </div>
                                                                                                        :
                                                                                                        <div className='inv-info-col'>
                                                                                                            <div
                                                                                                                className='status-dot'
                                                                                                                style={{
                                                                                                                    backgroundColor: invitation.active ? '#62bd19' : '#CECCCB',
                                                                                                                }}
                                                                                                            ></div>
                                                                                                            <span className='inv-info-col-label'>Esperando activación</span>

                                                                                                        </div>
                                                                                            }
                                                                                        </div>

                                                                                        <div
                                                                                            className='start-button-invs'

                                                                                        >
                                                                                            {
                                                                                                invitation.payment === 'false' ?
                                                                                                    <></> :
                                                                                                    invitation.active ?
                                                                                                        <Button
                                                                                                            // icon={}
                                                                                                            onClick={() =>
                                                                                                                handleMoode('on-edit', invitation._id)
                                                                                                            }
                                                                                                            style={{
                                                                                                                backgroundColor: '#FFFFFF20',
                                                                                                                backdropFilter: 'blur(10px)',
                                                                                                                border: 'none'
                                                                                                            }}
                                                                                                            id='ic-edit-button'
                                                                                                        >
                                                                                                            <span>
                                                                                                                Comenzar
                                                                                                                <RiArrowRightSLine size={25} />
                                                                                                            </span>
                                                                                                        </Button> :
                                                                                                        <></>
                                                                                            }

                                                                                        </div>

                                                                                    </>
                                                                            }




                                                                        </div>
                                                                    </div>




                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    width: '95vw',
                                                                    marginTop: '100px',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <Empty style={{ marginTop: '50px' }} description={false} />
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                marginTop: '150px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            <img
                                                                src={load}
                                                                style={{
                                                                    width: '5%',
                                                                }}
                                                                alt="Loading"
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            {
                                                !onGrid &&
                                                <>
                                                    <div className='shadow--white-right' />
                                                    <div className='shadow--white-left' />
                                                </>
                                            }


                                        </div>

                                        :
                                        <div style={{
                                            width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'flex-start',
                                            paddingTop: '100px', justifyContent: 'center'
                                        }}>
                                            <Login position={'land-page'} setOpenLogin={setOpenLogin} />
                                        </div>
                                }


                            </Content>
                    }



                </Layout>

                {/* <FooterMobile position={'invitations'} setOpenLogin={setOpenLogin} isVisible={isVisible} /> */}


            </Layout >

            {
                logged && <NewInvitationDrawer visible={visible} setVisible={setVisible} refreshInvitations={refreshInvitations} />
            }

        </>

    )
}
